import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';

import firebase from 'firebase'
import 'firebase/database'
import config from '../../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function Award(props) {
    userid = props.id
    var [ProfessionalAwarddata] = useListKeys(db.ref('UserAccount/' + userid + '/Achivement'));
   // ProfessionalAwarddata.sort(function (a, b) {
    //    return parseFloat(b.achivdate) - parseFloat(a.achivdate);
   // });

    return ( <> <div className="achivdetyail">{
        ProfessionalAwarddata && ProfessionalAwarddata.map(data => <ProfessionalAwardval edu={data}/>)
    } </div></>)
}
function ProfessionalAwardval(props)
{
    var [ProfessionalAwarddata] = useListVals(db.ref('UserAccount/' + userid + '/Achivement/'+props.edu));
    ProfessionalAwarddata.sort(function (a, b) {
     return parseFloat(b.achivdate) - parseFloat(a.achivdate);
   });
return(<><div className="col-md-12 section_headert1">
<h2>{props.edu}</h2>
</div>
 {  ProfessionalAwarddata && ProfessionalAwarddata.map(data => <ProfessionalAwardvalDetail edu={data}/>)}
</>)
}
function ProfessionalAwardvalDetail(props)
{
  var {
    Image,
    achivorg,achivdate,achivtitle,achivDesc
} = props.edu;

return (<>
    <div className="col-sm-6 col-xs-12">
      <div className="container-fluid award1">
         <div className="row">
           <div className="col-xs-3">
           {Image ? <center><img src={Image} /></center> : <></>}
           </div > 
           <div className="col-xs-9">
           <h3>{achivtitle}</h3>
            <p><i>{achivorg}</i> </p>
        <p><span className="glyphicon glyphicon-calendar"></span> {achivdate} </p>
         {achivDesc ? <p>{achivDesc}</p>:<></>}
         </div> </div>
      </div> </div> </>)
}
export default Award