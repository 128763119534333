import React, {useState, useCallback} from 'react';
import "./experience.css"
import "../common.css"
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import Compressor from 'compressorjs'
import ReactTooltip from 'react-tooltip';
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target.result)
    };
    reader.readAsDataURL(file);
})

function WorkExperience() {
 
    var [previewdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/WorkExperience'));
    previewdata.sort(function(a, b) {
        return parseFloat(b.EmpstartDate) - parseFloat(a.EmpstartDate) ;
    });
    const [edstatus,
        setedstatus] = useState(false)
    const [editInput,
        seteditinput] = useState(false)
   // const test = e => {
      //  console.log(e)
    //}
    const [dataUri,
        setDataUri] = useState('')
    const subexp = e =>{
        e.preventDefault();
        var Emptitle = document.getElementById('Emptitle').value;
        var empType = document.getElementById('empType').value;
        var Empcompany = document.getElementById('Empcompany').value;
        var Emplocation = document.getElementById('Emplocation').value;
        //var exStatus = document.getElementById('exStatus').value;
        var EmpstartDate = document.getElementById('EmpstartDate').value;
        var EmpDes = document.getElementById('EmpDes').value;
        
        if ((Emptitle.length === 0) || (Empcompany.length === 0)  || (EmpstartDate.length == 0)) {
              window.alert("Input field cannot be empty");
              return;
        }
        var pushkey;
        if (editInput) {
            pushkey = document
                .getElementById('id')
                .value
        } else {
            pushkey = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/WorkExperience').push().key;
        }
        
        var exInsert = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/WorkExperience');
        exInsert
        .child(pushkey)
        .update({"id": pushkey});

        exInsert.child(pushkey).update({
            "Emptitle": Emptitle,
            "Empcompany": Empcompany,
            "EmpstartDate": EmpstartDate,
        });
        if(Emplocation.length != 0) {
            exInsert.child(pushkey).update({
                "Emplocation": Emplocation,
            });
        }
        else{
            exInsert.child(pushkey+'/Emplocation').remove()
        }

        if(empType != 'none') {
            exInsert.child(pushkey).update({
                "empType": empType,
            });
        } else{
            exInsert.child(pushkey+'/empType').remove()
        }

        if(EmpDes.length != 0) {
            exInsert.child(pushkey).update({
                "EmpDes": EmpDes,
            });
        } else{
            exInsert.child(pushkey+'/EmpDes').remove()
        }

        {edstatus ?
            exInsert.child(pushkey).update({
                "EmpendDate": 'present'
        }) :exInsert.child(pushkey).update({
               "EmpendDate": document.getElementById('EmpendDate').value
        }); }
        if(dataUri != '')
        exInsert.child(pushkey).update({"Image": dataUri});

        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
        log.push().update({
            "DateTime" : new Date().toLocaleString() + '',
            "Message": 'Work Experience Data Modified'
        })
        document.querySelector('.doneexp').style.display = 'block';
        setTimeout(() => {
            reset();
            document.querySelector('.doneexp').style.display = 'none';
        }, 2500);
    }

    const onChange = (file) => {
        //console.log(file)
       // setfilename(file.name)
        if (!file) {
            setDataUri('');
            return;
        }
        if (file.type == 'image/jpeg' || file.type == 'image/png') {
            if (file.size / 1024 > 1024) {
                alert("File size should be less than 1 MB")
            } else {
                var qt = 0.8;
                if (file.size / 1024 > 800) {
                    qt = 0.6
                }
                new Compressor(file, {
                    quality: qt, // 0.6 can also be used, but its not recommended to go below.
                    success: (compressedResult) => {
                        fileToDataUri(compressedResult).then(dataUri => {
                            setDataUri(dataUri)
                        })
                    }
                });
            }
        } else {
             alert("image is supported")
           
        }
    }
    const editdatacontent = e => {
       // console.log(e)
        seteditinput(true);
        setedstatus(false);
         document.getElementById('checkbox').checked = false;
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/WorkExperience/' + e);
        experiencePreviewId.once('value', function (snapshot) {
            snapshot
                .forEach(function (elem) {
                     // console.log(elem.val())
                    if (elem.key === 'Image') {
                        setDataUri(elem.val())
                    } 
                    else if(elem.key == "EmpendDate")
                    {
                        if(elem.val() === 'present'){
                            setedstatus(true);
                            document.getElementById('checkbox').checked = true;
                        }
                        else{
                            setedstatus(false);
                            document.getElementById('checkbox').checked = false;
                            setTimeout(() => {
                                document.getElementById(elem.key).value = elem.val();
                            }, 300);
                           
                        }
                    }
                    else {
                        document.getElementById(elem.key).value = elem.val();
                    }
                })

        })
    }
    
    const reset = e=>{

        document.getElementById("expform").reset();
    
    setDataUri('')
    seteditinput(false);
    setedstatus(false)
    }
    return (
        <div >
              <div className="success-checkmark doneexp">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div class="col-sm-6 ">
                            <div className="first">
                                <center>
                                    {editInput
                                        ? <label className="labelHead">Edit Work Experience</label>
                                        : <label className="labelHead">Insert Work Experience</label>}
                                </center>
                            <form id="expform" onSubmit={subexp}>
                                <div id="login_div" className="main-div">
                                    <input type="hidden" className="borLessInput" id="id"/>

                                    <span className="label">Title</span>
                                    <input
                                        type="text"
                                        placeholder=" Title (eg. Sales Manager) "
                                        id="Emptitle"
                                        className="borLessInput"/>

                                    <select id="empType" className="borLessInput">
                                        <option value="none">
                                            Employment Type</option>
                                        <option value="Full Time">Full Time</option>
                                        <option value="Part Time">Part Time</option>
                                        <option value="Self-employed">Self-employed</option>
                                        <option value="Freelance">Freelance</option>
                                        <option value="Contract">Contract</option>
                                        <option value="Internship">Internship</option>
                                        <option value="Apprenticeship">Apprenticeship</option>
                                    </select>
                                    <span className="label">Organisation Name</span>
                                    <input
                                        type="text"
                                        placeholder=" Organisation Name "
                                        id="Empcompany"
                                        className="borLessInput"/>
                                    <br></br>
                                    {editInput
                                        ? <div id="LogoUp">

                                                <div className="updateLogo"></div>
                                                <div id="instLogoUp">
                                                {dataUri
                                                            ? <img src={dataUri} width="30%"/>
                                                            : <img
                                                                src="/image/logo_here.png"
                                                                width="30%"
                                                                style={{
                                                                marginTop: '30px'
                                                            }}/>}
                                                </div>
                                                <div className="editinputLogo">
                                                    <label id="pict">Update Company Logo
                                                        <input
                                                            type="file"
                                                            id="CollegeLogoedit"
                                                            onChange={(event) => onChange(event.target.files[0] || null)}
                                                            className="borLessInput"/>
                                                    </label>
                                                </div>
                                                <br/>
                                            </div>
                                        : <div id="LogoIn">
                                            <div id="instLogo"><img src={dataUri} width="30%"/></div>

                                            <div className="editinputLogo">
                                                <label id="pict">Company Logo
                                                    <input type="file"  onChange={(event) => onChange(event.target.files[0] || null)}/>
                                                </label>
                                            </div>

                                        </div>}

                                    <br/><br/>
                                    <span className="label">Organisation Location</span>
                                    <input
                                        type="text"
                                        placeholder=" Location "
                                        id="Emplocation"
                                        className="borLessInput"/>

                                    <div class="form-group">
                                        <label >
                                            I am currently working in this role  <input type="checkbox" id="checkbox" onChange={e => setedstatus(e.target.checked)} value="working"/>
                                        </label><br/>

                                    </div>

                                  
                                    <div className="dateYear">
                                        <div className="form-group">

                                            <span class="label">Start date</span>
                                            <input type="date" id="EmpstartDate" class="borLessInput"/>

                                        </div>
                                        <div className="form-group" id="pass">

                                            <span class="label">End date</span>
                                            {edstatus 
                                                ? <h3>Present</h3>
                                                : <input type="date" id="EmpendDate" class="borLessInput"/>}

                                        </div>
                                    </div>
                                    <span class="label">Responsibility</span>
                                    <textarea
                                        id="EmpDes"
                                        placeholder="Responsibility (optional)" rows="5"
                                        class="borLessInput"></textarea>

                                    {editInput
                                        ? <div id="">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-default btn-edit btn-submit" type="submit">Update</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="reset" onClick={e => reset()}>Reset</button>
                                                </div>
                                            </div>
                                        : <div id="">
                                            <div className="col-xs-6">
                                                <button className="btn btn-edit btn-default" type="submit">Submit</button>
                                            </div>
                                            <div className="col-xs-6">
                                                <button className="btn btn-edit btn-default" type="reset" onClick={e => reset()}>Reset</button>
                                            </div>
                                        </div>}

                                </div>
                                </form>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div className="second">
                                <center>
                                    <label class="headi labelHead">Preview
                                    </label>
                                </center>
                                {previewdata && previewdata.map(data => <Previewdata prop={data} editdata ={e => editdatacontent(e)}/>)}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
function Previewdata(props) {
    const handleInputChange = useCallback(event => {
        props.editdata(event)
    }, [props.showsection])
    if(props.prop)
    var {
        id,
        EmpDes,
        Empcompany,
        EmpendDate,
        Emplocation,
        Image,
        EmpstartDate,
        Emptitle,
        empType,
    } = props.prop;
    const deleteCont = e => {
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/WorkExperience/' + e)
            .remove();
            var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
            log.push().update({
                "DateTime" : new Date().toLocaleString() + '',
                "Message": 'Work Experience Data Deleted'
            })
    }
    return (
        <div class="container-fluid previewContent">
            <div class="col-xs-3 exImg">
                {Image
                    ? <img src={Image} width="90%"/>
                    : <center><img
                        src="/image/logo_here.png"
                        width="60%"
                        style={{
                        marginTop: '30px'
                    }}/></center>}

            </div>
            <div class="col-md-7 ebc">
                <p style={{
                    fontSize: '20px'
                }}>
                    <b >{Emptitle}</b>
                </p>
                <p>{Empcompany}</p>
                <p>
                    <span><img src="/image/event_available-white-36dp.svg"/></span>
                    {EmpstartDate}
                    - {EmpendDate}</p>
                {Emplocation
                    ? <p>{Emplocation}</p>
                    : <p></p>}
                {empType
                    ? <p>Emp. Type: {empType}</p>
                    : <p></p>}
                {EmpDes
                    ? <div>
                            <button
                                class="btn btn-default bnt1"
                                data-toggle="collapse"
                                data-target={'#' + id}>Additional</button>
                            <div id={id} className="collapse">
                                <p>  {EmpDes}</p>  
                            </div>
                        </div>
                    : <div></div>}
            </div>
            <div class="col-xs-2">
                <span
                    class="glyphicon glyphicon-option-horizontal"
                    data-toggle="collapse"
                    data-target={'#info' + id}></span>
                <div id={'info' + id} class="collapse edcopllapse">
                    <button
                        data-tip="Delete"
                        
                        onClick={(e) => deleteCont(id)}
                        class="btn bthBorderLess btn-default">
                        <span class="glyphicon glyphicon-trash"></span>
                    </button>
                    <br/>
                    <button
                       data-tip="Edit"
                        
                        onClick={(e) => handleInputChange(id)}
                        class="btn bthBorderLess btn-default">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                    <ReactTooltip />
                </div>
            </div>
        </div>
    )
}

export default WorkExperience;
