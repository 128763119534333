import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function Project(props) {
    userid = props.id
    var [Projectdata] = useListVals(db.ref('UserAccount/' + userid + '/Project'));
    Projectdata.sort(function (a, b) {
        return parseFloat(b.projendDate) - parseFloat(a.projendDate) ;
    });

    return ( <> {
        Projectdata && Projectdata.map(data => <Projectval edu={data}/>)
    } </>)
}
var countedu = []
function Projectval(props)
{
  var {
    Associatedwith,
    projectDes,projectSupervisor,
    projectlocation,projectname,
    projendDate,projectrole,projectUrl,
    id,
    projstartDate
} = props.edu;

return (<>
<li class="timelinet1-inverted t1t1">
<div class="timelinet1-badget1">
</div> 
< div class = "timelinet1-panel" > 
<div class="timelinet1-heading">
          <p>{projstartDate} - {projendDate}</p > 
        <h4 class="timelinet1-title">{projectUrl ? <a href={projectUrl}  target="_blank" >
            {projectname}</a>:projectname}</h4>
</div>   

 <div class="timelinet1-body">
     {Associatedwith ? <p>Associated with : {Associatedwith}</p>:<></>}
     {projectlocation ? <p>{projectlocation}</p>:<></>}
     {projectrole ? <p>Role : {projectrole}</p>:<></>}
     <br/>
     {projectDes || projectSupervisor ? 
      <> <button
      type="button"
      class="btn btn-default btn-descp"
      data-toggle="collapse"
      data-target={"#myModal" + id}>Description</button> 

      <div id = {"myModal" + id} class = "collapse" > 
<br/>
           {projectSupervisor ? <p>Supervisor :<br/> <b>{parse(projectSupervisor.replaceAll('\n','<br/>'))}</b></p> : <></>}
           {projectDes ? <p>Description :<br/> <b>{parse(projectDes.replaceAll('\n','<br/>'))}</b></p> : <></>}

 </div>  
  </ >
     :<></>}
 </div> </div>
</li > </>)
}
export default Project