import React, {useEffect, useRef, useState,lazy,Suspense} from 'react';
import CVTemplet1 from './CVTemplet1/cvtemplet1';
import CV1Formate from './CVTemplet1/cv1formate';
import './cv.css'
import param from'./parameters.json'
import Modal from 'react-modal';
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import ReactToPrint, {useReactToPrint} from 'react-to-print';
import {useObjectVal} from 'react-firebase-hooks/database';
import ReactTooltip from 'react-tooltip';
import html2pdf from 'html2pdf.js';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
const customStyles = {
    content: {
        top: '45%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: '80vh',
        marginRight: '-50%',
        border: '3px solid black',
        borderRadius: '15px',
        transform: 'translate(-50%, -50%)'
    }
};
const style = document.createElement('style');
function CV({cvpage}) {
    var Service ;


    document.head.appendChild(style);

    let componentRef = useRef();
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     orientation: 'landscape', // Set the orientation to 'portrait' or 'landscape'
    // });

    const [modalIsOpen,
        setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    const [dimensions, setDimensions] = React.useState({ width: 500, height: 500 });
    var Temp;
    var [name] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/AboutData/name'));
    var [Templet,load] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Templet/CV'));
    if(!load && Templet) 
        Service = lazy(() => import('./CVTemplet'+Templet.Templet+'/cvtemplet'+Templet.Templet));
    style.innerHTML = `
      @media print {
        @page {
          size: ${Templet ? Templet.Page ? param[Templet.Page].orientation : param.a4.orientation: param.a4.orientation};
        }
      }
    `;

    return (
        <div>
            <div id="cvpreview">
                <center>
                    <label className="labelHead">Preview CV</label>
                </center>
                <div >
                    <center>
                        <div id="CVDetail"></div>
                    </center>
                    <span className="" data-toggle="" data-target="#demodownloadprintcv"></span>
                    <div id="demodownloadprintcv" className=" templetcopllapse">
                        <ReactToPrint
                            content={() => componentRef}
                            onBeforePrint={() => document.title = name.replace(/ /g,'_') + '_'+ Math.round(+new Date()/1000)}
                            onAfterPrint={() => document.title = name}
                            trigger={() => <button
                            className="btn bthBorderLess btn-default"
                            data-tip="Download/Print CV"
                            >
                            <span className="glyphicon glyphicon-download-alt"></span>
                        </button>}/>

                        <button
                            className="btn bthBorderLess btn-default"
                            data-tip="Change Templet"  onClick={e => cvpage("CVTempletSelect")}>
                            <span className="glyphicon glyphicon-folder-open"></span>
                        </button>

                        <button
                            className="btn bthBorderLess btn-default"
                            onClick={e => setIsOpen(true)}>
                            <span
                                className="glyphicon glyphicon glyphicon-list-alt"
                                data-tip="Modify CV (Coming soon)"></span>
                        </button>
                        <ReactTooltip />
                    </div>

                    <div className="secondLoading" id="cvLoading"></div>
                    <div id="print-btn"></div>
                    
                    {Templet ?  
                    <div>
                    <div id="cvtempletpreview" style={{'width' : Templet ? Templet.Page ? param[Templet.Page].width : param.a4.width: param.a4.width}}>
                    <div ref={(el) => (componentRef = el)}>
                    {/* <div ref={componentRef} className='page'> */}
                       <Suspense fallback={<div>CV is Loading...</div>}>
                            {Templet.Templet ? <Service/>   : <CVTemplet1/>}
                           </Suspense>
                        
                    </div> 
                    </div>
                    </div>:
                 
                    <div></div>}
                    <ComponentToPrint  />
                </div>
            </div>
            {/* <Modal
                isOpen={modalIsOpen}
                onRequestClose={e => closeModal(0)}
                style={customStyles}
                ariaHideApp={false} 
                contentLabel="Example Modal">

                <CV1Formate />
            </Modal> */}
        </div>
    )
}
const ComponentToPrint = () => (
    <div>
      {/* Content to be printed */}
      <h1>Hello, Printing!</h1>
      <p>This is the content that will be printed.</p>
    </div>
  );
export default CV;