import React, {useState, useEffect} from 'react';
import "./dashboard.css"
import "./dashboardres.css"
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import {useObjectVal, useListVals,useListKeys} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
var percentage;

function Analytic() {
    var [createwebiste] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/website/url'));
    var todaycount = 0,yesterdaycount = 0,thismonthcount = 0,lastmonthcount = 0;
    var [alltime,loading] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Analytic'));
    if(!loading){
        alltime.map(item=>{
            var todaydate = (new Date().toLocaleString() + '').split(',')[0];
           // var todaydate = "11/01/2021";
            var today = new Date();
            var yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            var month = today.getMonth() + 1
            var d = item.date
         //   console.log(d,  " ", todaydate)
             var d1 = d.split('/')
             if(todaydate.split('/')[1] === '1')
                 month = month-1;
             var temp = d1[0]
             d1[0] = d1[1]
             d1[1] = temp
             d1 = d1.join('/')
             yesterday = month + '/' + yesterday.getDate() + '/' + yesterday.getFullYear()
             if(d === todaydate)
               todaycount++;
             if(d === yesterday)
             yesterdaycount++;
            var thismonth = (todaydate.split('/'))[0]+''+(todaydate.split('/'))[2]
             if(((d.split('/'))[0]+''+(d.split('/'))[2]) === thismonth)
               thismonthcount++;
             var lastmonth = (todaydate.split('/'))[0] - 1;
             lastmonth += ''+(todaydate.split('/'))[2]
             if(lastmonth == 0)
                 lastmonth -= 1;
              if(((d.split('/'))[0]+''+(d.split('/'))[2]) === lastmonth)
              lastmonthcount++;
             
         })
         setTimeout(()=>{
            document.getElementById('website_loader').style.display="none"
            document.getElementById('website_content').style.display="block"

         },1000)
    }
    
    

    return (
        <div className="dashbox">
        <h1>Website Statistic (Beta)</h1>
        <div className='content_loading' id="website_loader">
            <img src="./image/loading.gif" />
        </div>
        <div className="container-fluid dashsat" id="website_content">
            <div className="row">
                {createwebiste ?<>
                    <div className="col-xs-12">
                    {/*<span
                        className="glyphicon glyphicon-refresh"
                        id="refreshView"
                    onclick="analyticPreview()"></span>*/}
                </div>
                <div className="col-xs-12 ">
                    <div className="profiledstaticticcount">
                        <p>All Time<br/>
                            <span id="alltimeview">{alltime.length}</span>
                        </p>
                    </div>
                 
                </div>
                
                <div className="col-xs-6">
                    <div className="profiledstaticticcount">
                        <p>Today<br/>
                            <span id="todayview">{todaycount}</span>
                        </p>
                    </div>
                </div>
                <div className="col-xs-6">
                    <div className="profiledstaticticcount">
                        <p>Yesterday
                            <br/>
                            <span id="yesterdayview">{yesterdaycount}</span>
                        </p>
                    </div>
                </div>
            <div className="col-xs-6">
                    <div className="profiledstaticticcount">
                        <p>This Month
                            <br/>
                            <span id="thismonthview">{thismonthcount}</span>
                        </p>
                    </div>
                </div>
                    <div className="col-xs-6">
                    <div className="profiledstaticticcount">
                        <p>Last Month
                            <br/>
                            <span id="lastmonthview">{lastmonthcount}</span>
                        </p>
                    </div>
                </div>
    
                </> : <p id="generatesite">Generate Your Site in the <br/><i>'Create Site'</i> Section</p>}
                
            </div>
        </div>
    </div>
    )
}

export default Analytic
