import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function WorkExperience(props) {
    userid = props.id
    var [WorkExperiencedata] = useListVals(db.ref('UserAccount/' + userid + '/WorkExperience'));
    WorkExperiencedata.sort(function (a, b) {
        return parseFloat(b.EmpstartDate) - parseFloat(a.EmpstartDate);
    });

    return ( <> {
        WorkExperiencedata && WorkExperiencedata.map(data => <WorkExperienceval edu={data}/>)
    } </>)
}
var countedu = []
function WorkExperienceval(props)
{
  var {
    Image,
    EmpDes,Emptitle,
    Empcompany,Emplocation,
    EmpendDate,
    id,
    EmpstartDate,supervisor,titleDes,titleName,projecttitle
} = props.edu;
if(countedu.indexOf(id) == -1 && id) {
    countedu.push(id)
   
}
return (<>
    <div className="col-sm-6 col-xs-12">
      <div className="container-fluid workexp1">
         <div className="row">
           <div className="col-xs-3">
           {Image ? <center><img src={Image} /></center> : <></>}
           </div > <div className="col-xs-9">
           <h3>{Emptitle}</h3>
        <p><i>{Empcompany}</i> </p>
        {Emplocation ?<p>{Emplocation}</p>:<></> }
        <p><span className="glyphicon glyphicon-calendar"></span> {EmpstartDate} - {EmpendDate}</p>
        {EmpDes
            ? <> <button
                type="button"
                class="btn btn-default btn-descp"
                data-toggle="modal"
                data-target={"#myModal" + id}>Description</button> 

                <div id = {"myModal" + id} class = "modal fade modalt1" role = "dialog" > 
                <div class="modal-dialog">

                <div class="modal-content">
                    
                    <div class="modal-body">
                     {EmpDes ? <p>Description :<br/> <b>{parse(EmpDes.replaceAll('\n','<br/>'))}</b></p> : <></>}
                     
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default  btn-descp" data-dismiss="modal">Close</button>
                    </div>
                </div>

            </div> </div>  
            </ >
            : <></>}
    </div> </div>
     </div> </div> </>)
}
export default WorkExperience