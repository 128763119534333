import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../../config'
import parse from 'html-react-parser';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid
function ResearchArea(props){
    userid = props.id
    var [ResearchAreadata] = useListKeys(db.ref('UserAccount/' + userid + '/ResearchArea'));
    
return(<><div className="container-fluid"><div class="row">
          {ResearchAreadata && ResearchAreadata.map(data => <ResearchAreatype edu={data}/>)}</div></div>
</>)
}

function ResearchAreatype(props)
{
    var type = props.edu
    var [skilldata] = useListVals(db.ref('UserAccount/' + userid + '/ResearchArea/'+props.edu));
    
    return(<><div className="container-fluid"><div class="row">
    {type ? <div className="">
    <h3 style={{color:'white',marginLeft:'50px'}}>{type.replaceAll('+',' ')} </h3>
    {skilldata && skilldata.map(item=> <ResearchAreaTypeval val={item}/>)}
    </div>: <></>}</div></div>
    </>

    );
}

function ResearchAreaTypeval(props) {
    var {Description,SubDomain,id} = props.val;
    return(<><div class="col-md-4">
        <div className="ResearchAreat1">
        <h4 style={{fontWeight:'bold'}}> {SubDomain} </h4>
        <br/>
     {Description ? 
      <> <button
      type="button"
      class="btn btn-default btn-descp"
      data-toggle="modal"
      data-target={"#myModal" + id}>Description</button> 
     <div id = {"myModal" + id} class = "modal fade modalt1" role = "dialog" > 
                <div class="modal-dialog">

                <div class="modal-content">
                    
                    <div class="modal-body">
                    <p>Description: <br/> <b> {parse(Description.replaceAll('\n','<br/>'))}</b></p> 
                     
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default  btn-descp" data-dismiss="modal">Close</button>
                    </div>
                </div>

            </div> </div>  
  
  </ >
     :<></>}
    </div>
         </div></>)
}
export default ResearchArea;