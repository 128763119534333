import React, {useState, useRef, useCallback, useEffect} from 'react';
import "./about.css"
import "../common.css"
import ReactCrop from 'react-image-crop';
import ReactTooltip from 'react-tooltip';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from 'react-modal';
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import {useObjectVal} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)'
    }
};
const editorConfig = {
    toolbar: ['bulletedList'],
    language: 'en',
    startupOutlineBlocks: true
    // Add any other configuration options you need
  };
  const handleEditorReady = (editor) => {
    console.log('Editor is ready to use!', editor);

    // Disable the bulleted list command
    const command = editor.commands.get('bulletedList');
    if (command) {
      command.isEnabled = true;
    }
  };
function About() {

    const [finalCropImg,setfinalCrop] = useState(null);
    const [aboutYaDetail,setaboutYaDetail] = useState('<ul><li></li></ul>');
    
    var [previewdata] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/AboutData'));


    const [modalIsOpen,
        setIsOpen] = React.useState(false);
    const [upImg,
        setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop,
        setCrop] = useState({
        unit: '%',
        width: 50,
        aspect: 4 / 4
    });
    const [completedCrop,
        setCompletedCrop] = useState(null);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setIsOpen(true);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
    }, [completedCrop]);

    function closeModal() {
        setIsOpen(false);
        document
            .querySelector("#picture")
            .value = null;
        setUpImg()
    }

    function test(canvas, crop) {
        if (!crop || !canvas) {
            return;
        }
        var dataURL = canvas.toDataURL();
        canvas.toBlob((blob) => {

            var newImg = document.createElement('img'),
                url = URL.createObjectURL(blob);
            newImg.onload = function () {
                // no longer need to read the blob so it's revoked
                URL.revokeObjectURL(url);
            };

            newImg.src = url;
            setfinalCrop(dataURL)
        }, 'image/png', 1);
        closeModal();
    }
    const submitAbout = e => {
        e.preventDefault();
        var home = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/AboutData');
        {
            document.getElementById('name').value? home.update({name: document
                        .getElementById('name')
                        .value
                })
                : <></>}
        {document.getElementById('description').value ? home.update({description: document.getElementById('description').value}):  <></>
        }
         home.update({
                    aboutmedetail: aboutYaDetail
                })
    
        // {document.getElementById('carrerobjective').value ? home.update({carrerobjective: document.getElementById('carrerobjective').value}): <></>
        // }
        {
            document
                .getElementById('persMobile')
                .value
                ? home.update({
                    persMobile: document
                        .getElementById('persMobile')
                        .value
                })
                : <></>}
        {document.getElementById('perscvemail').value ? home.update({perscvemail: document.getElementById('perscvemail').value}):<></>
        }
        {document.getElementById('persDOB').value ? home.update({persDOB: document.getElementById('persDOB').value}):<></>
        }
        {
            document
                .getElementById('persGender')
                .value
                ? home.update({
                    persGender: document
                        .getElementById('persGender')
                        .value
                })
                : <></>}
        {document.getElementById('persNation').value ? home.update({persNation: document.getElementById('persNation').value}):<></>
        }
        {
            document
                .getElementById('persOffAddress')
                .value
                ? home.update({
                    persOffAddress: document
                        .getElementById('persOffAddress')
                        .value
                })
                : <></>}
        {document.getElementById('pershobbie').value ? home.update({pershobbie: document.getElementById('pershobbie').value}): <></>
        }
        {
            document
                .getElementById('perslang')
                .value
                ? home.update({
                    perslang: document
                        .getElementById('perslang')
                        .value
                })
                : <></>}
        {finalCropImg ? home.update({image: finalCropImg}): <></>
        }
        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
        log.push().update({
            "DateTime" : new Date().toLocaleString() + '',
            "Message": 'About Data Modified'
        })
        document
            .querySelector('.donabout')
            .style
            .display = 'block';
        setTimeout(() => {
            reset();
            document
                .querySelector('.donabout')
                .style
                .display = 'none';
        }, 2500);
    }
    const editAbout = e => {
        var experiencePreviewId = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/AboutData/');
        experiencePreviewId.once('value', function (snapshot) {
            snapshot
                .forEach(function (elem) {
                    if (elem.key === 'image') {
                        setfinalCrop(elem.val())
                    } 
                    else if (elem.key === 'aboutmedetail'){
                        setaboutYaDetail(elem.val())
                    }
                    else {
                        try{
                            
                            document.getElementById(elem.key).value = elem.val();
                        }
                     catch(e) { }
                      
                    }
                })

        })
    }
    const reset = e => {
        setfinalCrop(null)
        setaboutYaDetail('<ul><li></li></ul>')
        document
            .getElementById("aboutform")
            .reset();
    }
    const deleteabout = e => {
        db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/AboutData/' + e)
            .remove();
            var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
            log.push().update({
                "DateTime" : new Date().toLocaleString() + '',
                "Message": 'About Data Modified'
            })
    }
    return (
        <div>
            <div className="success-checkmark donabout">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>

            <section id="home">
                <div className="container-fluid">
                    <div className="row">
                      
                        <div class="col-sm-6 ">
                            <div className="first">
                                <center>
                                    <label className="labelHead">About</label>
                                </center>
                                <form id="aboutform" onSubmit={submitAbout}>
                                    <div id="login_div" className="main-div">

                                        <div className="label">NAME</div>

                                        <input type="text" placeholder=" Name" id="name" className="borLessInput"/>

                                        <br/>

                                        <div
                                            id="pictName"
                                            style={{
                                            paddingLeft: '0px'
                                        }}>{finalCropImg
                                                ? <img src={finalCropImg} alt="userimg"/>
                                                : <img src="/image/unnamed.png" alt="userimg"/>}</div>

                                        <div
                                            style={{
                                            float: 'right',
                                            width: '50%',
                                            textAlign: 'center'
                                        }}>

                                            <label id="pict">Profile Picture
                                                <input
                                                    type="file"
                                                    name="image"
                                                    className="image"
                                                    id="picture"
                                                    accept="image/*"
                                                    onChange={onSelectFile}/></label>

                                        </div>
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={closeModal}
                                            style={customStyles}
                                            shouldCloseOnOverlayClick={false}
                                            contentLabel="Example Modal">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h3>Crop/Resize Image</h3>
                                                        <div className="cropImageSection">
                                                            <ReactCrop
                                                                src={upImg}
                                                                onImageLoaded={onLoad}
                                                                crop={crop}
                                                                onChange={(c) => setCrop(c)}
                                                                onComplete={(c) => setCompletedCrop(c)}/>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6">
                                                        <h3>Preview</h3>
                                                        <div className="finalcropImageSection">
                                                            <div>

                                                                <canvas ref={previewCanvasRef} // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                                                    style={{
                                                                    width: '100%'
                                                                }}/>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                className="btn btn-default"
                                                onClick={e => test(previewCanvasRef.current, completedCrop)}>Apply</button>
                                            <button className="btn btn-default" onClick={e => closeModal()}>Cancel</button>

                                        </Modal>

                                        <br/><br/>

                                        <div className="label">present status
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Present Status & Description"
                                            id="description"
                                            className="borLessInput"/>

                                        <div className="label">about Ya</div>
                                        <CKEditor
                                         config={editorConfig}
                                        editor={ClassicEditor}
                                        data={aboutYaDetail}
                                        onReady={handleEditorReady}
                                        onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setaboutYaDetail(data);}}
                                        onBlur={(event, editor) => {}}
                                        onFocus={(event, editor) => {
                                       // console.log('Focus.', editor);
                                       }}/>
                                        {/* <textarea
                                            type="text"
                                            placeholder="About me"
                                            id="aboutmedetail"
                                            rows="5"
                                            style={{
                                            fontWeight: 'none !important'
                                        }}
                                            className="borLessInput"></textarea> */}

                                        {/* <div className="label">career objective</div> */}
{/* 
                                        <textarea
                                            type="text"
                                            placeholder="Career Objective"
                                            id="carrerobjective" rows="5"
                                            className="borLessInput"></textarea> */}

                                        <span className="label">MOBILE NUMBER</span>
                                        <input
                                            type="text"
                                            id="persMobile"
                                            className="borLessInput"
                                            placeholder="Mobile Number"/>

                                        <span className="label">EMAIL</span>
                                        <input
                                            type="email"
                                            placeholder="Email Id (for CV / Website)"
                                            id="perscvemail"
                                           
                                            className="borLessInput"
                                         />

                                        <span className="label">DOB</span>
                                        <input
                                            type="date"
                                            placeholder="Date Of Birth"
                                            id="persDOB"
                                            className="borLessInput"/>

                                        <span className="label">GENDER</span>
                                        <input
                                            type="text"
                                            placeholder="Gender"
                                            id="persGender"
                                            className="borLessInput"/>

                                        <span className="label">CITIZENSHIP</span>
                                        <input
                                            type="text"
                                            id="persNation"
                                            placeholder="Citizenship"
                                            className="borLessInput"/>

                                        <span className="label">
                                            ADDRESS</span>
                                        <textarea id="persOffAddress" placeholder="Address" className="borLessInput"></textarea>

                                        <span className="label">HOBBIE</span>
                                        <input
                                            type="text"
                                            id="pershobbie"
                                            placeholder="Interest"
                                            className="borLessInput"/>
                                        <span className="label">LANGUAGE</span>
                                        <input
                                            type="text"
                                            id="perslang"
                                            placeholder="Languages Ya Speak"
                                            className="borLessInput"/>

                                        <div id="">
                                            <div className="col-xs-6">
                                                <button className="btn btn-edit btn-default" type="submit">Submit</button>
                                            </div>
                                            <div className="col-xs-6">
                                                <button
                                                    className="btn btn-edit btn-default"
                                                    type="reset"
                                                    onClick={e => reset()}>Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>

                        <div className="col-sm-6">
                            <div className="second">
                                <center>
                                    <label className="headi labelHead">Preview
                                    </label>
                                </center>

                                <span
                                    class="glyphicon glyphicon-edit"
                                    data-tip="Edit"
                                    style={{
                                    float: 'right',
                                    outline: 'none',
                                    marginRight: '5%',
                                    cursor: 'pointer'
                                }}
                                    onClick={e => editAbout()}></span>
                                <ReactTooltip/>
                                <div
                                    class="container-fluid aboutpreview"
                                    style={{
                                    paddingTop: '40px'
                                }}>
 {previewdata
                                        ? <> 
                                    <div class="col-md-4">
                                        <center>
                                            {previewdata.image
                                                ? <img src={previewdata.image} alt="userimg" width="100%" class="img-responsive  img-circle"/>
                                                : <img
                                                    src="/image/unnamed.png"
                                                    alt="userimg"
                                                    width="70%"
                                                    class="img-responsive  img-circle"/>}

                                        </center>
                                    </div>
                                             
                            < div class = "col-md-8" > 
                            
                            {previewdata.name ? <>  <p className="removeabout"> <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('name')}></i>
                                                    <ReactTooltip/><span>Name</span>
                                  {previewdata.name}</p></>: <></>}
                          

                                       {previewdata.description ?<p className="removeabout"> <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('description')}></i>
                                                    <ReactTooltip/><span>Present Status</span > {
                                                previewdata.description
                                            } </p>: <p></p >
                                    } </div>
                                  <div class="col-xs-12">
                                      <br/> <br/>
                                    {
                                        previewdata.aboutmedetail 
                                            ? <p className="removeabout">
                                                    <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('aboutmedetail')}></i>
                                                    <ReactTooltip/>
                                                    <span>About Me</span>
                                                    <span className="about_class_li">{parse(previewdata.aboutmedetail.replaceAll('<li>','<li><span className="material-icons">play_arrow</span>')) }</span></p>
                                            : <p></p>
                                    }
                                    {
                                        previewdata.carrerobjective
                                            ? <p className="removeabout">
                                                    <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('carrerobjective')}></i>
                                                    <ReactTooltip/>
                                                    <span>Carrer Objective</span>
                                                    {parse(previewdata.carrerobjective.replace(/\n/g,'<br/>'))}</p>
                                            : <p></p>
                                    }
                                    {
                                        previewdata.persMobile
                                            ? <p className="removeabout">
                                                    <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('persMobile')}></i>
                                                    <ReactTooltip/>
                                                    <span>Mobile</span>
                                                    {previewdata.persMobile}</p>
                                            : <p></p>
                                    }
                                    {
                                        previewdata.perscvemail
                                            ? <p className="removeabout">
                                                    <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('perscvemail')}></i>
                                                    <ReactTooltip/>
                                                    <span>Email(CV/Wesbite) </span>
                                                    {previewdata.perscvemail}</p>
                                            : <p></p>
                                    }
                                    
                                    {
                                        previewdata.persDOB
                                            ? <p className="removeabout">
                                                    <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('persDOB')}></i>
                                                    <ReactTooltip/>
                                                    <span>DOB</span>
                                                    {previewdata.persDOB}</p>
                                            : <p></p>
                                    }
                                    {
                                        previewdata.persGender
                                            ? <p className="removeabout">
                                                    <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('persGender')}></i>
                                                    <ReactTooltip/>
                                                    <span>Gender</span>
                                                    {previewdata.persGender}</p>
                                            : <p></p>
                                    }
                                    {
                                        previewdata.persNation
                                            ? <p className="removeabout">
                                                    <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('persNation')}></i>
                                                    <ReactTooltip/>
                                                    <span>Citizenship</span>
                                                    {previewdata.persNation}</p>
                                            : <p></p>
                                    }
                                    {
                                        previewdata.persOffAddress
                                            ? <p className="removeabout">
                                                    <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('persOffAddress')}></i>
                                                    <ReactTooltip/>
                                                    <span>Address</span>
                                                    {previewdata.persOffAddress}</p>
                                            : <p></p>
                                    }
                                    {
                                        previewdata.pershobbie
                                            ? <p className="removeabout">
                                                    <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('pershobbie')}></i>
                                                    <ReactTooltip/>
                                                    <span>Hobbies</span>
                                                    {previewdata.pershobbie}</p>
                                            : <p></p>
                                    }
                                    {
                                        previewdata.perslang
                                            ? <p className="removeabout">
                                                    <i
                                                        class="glyphicon glyphicon-remove "
                                                        data-tip="Delete"
                                                        style={{
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={e => deleteabout('perslang')}></i>
                                                    <ReactTooltip/>
                                                    <span>Language I Speak</span>
                                                    {previewdata.perslang}</p>
                                            : <p></p>
                                    } </div></> : <><h1>Insert Data</h1></>}

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;
