import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';

import firebase from 'firebase'
import 'firebase/database'
import config from '../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function Award(props) {
    userid = props.id
    var [ProfessionalAwarddata] = useListKeys(db.ref('UserAccount/' + userid + '/Achivement'));

    return ( <> {
        ProfessionalAwarddata && ProfessionalAwarddata.map(data => <ProfessionalAwardval group={data}/>)
    } </>)
}
function ProfessionalAwardval(props)
{
    var type = props.group
    
    var [skilldata] = useListVals(db.ref('UserAccount/' + userid + '/Achivement/'+type));
    skilldata.sort(function (a, b) {
        return parseFloat(b.achivdate) - parseFloat(a.achivdate);
    });
    return(<>
    {type ? <>
        <p className="p">{type.replaceAll('+',' ')}</p><ul>
   
        {skilldata && skilldata.map(item=> <AchivementTypeval val={item}/>)} </ul> <hr/>
    </>: <></>}
    </>

    );
}
function AchivementTypeval(props) {
    
    var {

        achivorg,achivdate,achivtitle,achivDesc
    } = props.val;
    
    return (<> 
       <li>
        <b>{achivtitle}</b><br/>
        {achivorg}<br/>
        <span style={{fontWeight: 'bold'}}>{achivdate.split('-').reverse().join('/')}</span><br/>
        <ul><li>{achivDesc}</li></ul>
</li>
       </>)
}
export default Award