import React from 'react';
import './Templetselect.css'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
function WebTempletSelect(props) {
    const selecttemplet = e => {
        var cv =  db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Templet/Website').update({
            "Templet" :e
        })
       // NotificationManager.info('Website Templet Changed');
       toast('Website Templet Changed', {
        style: {
            backgroundColor:'#B7F9AA',
            color:'black',
            fontSize:'20px',
        },
        autoClose:1000
      });
        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
                     log.push().update({
                         "DateTime" : new Date().toLocaleString() + '',
                         "Message": 'Website Templet Changed'
                     })
        props.changewebsite("Website")
    }

    return ( <> <center>
        <label class="labelHead" style={{color:'black'}}>Changed Website Templet</label>
    </center> < div class = "container-fluid" > <div class="col-sm-12">
        {props.preview ?
        <div class="webtempletimg">

            <div class="container-fluid ">
                <div className="row">
                    <div className="col-xs-9">
                        <div className="webtempletselect">
                            <img src="/image/WebsiteTemplet/web1pc.png"/>
                            <div className="webtempletselect_selectbtn">
                            
                            </div>
                        </div>
                        <button className="btn btn-primary" onClick={e => selecttemplet('1')}>SELECT</button>
                    </div>
                    <div className="col-xs-3">
                        <div className="webtempletselectmobile">
                            <img src="/image/WebsiteTemplet/web1mobile.png"/>  
                        </div>

                    </div>
                 <div className="col-md-12">
                 <br/><br/>
                     <hr/>
                     <br/><br/>
                 </div>
                    <div className="col-xs-9">
                        <div className="webtempletselect">
                            <img src="/image/WebsiteTemplet/web2pc.png"/>
                            <div className="webtempletselect_selectbtn">
                                
                            </div>
                        </div>
                        <button className="btn btn-primary" onClick={e => selecttemplet('2')}>SELECT</button>
                    </div>
                    <div className="col-xs-3">
                        <div className="webtempletselectmobile">
                            <img src="/image/WebsiteTemplet/web2mobile.png"/>  
                        </div>

                    </div>
                    <div className="col-md-12">
                 <br/><br/>
                     <hr/>
                     <br/><br/>
                 </div>
                    <div className="col-xs-9">
                        <div className="webtempletselect">
                            <img src="/image/WebsiteTemplet/web3pc.png"/>
                            <div className="webtempletselect_selectbtn">
                               
                            </div>
                        </div>
                        <button className="btn btn-primary" onClick={e => selecttemplet('3')}>SELECT</button>
                    </div>
                    <div className="col-xs-3">
                        <div className="webtempletselectmobile">
                            <img src="/image/WebsiteTemplet/web3mobile.png"/>  
                        </div>

                    </div>
                    <div className="col-md-12">
                 <br/><br/>
                     <hr/>
                     <br/><br/>
                 </div>
                    <div className="col-md-12">
                        <div className="">
                            <h1
                                style={{
                                color: 'black',
                                textAlign: 'center'
                            }}>More Templet Coming Soon...... </h1>
                        </div>
                    </div>
                </div>
            </div>

        </div>: <><h1 style={{textAlign: 'center',color: 'black',fontWeight: 'bold',paddingTop:'50px'}}>Generate Your Site in the <i>'Create Site'</i> Section</h1></>}
    </div> </div > </ >)
}

export default WebTempletSelect;