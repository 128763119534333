import React, {useState, useRef, useCallback, useEffect} from 'react';
import "./dashboard.css"
import "./dashboardres.css"

import Completion from './completion';
import Analytic from './analytic';
import MenuList from './menu';
import Loading from './loading';
import About from "../About/about"
import Education from "../Education/education"
import WorkExperience from "../Experience/workexperience"
import TeachingExperience from "../Experience/teachingexperience"
import ResearchArea from '../Researcharea/researcharea';
import Publication from '../Publication/publication';
import Project from '../Project/project'
import Website from '../Website/website';
import Skill from '../Skill/skill'
import ProfessionalMembership from '../ProfessionalMembership/ProfessionalMembership'
import Links from '../Links/links'
import TrainingCourse from '../TrainingCourse/trainigcourse'
import Achivement from '../Achivement/achivement'
import Conference from '../Conference/conference'
import Message from '../Message/message'
import Password from '../Privacy/Password/password'
import Certification from "../Certification/Certification"
import CV from '../CV/cv'
import CVTempletSelect from '../CV/TempletSelect/Templetselect'
import WebTempletSelect from '../M/TempletSelect/Templetselect'
import Working from '../Working/working'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Modal from 'react-modal';
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import {useObjectVal, useListVals,useListKeys} from 'react-firebase-hooks/database';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tour from 'reactour'
import Picker from 'emoji-picker-react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
var percentage ;

if(window.innerWidth < 500)
{
    var customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: '-40%',
            bottom:'1px',
           
            backgroundColor:'white',
            transform: 'translate(-50%, -50%)',
            border: '5px solid black',
            borderRadius: '0px'
        }
    };
}
else{
    var customStyles = {
        content: {
            top: '50%',
            left: '52%',
            right: 'auto',
            bottom: 'auto',
            backgroundColor:'white',
            transform: 'translate(-50%, -50%)',
            border: '5px solid black',
            borderRadius: '50px'
        }
    };
}
var selectcolor  ;

const setcolor = e =>{
    if (e === selectcolor) 
    return 'grey'
return 'transparent'
}
var steps = [
    {
        selector: '.leftSection',
        content: 'Menu Section'
    }, {
        selector: '.addsectionlist',
        content: 'Add Menu to the Menu List'
    }, {
        selector: '.mainSectionMiddle',
        content: 'Mani Activity Section '
    }, {
        selector: '.profilecomplition',
        content: 'Profile Completion Graph '
    }, {
        selector: '.analyticwebsite',
        content: 'Website Analytic '
    }, {
        selector: '.profilenotification',
        content: 'Profile Notification'
    }, {
        selector: '.notificationicon',
        content: 'Chat with your friend '
    }, {
        selector: '.jobsearch',
        content: 'Job search Section (Coming Soon)'
    }
   
];
var msgcount=0,chatmsgnoti = 0;
function Dashboard() {
    const [isTourOpen,
        setIsTourOpen] = useState(false);
    const [modalOpen,
            setOpen] = React.useState(false);
        useEffect(()=>{
            if(localStorage.getItem('newmakeyacvUser'))
            {
                setOpen(true)
                localStorage.removeItem('newmakeyacvUser')
            } 
          
        },[])
        
    if(!localStorage.getItem('makeyacvUser'))
       window.location.href="/Login"
useEffect(() => {
   
    //document.getElementById('dashboardmain').style.display = 'block';
   document.getElementById('loader').style.display = 'block';
    document.getElementById('connecting').style.display = 'block';
    document.getElementById('connected').style.visibility = 'hidden';
    setTimeout(() => {
        document.getElementById('loader').style.display = 'none';
        document.getElementById('dashboardmain').style.display = 'block';
    },5500)
    setTimeout(() => {
        document.getElementById('connecting').style.display = 'none';
        document.getElementById('connected').style.visibility = 'visible';
    },4500)
},[])
    
  
   
   
    var [status] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Status'));
    if(status === 'block'){
        document.getElementById('dashboardmain').style.display = 'none';
        document.getElementById('blockacc').style.display = 'block';
    }
     
    const [frindsearch,setfriendsearch] = useState('')
    const [frienddiv,setfreinfdiv] = useState(false)
    const [startchat,setstartchat] = useState(false)
    const [chatusernames,setchatusernames] = useState('')
    const [selectsection,setselectsection] = useState(0)
    const [showEmoji,
        setshowEmoji] = useState(false);
    var [aboutdatadetail] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/AboutData/'));
    var [username] = useListVals(db.ref('UserAccount'));
    var [chatmessage] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ChatMessage/'+chatusernames));
    var [notificationmsg] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Notification/Message'));
    var [recentList] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/RecentChat'));
    var [createwebiste] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/website/url'));
   // const [createwebiste,setcreatewebiste] = useState
    recentList.sort(function(a, b) {
        return parseFloat(b.Servertime) - parseFloat(a.Servertime) ;
    });
    var [chatnotificationmsg,chatnotificationmsgload] = useListKeys(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ChatNotification'));
    if(!chatnotificationmsgload)
       chatnotificationmsg.map(item=>{
        var modifiedemail = item.replace(/\./g, '(dot)')
        modifiedemail = modifiedemail.replace(/\$/g, '(dollar)')
        modifiedemail = modifiedemail.replace(/\[/g, '(left)')
        modifiedemail = modifiedemail.replace(/\]/g, '(right)')
        modifiedemail = modifiedemail.replace(/\//g, '(slash)')
       if(modifiedemail !== chatusernames && chatnotificationmsg)
           chatmsgnoti = 1;
       })
    if(chatnotificationmsg.length === 0)
       chatmsgnoti = 0;
    var [notificationprof] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Notification/Profile'));
    notificationmsg.map(item=>{
        if(item.bgcolor === 'transparent')
        {
            msgcount++;
        }
    })
    var [Menu,menuloading] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/MenuList'));
    
    const [sectiondivShow,
        setsectionDiv] = useState('Dashboard')
    const [modalIsOpen,
        setIsOpen] = useState(false);
    const [menu,setmenu]  = useState(true)
    const [btnNotify,setbtnNotify] = useState(0)
    function openModal() {
        setIsOpen(true);  
    }
    function closeModal() {
        setIsOpen(false);
        setOpen(false);
    }
    const displayDiv = e => {
        if (e === sectiondivShow) 
            return 'block'
        return 'none'
    }

    selectcolor = sectiondivShow;
    const logoutuser = e=>{
        localStorage.removeItem('makeyacvUser')
        window.location.href="/Login"
    }
    const showuserchat = (e,n,i) =>{
        var modifiedemail = e.replace(/\./g, '(dot)')
            modifiedemail = modifiedemail.replace(/\$/g, '(dollar)')
            modifiedemail = modifiedemail.replace(/\[/g, '(left)')
            modifiedemail = modifiedemail.replace(/\]/g, '(right)')
            modifiedemail = modifiedemail.replace(/\//g, '(slash)')
        if(modifiedemail !== localStorage.getItem('makeyacvUser'))
        {
            setstartchat(true)
            setchatusernames(modifiedemail)
            setfreinfdiv(frienddiv => !frienddiv)
            setTimeout(() => {
                try{
                    if(i)
                      var data = '<img src="'+i+'"/>'+n
                    else    var data = '<img src="/image/unnamed.png"/>'+n
                    document.getElementById('usernameinage').innerHTML = data
                    element.scrollTop = element.scrollHeight;
                   // dummy.element.scrollIntoView({behavior: 'smooth'});
          
                }catch{
          
                }
                    
              }, 40);
        }
       
    }
    const onEmojiClick = (event, emojiObject) => {
        const cursor = ref.current.selectionStart;
        const text = formValue.slice(0, cursor) + emojiObject.emoji + formValue.slice(cursor);
        setFormValue(text)
    };
    const ref = useRef(null);
    const dummy = useRef();
    var element = document.getElementById("chatid"); 
    setTimeout(() => {
        try{
            element.scrollTop = element.scrollHeight;
           // dummy.element.scrollIntoView({behavior: 'smooth'});
  
        }catch{
  
        }
            
      }, 300);
    const [formValue,
        setFormValue] = useState('');
    const sendMessage = async(e) => {
        e.preventDefault();
            // dummy.current.scrollIntoView({behavior: 'smooth'});
            element.scrollTop = element.scrollHeight;
           var pushkey = db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ChatMessage/' + chatusernames)
               .push()
               .key;
               var noti = db.ref('UserAccount/' + chatusernames + '/ChatNotification/' + localStorage.getItem('makeyacvUser'))
               noti.push().set({
                   "msg" : 'incoming message '
               })
           var sendMsg = db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ChatMessage/' + chatusernames)
           sendMsg.child(pushkey)
               .set({
                   "Msg": formValue,
                   "id": localStorage.getItem('makeyacvUser'),
                   "SentTime": new Date().toLocaleString() + '',
                   "Type": 0
               })
           var sendMsg2 = db.ref('UserAccount/' + chatusernames + '/ChatMessage/' + localStorage.getItem('makeyacvUser'))
           sendMsg2
               .child(pushkey)
               .set({
                   "Msg": formValue,
                   "id": localStorage.getItem('makeyacvUser'),
                   "SentTime": new Date().toLocaleString() + '',
                   "Type": 0
               })
   
           var recentName = db.ref("UserAccount/" + localStorage.getItem('makeyacvUser') + "/RecentChat")
           recentName
               .child(chatusernames)
               .update({"Servertime": firebase.database.ServerValue.TIMESTAMP, "id": chatusernames})
           var recentName2 = db.ref("UserAccount/" + chatusernames + "/RecentChat")
           recentName2
               .child(localStorage.getItem('makeyacvUser'))
               .update({
                   "Servertime": firebase.database.ServerValue.TIMESTAMP,
                   "id": localStorage.getItem('makeyacvUser')
               })
   
           setFormValue('');
       }
// responsive
const [responsive,setresponsive] = useState(false)
useEffect(()=>{
    if(window.innerWidth < 500){
        setresponsive(true);
    }
   
},[])

const [openmavbaeres,setopenmavbaeres] = useState(false)

const [modalIsOpenwebsite, setIsOpenwebsite] = React.useState(false);

  function closeModalwebsite() {
    setIsOpenwebsite(false);
    var updatenotice = db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Updatenotice')
    updatenotice.child(document.getElementById('noticeid').value).set({
                   "seen" : true
               })
  }
return (<>
 <div id="blockacc">
<div className="blockacc_container">
    <h1>Your Account has been block by the admin due to some miscellaneous activity</h1>
    <p>For more information contact makeyacv@gmail.com</p>
</div>
 </div>
    <div id="loader">
     <Loading/>
    </div>
    <div id="dashboardmain">
        <input type="hidden" id="noticeid" value="1"></input>
        <Modal
        isOpen={modalIsOpenwebsite}
        onRequestClose={closeModalwebsite}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
      <div className="welcomeMsg">
                    <center><p>UPDATE NOTICE: </p></center>
                   
                    <p>1) You can now edit or add your email address. </p>
                    <p>2) Create you website without "Preview Code"</p>
                    <center>   <h3>Thank you!</h3></center>
                    <center>      <button className="btn btn-default" onClick={e => closeModalwebsite()}>Close</button></center>
                 
                </div>
      </Modal>
     <Modal
                isOpen={modalOpen}
                onRequestClose={e => closeModal(0)}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Example Modal">

                <div className="welcomeMsg text-center">
                    <p>Welcome to MakeYaCV</p>
                    <p id="responwarn">Recommended <br></br>
                        Laptop / Desktop<br></br>
                         View for <br></br>
                         Better Experience</p>
                    <button className="btn btn-default tourbtn" onClick={e => {closeModal();setTimeout(() => {setIsTourOpen(true)},300)}}>Take a Small Tour</button>
                    <button className="btn btn-default" onClick={e => closeModal()}>Close</button>
                </div>

            </Modal>
        <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}/>
       <MenuList prop={modalIsOpen} sectiondivShow1={sectiondivShow} close={e=>closeModal(e)} section={e=>setsectionDiv(e)}/>

        <div className="leftSection sectionDiv" style={{width : !responsive ?'15%' : openmavbaeres ?'100%':'0%'}}>
                <img src="/image/MakeYaCV_Logo_01.svg" id="makeyacvlogo"/>
                
                <div className="leftsidemenuList">
                <span className="glyphicon glyphicon1 glyphicon-remove"  onClick={e=>setopenmavbaeres(false)}></span>
                {menu ?  <>
                                   
                    <nav className="navmenu" id="DashSide" style={{backgroundColor:setcolor('Dashboard')}}>
                        <a><img src="https://img.icons8.com/ios/30/fbff7d/speed.png"/></a>
                        <a onClick={e => {setsectionDiv('Dashboard');setopenmavbaeres(false)}}>Dashboard</a>
                    </nav>
                    <nav className="navmenu" id="HSide" style={{backgroundColor:setcolor('About')}}>
                        <a>
                            <img src="https://img.icons8.com/material-rounded/30/fbff7d/writer-male.png"/></a>
                        <a onClick={e => {setsectionDiv('About');setopenmavbaeres(false)}} >
                            About</a>
                    </nav>
                    {Menu.length == 0 ?
                    <><br/><br/></> : <><hr></hr>
                    <div className="addmenulist">  
                        {Menu && Menu.map(msg => <AddSectionMenu menuname={msg} showsection={e => {setsectionDiv(e);setopenmavbaeres(false)}} />)}
                    </div>
                    <hr></hr></>}
                    
                    <nav className="navmenu" onClick={e => {setsectionDiv('Website');setopenmavbaeres(false)}} style={{backgroundColor:setcolor('Website')}}>
                        <a><img src="https://img.icons8.com/ios/30/fbff7d/green-earth.png"/></a>
                        <a>{createwebiste ? <>Preview Site</> :<>Create Site</>}</a>
                    </nav>
                    <nav className="navmenu" id="CVSide" onClick={e => {setsectionDiv('generatecv');setopenmavbaeres(false)}} style={{backgroundColor:setcolor('generatecv')}}>
                        <a><img src="https://img.icons8.com/ios/30/fbff7d/green-earth.png"/></a>
                        <a>Generate CV</a>
                    </nav>
                    <nav className="navmenu" id="MsgSide"  onClick={e => {setsectionDiv('Message');setopenmavbaeres(false)}} style={{backgroundColor:setcolor('Message')}}>
                        <a>
                            <img src="https://img.icons8.com/ios-filled/30/fbff7d/messaging-.png"/></a>
                        <a>Ya Message
                        </a>
                    </nav>
                    <nav className="navmenu" onClick={e=>setmenu(false)}>
                        <a><img src="https://img.icons8.com/windows/30/fbff7d/user-settings.png"/></a>
                        <a>Account Setting</a>
                    </nav></>
                : 
                   <> 
                <p style={{color:'white',cursor:'pointer',width: 'fit-content', paddingLeft:'10px',fontSize:'20px'}}
                       onClick={e=>setmenu(true)}><span className="glyphicon glyphicon-arrow-left"></span>
                    </p>
                <nav className="navmenu" id="" data-toggle="collapse" data-target="#plan">
                    <a><img src="https://img.icons8.com/ios-filled/30/fbff7d/gantt-chart.png"/></a>
                    <a>My Subscription Plan </a>
                </nav>
                <div id="plan" className="collapse">
                        <hr/>
                        <nav className="navmenu" id="upgradeSide" onClick={e => {setsectionDiv('working');setopenmavbaeres(false)}} style={{backgroundColor:setcolor('working')}}>
                            <a> <img src="https://img.icons8.com/windows/30/fbff7d/upgrade.png" /></a>
                            <a>Upgrade Plan</a>
                        </nav>
                        <nav className="navmenu" id="SubPlanSide" onClick={e => {setsectionDiv('working1');setopenmavbaeres(false)}} style={{backgroundColor:setcolor('working1')}}>
                            <a><img src="https://img.icons8.com/ios-filled/30/fbff7d/gantt-chart.png" /></a>
                            <a onclick=""> History</a>
                        </nav>
                        <hr/>
                </div>
                <nav className="navmenu" id="" data-toggle="collapse" data-target="#privacy">
                        <a> <img src="https://img.icons8.com/ios-filled/30/fbff7d/terms-and-conditions.png" /></a>
                        <a>Privacy and Security <span className="caret"></span></a>
                    </nav>
                    <div id="privacy" className="collapse">
                        <hr/>
                        <nav className="navmenu" id="PassSide" style={{backgroundColor:setcolor('password')}}>
                            <a><img src="https://img.icons8.com/wired/30/fbff7d/re-enter-pincode.png" /></a>
                            <a onClick={e => {setsectionDiv('password');setopenmavbaeres(false)}}>Change Password</a>
                        </nav>
                        <hr/>
                    </div>
                    <nav className="navmenu" id="">
                        <a> <img src="https://img.icons8.com/ios-filled/30/fbff7d/data-setting.png" /></a>
                        <a data-toggle="collapse" data-target="#setting">Customization <span className="caret"></span></a>
                    </nav>
                    <div id="setting" className="collapse">
                        <hr/>
                        {/*<nav className="navmenu" id="WMSide" onclick="ShowWebsite()">
                            <a> <img src="https://img.icons8.com/pastel-glyph/30/fbff7d/earth-planet.png" /></a>
                            <a>Website Modification</a>
                    </nav>*/}
                        <nav className="navmenu" id="websitetempletSide" onClick={e => {setsectionDiv('WebTempletSelect');setopenmavbaeres(false)}} style={{backgroundColor:setcolor('WebTempletSelect')}}>
                            <a> <img src="https://img.icons8.com/ios-filled/30/fbff7d/joomla.png" /></a>
                            <a onclick="changewebsite()">Change Website Templet</a>
                        </nav>
                        <nav className="navmenu" id="cvtempSide" onClick={e => {setsectionDiv('CVTempletSelect');setopenmavbaeres(false)}} style={{backgroundColor:setcolor('CVTempletSelect')}}>
                            <a> <img src="https://img.icons8.com/ios-filled/30/fbff7d/joomla.png" /></a>
                            <a >Change CV Templet</a>
                        </nav>
                        <hr/>
                    </div>
                    <nav className="navmenu" id="" onClick={e=>logoutuser()}>
                        <a> <img src="https://img.icons8.com/ios-filled/30/fbff7d/logout-rounded.png" /></a>
                        <a>Logout</a>
                    </nav>
            </>
                }</div>
            </div>
            <div className="middleSection sectionDiv">
                <div className="topNavbar">
                    <div className="container-fluid">   
                                    <ul className="nav navbar-nav navbar-right help">
                                        <li> <span className="menu" onClick={e=>setopenmavbaeres(true)}>Menu</span></li>
                                    <li className= "notificationicon" onClick={e=>setfreinfdiv(frienddiv => !frienddiv)}>
                                   
                                    <span className={!chatmsgnoti ? "material-icons" :"material-icons newmaterial-icons"}>
                                   question_answer
                                  </span>
         
                                      </li>
                                       
                                        <li id="addsection" className="addsectionlist">
                                            <a onClick={openModal}>
                                                <span className="glyphicon glyphicon-plus"></span>Add Section
                                            </a>
                                        </li>
                                        <li id="userIcontop">
                                            <a>
                                            <div className="dropdown">
                                            {aboutdatadetail ? aboutdatadetail.image ? <img src={aboutdatadetail.image} className="img-responsive img-circle" data-toggle="dropdown"/> :
                                                 <img src="/image/unnamed.png" data-toggle="dropdown"/>:<></>}
                                        
                                                     <ul className="dropdown-menu">
                                                     <li><a href="#">Term and Condition</a></li>
                                                     <li onClick={e=>setmenu(false)}><a >Account Setting</a></li>
                                                         <hr/>
                                                        <li><a onClick={e=>logoutuser()}>Logout</a></li>
                                                     </ul>
                                            </div>
                                               
                                            </a>
                                        </li>
                                    </ul>  
                    </div>
                </div>
                
             
                <div className="mainSectionMiddle">
                  
               {startchat ?<>
          
        <div>
       
        <div className="chatsection" >
               <div className="chatsection-header" >
                   <div className="chatsection-username" id="usernameinage">
                   </div>
                   <p style={{color:'black'}}>Beta
                 </p>
               <span className="material-icons" onClick={e=>{setstartchat(startchat => !startchat);setchatusernames('')}}>
                         close
                      </span>

                    
                 </div> 

                 <div className="MessageSection">
 
            <div className="mainChatindi" id="chatid">
            {chatmessage && chatmessage.map(msg => <ChatMessage key={chatmessage.id} message={msg}/>)}
                <span ref={dummy}></span>
              
            </div>

            <form onSubmit={sendMessage} className="indiForm">
                <div className="showPickerDivindi">
                    

                </div>
                <div className="showPickerDivindi">
                {showEmoji
                        ? <span
                                className="material-icons"
                                onClick={() => setshowEmoji(showEmoji => !showEmoji)}>
                                cancel
                            </span>

                        : <span
                            className="material-icons"
                            onClick={() => setshowEmoji(showEmoji => !showEmoji)}>
                            sentiment_very_satisfied
                        </span>}
                </div>
                <div
                className="picker"
                style={{
                display: showEmoji
                    ? 'block'
                    : 'none'
            }}>
                <Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} native/>
            </div>
                <textarea  value={formValue}
                    ref={ref}
                    onKeyPress={e => {
                    if (e.key !== "Enter") 
                        return;
                }}
                    onChange={(e) => setFormValue(e.target.value)}
                    placeholder="Text Message"></textarea>
                
                <button type="submit" disabled={!formValue} className="btn btn-default">
                    <span className="material-icons micon">send</span>
                </button>
            </form>
            <div className="picker">
            </div>

        </div>
        </div>
        </div>
   
               
                </>:<></>}
                
                 {frienddiv ?   <div className="frinendlistsection">
                 <span className="material-icons" onClick={e=>setfreinfdiv(frienddiv => !frienddiv)}>
                         close
                      </span>
                     <div className="frinendlistsectionmenusection">
                     <div className="frinendlistsectionmenusection_first frinendlistsectionmenusection_div"
                     onClick={e=>setselectsection(0)} style={{opacity : selectsection ? '0.2' : '1'}}>
                         <p>Recent Chat</p>
                    </div>
                     <div className="frinendlistsectionmenusection_second frinendlistsectionmenusection_div"
                      onClick={e=>setselectsection(1)} style={{opacity : !selectsection ? '0.2' : '1'}}>
                         <p>Search Friend</p>
                    </div>
                     </div>
                     <div className="recentchatsection frinendlistsectionmenusection_selectsection"
                      style={{display :selectsection ? 'none' :'block'}}>   
                      {recentList && recentList.map(msg => <RecentList recent={msg} chatuser={chatusernames} chatlist={(e,n,i)=>showuserchat(e,n,i)}/> )}
                     </div>
                     <div className="searchfriendsection frinendlistsectionmenusection_selectsection" 
                     style={{display :!selectsection ? 'none' :'block'}}>
                     <div className="login_form" id="login_section">
                    <div className="form__group field" id="email">

                        {aboutdatadetail ?aboutdatadetail.name ? <> <input type="input" className="form__field"  placeholder="Search Friend" name="userEmail" id='searchfriend' value={frindsearch} 
                       onChange={e=>setfriendsearch(e.target.value)} autoComplete="off"
                        /> <label for="userEmail" className="form__label">Search Friend</label></>:<>
                        <p style={{color:'red',textAlign:'center',fontWeight:'bold'}}>Enter Your Name in the About section</p></> :<></>}
                     
                       
                    </div>
                    </div>
                    <div className="frindlistname">
                        
                        {username && username.map(item=> <FreindList list={item.AboutData} namelist={frindsearch}
                         chatlist={(e,n,i)=>showuserchat(e,n,i)}/>)}
                    </div>
                     </div>
                   <a href="https://justchat.priyonujdey.in/" target="_blank"><p style={{position:'absolute',bottom:'0',color:'black',width:'100%',fontSize:'10px',fontWeight:'bolder'}}>Powered by justchat.priyonujdey.in</p></a>
                 </div> : <></>}
               
                <span
                        style={{
                        display: displayDiv('Dashboard')
                    }}>
                         <div className="container-fluid">
                            <center>
                                <label className="labelHead" style={{color:'black'}}>Dashboard</label>
                            </center>
                            <div className="dashfirst">
                                <div className={window.innerWidth > 1024 ? 'col-md-4 profilecomplition' : 'col-sm-6 profilecomplition'}>
                                    <Completion/>
                                </div>
                                <div className={window.innerWidth > 1024 ? 'col-md-4 analyticwebsite' : 'col-sm-6 analyticwebsite'}>
                                  <Analytic/>
                                </div>
                                <div className={window.innerWidth > 1024 ? 'col-md-4 profilenotification' : 'col-sm-6 profilenotification'}>
                                    <div className="dashbox">
                                        <h1>Notification</h1>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <button className="btn-notify" id="profilenotificationPreviewchild" 
                                                      onClick={e=>setbtnNotify(0)}
                                                    style={{opacity : btnNotify ? '0.2' : '1'}}
                                                    >
                                                        Profile
                                                        <span id="profilenotifybell" className="glyphicon glyphicon-user"></span>
                                                    </button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button className="btn-notify" id="messagenotificationPreviewchild"
                                                      onClick={e=>setbtnNotify(1)}
                                                    >
                                                        <span style={{opacity : !btnNotify ? '0.2' : '1'}}>Message  
                                                        <span id="msgnotifybell" className="glyphicon glyphicon-envelope"></span></span>
                                                        <sup id="unreadMsgNotify">

                                                        </sup>
                                                    </button>
                                                    
                                                </div>
                                                {!btnNotify ? <div className="col-xs-12">
                                                    <div id="messagenotificationPreview">
                                                    {notificationprof.length == 0 ? <h4 style={{textAlign : 'center'}} >No Data</h4>:
                                                    notificationprof && notificationprof.map(data => 
                                                    <Profilenoti msg={data} notificationprof={e => setsectionDiv('Message')}/>)}
                                                    </div>
                                                </div>:  <div className="col-xs-12">
                                                    <div id="profilenotificationPreview">
                                                        {notificationmsg.length == 0 ? <h4 style={{textAlign : 'center'}} >No Message</h4>:
                                                        notificationmsg && notificationmsg.map(data => 
                                                        <Messagenoti msg={data} showmsg={e => setsectionDiv('Message')}/>)} 
                                                    </div>
                                                </div>}
                                                
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 jobsearch">
                                    <div className="dashboxjob">
                                        <h1>Job Search Section</h1>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p id="coming">
                                                        COMING SOON</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*<div className=" col-sm-6">
                                    <div className="dashbox">
                                        <h1>Advertisement Section</h1>
                                        <div className="addsection"></div>
                                    </div>
                                                        </div>
                                <div className="col-md-4">
                                    <div className="dashbox">
                                        <h1>Activity Log</h1>
         
                                        <div id="activitylog"><p id="coming">
                                                        COMING SOON</p></div>
                                    </div>
                                </div>*/}

                            </div>
                        </div>
                    </span>
                    <span
                        style={{
                        display: displayDiv('About')
                    }}><About/></span>
                    <span
                        style={{
                        display: displayDiv('Education')
                    }}><Education/></span>
                    <span
                        style={{
                        display: displayDiv('WorkExperience')
                    }}><WorkExperience/></span>
                    <span
                        style={{
                        display: displayDiv('TeachingExperience')
                    }}><TeachingExperience/></span>
                    <span
                        style={{
                        display: displayDiv('Publication')
                    }}><Publication/></span>
                    <span
                        style={{
                        display: displayDiv('ResearchArea')
                    }}><ResearchArea/></span>
                    <span
                        style={{
                        display: displayDiv('Skill')
                    }}><Skill/></span>
                     <span
                        style={{
                        display: displayDiv('Project')
                    }}><Project/></span>
                    <span
                        style={{
                        display: displayDiv('Website')
                    }}><Website changewebsite={e=>setsectionDiv(e)}/></span>
                   <span
                        style={{
                        display: displayDiv('ProfessionalMembership')
                    }}><ProfessionalMembership/></span>
                      <span
                        style={{
                        display: displayDiv('Links')
                    }}><Links/></span>
                    <span
                        style={{
                        display: displayDiv('Training/ Course')
                    }}><TrainingCourse/></span>
                      <span
                        style={{
                        display: displayDiv('Achivement/ Award / Honour')
                    }}><Achivement/></span>
                    <span
                        style={{
                        display: displayDiv('Conference/ Symposium / Workshop')
                    }}><Conference/></span>
                    <span
                        style={{
                        display: displayDiv('Certification')
                    }}><Certification/></span>
                      <span
                        style={{
                        display: displayDiv('password')
                    }}><Password/></span>
                    <span
                        style={{
                        display: displayDiv('Message')
                    }}><Message/></span>
                    <span
                        style={{
                        display: displayDiv('generatecv')
                    }}><CV cvpage={e=>setsectionDiv(e)}/></span>
                    <span
                        style={{
                        display: displayDiv('working')
                    }}><Working/></span>
                       <span
                        style={{
                        display: displayDiv('working1')
                    }}><Working/></span>
                     <span
                        style={{
                        display: displayDiv('CVTempletSelect')
                    }}><CVTempletSelect changecv={e=>setsectionDiv(e)}/></span>
                    <span
                        style={{
                        display: displayDiv('WebTempletSelect')
                    }}><WebTempletSelect changewebsite={e=>setsectionDiv(e)} preview={createwebiste}/></span>

                  
                </div>
                <div className="bottomNavbar">
                <p>Under development

                </p>
                <p><span className="material-icons" style={{fontSize:'15px'}}>mail</span> makeyacv@gmail.com</p>
         
                </div>
            </div>
            <div className="rightSection sectionDiv">
               
            </div>
            <ToastContainer />
        </div>
        </>
    );
}
function Messagenoti(props)
{
    const removeMsgNotify = e =>{
        db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Notification/Message/'+e).remove()
    }
    const handleInputChange = useCallback(e => {
        var changebg =  db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Notification/Message/'+e).update({
                "bgcolor" :'grey'
        })
        props.showmsg()
    }, [props.showsection])
    var {DateTime,Name,bgcolor,id} = props.msg;
    return(<div className="container-fluid" style={{backgroundColor: bgcolor}} >
        <div className="row">
        <div className="col-xs-12 msgnotifyVal">
        <li onClick={e=>handleInputChange(id)}>
        <label>{DateTime}</label>
        <p style={{cursor:"pointer"}}>New Message from <br/>" {Name} "</p>
        </li>
        <span style={{cursor:'pointer'}} className="glyphicon glyphicon-remove" onClick={e=>removeMsgNotify(id)}></span>
        </div>
         </div></div>
      )
}
function Profilenoti(props)
{
    const removeMsgNotify = e =>{
        db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Notification/Profile/'+e).remove()
    }
    var {DateTime,Message,id} = props.msg;
    return(<div className="container-fluid"  >
        <div className="row">
        <div className="col-xs-12 msgnotifyVal">
        <li >
        <label>{DateTime}</label>
        <p style={{cursor:"pointer"}}>{Message} </p>
        </li>
        <span style={{cursor:'pointer'}} className="glyphicon glyphicon-remove" onClick={e=>removeMsgNotify(id)}></span>
        </div>
         </div></div>
      )
}
function AddSectionMenu(props) {

    var {Name,image} = props.menuname;
    const handleInputChange = useCallback(event => {
        props.showsection(event)
    }, [props.showsection])
    return (
        <nav
            className="navmenu"
            id="HSide"
            onClick={(e) => handleInputChange(Name.replace(' ', ''))}  style={{backgroundColor:setcolor(Name.replace(' ', ''))}}>
            <a>
                <img src={image.replace('000000','fbff7d')}/></a>
            <a>
                {Name}</a>
        </nav>
    )
}
function FreindList(props){
    const handleInputChange = useCallback((e,n,i) => {
        props.chatlist(e,n,i)
    }, [props.showsection])
    return(<>{props.list.name ?<>{props.namelist.trim() ?<>
        {props.list.name.toLowerCase().includes(props.namelist.toLowerCase()) ? <> 
        <li onClick={e=>handleInputChange(props.list.email,props.list.name,props.list.image)}>{props.list.image ? <img src={props.list.image} /> : <img src="/image/unnamed.png"/> }
        {props.list.name}</li></>:<></>}</> : <><li></li></>}</> : <></>}</>)
}
function ChatMessage(props) {
    const {Msg, id, SentTime, Type,FileName} = props.message;
    const messageClass = localStorage.getItem('makeyacvUser') === id
        ? 'received'
        : 'sent';

    return ( <> <div className={`message ${messageClass}`}>

        {Type === 0
            ? <>< p > {
                Msg
            } <br/><label>{
                SentTime
            }</label> </p> </> 
            :Type === 1 ? <><p className="imageMessage"><a href={Msg} target="_blank" download={FileName} data-tip="Click to download"> 
            <span className="material-icons">
            file_download
            </span > </a> < img src = {
                Msg
            }
            alt = "image" style = {{width:'70%'}}/> 
             </p > <br></br > < label > {
                SentTime
            } </label> </>:<></>}

    </div> </>)
  }
function RecentList(props) {

    const handleInputChange = useCallback((e,n,i) => {
        var r = db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ChatNotification/'+props.recent.id).remove();
        props.chatlist(e,n,i)
    }, [props.showsection])
var [username] = useObjectVal(db.ref('UserAccount/' + props.recent.id + '/AboutData'));
var [chatnotificationmsg,chatnotificationmsgload] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ChatNotification/'+props.recent.id));
return ( <>{username ?<><li onClick={e=>handleInputChange(username.email,username.name,username.image)}><img src={username.image ? username.image : "/image/unnamed.png"} alt={username.name}/> {username.name}
{props.recent.id !== props.chatuser && chatnotificationmsg ? <span className="material-icons add blinkspan" style={{fontSize:'15px',color:'red',marginTop:'15px'}}>
    mark_chat_unread
</span> :<></>}
</li></> :<></>} </>
    )
}
export default Dashboard;
