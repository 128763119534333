import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function Training(props) {
    userid = props.id
    var [Trainingdata] = useListKeys(db.ref('UserAccount/' + userid + '/TrainingCourse'));
  

    return ( <> {
        Trainingdata && Trainingdata.map(data => <Trainingval edu={data}/>)
    } </>)
}
var countedu = []

function Trainingval(props) {
    var [Trainingdata] = useListVals(db.ref('UserAccount/' + userid + '/TrainingCourse/'+props.edu));
    Trainingdata.sort(function (a, b) {
        return parseFloat(b.confstartDate) - parseFloat(a.confstartDate);
    });
    return(<>   <div className="col-md-12 section_headert1">
    <h2>{props.edu}</h2>
</div>{
        Trainingdata && Trainingdata.map(data => <TrainingvalDetail edu={data}/>)
    }</>)
}
function TrainingvalDetail(props)
{
  var {
    confDes,conftitle,
    confcompany,conflocation,
    confendDate,
    id,
    confstartDate,confyour
} = props.edu;
if(countedu.indexOf(id) == -1 && id) {
    countedu.push(id)
   
}
return (<>
    <div className="col-sm-6 col-xs-12">
      <div className="container-fluid traint1">
         <div className="row">
            <div className="col-xs-12">
           <h3>{conftitle}</h3>
        <p><i>{confcompany}</i> </p>
        {conflocation ?<p>{conflocation}</p>:<></> }
        {confyour ?<p>Role : {confyour}</p>:<></> }
        <p><span className="glyphicon glyphicon-calendar"></span> {confstartDate} - {confendDate}</p>
        {confDes
            ? <> <button
                type="button"
                class="btn btn-default btn-descp"
                data-toggle="modal"
                data-target={"#myModal" + id}>Description</button> 

                <div id = {"myModal" + id} class = "modal fade modalt1" role = "dialog" > 
                <div class="modal-dialog">

                <div class="modal-content">
                    
                    <div class="modal-body">
                     {confDes ? <p>Description :<br/> <b>{parse(confDes.replaceAll('\n','<br/>'))}</b></p> : <></>}
                     
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default  btn-descp" data-dismiss="modal">Close</button>
                    </div>
                </div>

            </div> </div>  
            </ >
            : <></>}
    </div> </div>
     </div> </div> </>)
}
export default Training