import React, {useState, useCallback} from 'react';
import './Templetselect.css'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import firebase from 'firebase'
import 'firebase/database'
import config from '../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
toast.configure()
const db = firebase.database();
function CVTempletSelect({changecv}) {
    const selecttemplet = (e,size) => {
        var cv =  db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Templet/CV').update({
            "Templet" :e ,
            "Page" : size
        })

        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
                     log.push().update({
                         "DateTime" : new Date().toLocaleString() + '',
                         "Message": 'CV Templet Changed'
                     })
                     toast('CV Templet Changed', {
                        style: {
                            backgroundColor:'#B7F9AA',
                            color:'black',
                            fontSize:'20px',
                        },
                        autoClose:1000
                      });
                      
                        changecv("generatecv")
                 
   
    }

  

    return ( <> <center>
        <label class="labelHead">Changed CV Templet</label>
    </center> < div class = "container-fluid" > <div class="col-sm-12">
        <div class="cvtempletimg">

            <div class="container-fluid ">
                <div className="row">
                    <div className={window.innerWidth > 1024 ? 'col-md-3 ' : 'col-sm-6 '}>
                        <div className="cvtempletselect">
                            <img src="/image/CVTemplet/cv1.png"/>
                            <div className="cvtempletselect_selectbtn">
                                <button className="btn btn-primary" onClick={e => selecttemplet('1','a4')}>SELECT</button>
                            </div>
                        </div>

                    </div>
                    <div className={window.innerWidth > 1024 ? 'col-md-3 ' : 'col-sm-6 '}>
                        <div className="cvtempletselect">
                            <img src="/image/CVTemplet/cv2.png"/>
                            <div className="cvtempletselect_selectbtn">
                                <button className="btn btn-primary" onClick={e => selecttemplet('2','a4')}>SELECT</button>
                            </div>
                        </div>
                    </div>
                    <div className={window.innerWidth > 1024 ? 'col-md-3 ' : 'col-sm-6 '}>
                        <div className="cvtempletselect">
                            <img src="/image/CVTemplet/cv3.png"/>
                            <div className="cvtempletselect_selectbtn">
                                <button className="btn btn-primary" onClick={e => selecttemplet('3','a4')}>SELECT</button>
                            </div>
                        </div>
                    </div>
                    <div className={window.innerWidth > 1024 ? 'col-md-3 ' : 'col-sm-6 '}>
                        <div className="cvtempletselect">
                            <img src="/image/CVTemplet/cv4.png"/>
                            <div className="cvtempletselect_selectbtn">
                                <button className="btn btn-primary" onClick={e => selecttemplet('4','a4')}>SELECT</button>
                            </div>
                        </div>
                    </div>
                    <div className={window.innerWidth > 1024 ? 'col-md-3 ' : 'col-sm-6 '}>
                        <div className="cvtempletselect">
                            <img src="/image/CVTemplet/cv5.png"/>
                            <div className="cvtempletselect_selectbtn">
                                <button className="btn btn-primary" onClick={e => selecttemplet('5','celebal')}>SELECT</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="">
                            <h1
                                style={{
                                color: 'black',
                                textAlign: 'center'
                            }}>More Templet Coming Soon......</h1>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div> </div > </ >)
}

export default CVTempletSelect;