import parse from 'html-react-parser';
import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid
function Skill(props){
    userid = props.id
    var [Skilldata] = useListKeys(db.ref('UserAccount/' + userid + '/Skill'));
    
return(<> <p className="p">skill</p><ul>
          {Skilldata && Skilldata.map(data => <Skilltype edu={data}/>)}</ul>
</>)
}

function Skilltype(props)
{
    var type = props.edu
    var [skilldata] = useListVals(db.ref('UserAccount/' + userid + '/Skill/'+props.edu));
    skilldata.sort(function (a, b) {
        return parseFloat(b.SkillRating) - parseFloat(a.SkillRating);
    });
    return(<>
    {type ? <li style={{paddingLeft:"10px"}}><b>{type.replaceAll('+',' ')} :</b> 
    {skilldata && skilldata.map(item=> <SkillTypeval val={item}/>)}<br/>
    </li>: <></>}
    </>

    );
}

function SkillTypeval(props) {
    var {Skill} = props.val;
    return(<> {Skill} , </>)
}
export default Skill;