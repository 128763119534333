import React, {useState, useCallback} from 'react';
import ReactTooltip from 'react-tooltip';
import './message.css'
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();

function Message() {
    const [previewmsg,setpreviewmsg] = useState(false)
    const [email,setemail] = useState()
    const [subject,setsubject] = useState()
    const [message,setmessage] = useState()
    var [previewdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Message'));
    const previewmseeage = (a,b,c)=>{
        setpreviewmsg(true)
        setemail(a)
        setsubject(b)
        setmessage(c)
    }
    return (
        <section id="home">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">

                        <div className="first">
                            <center>
                                <label className="labelHead">Message</label>
                            </center>
                            <div id="messagePreview">
                            {previewdata.length == 0 ? <h1 style={{textAlign : 'center',marginTop:'30%',letterSpacing : '0.2em',fontWeight:'bolder'}} >No Message</h1>:
                                previewdata && previewdata.map(data => <Messagepreview msg={data} 
                                preview={(a,b,c)=>previewmseeage(a,b,c)}
                                 clear={e=>{setpreviewmsg(false);setemail('');setsubject('');setmessage('')}}/>)}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="second">

                            <div id="messageValuePreview">
                                {previewmsg ? 
                                <div class="container-fluid messageVal">
                                    <div class="col-xs-12"><label>Email</label><p>{email}</p></div>
                                    <div class="col-xs-12"><label>Subject</label><p>{subject}</p></div>
                                    <div class="col-xs-12"><label>Message</label><p>{message}</p></div>
                                 </div>
                                :<></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
function Messagepreview(props) {
    const handleInputChange = useCallback((a,b,c,id) => {
       var changebg = db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Message/'+id).update({"bgcolor" : 'grey'})
        props.preview(a,b,c)
    }, [props.showsection])
    const deletechange = useCallback((id) => {
        var changebg = db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Message/'+id).remove()
         props.clear()
     }, [props.showsection])
    var {
        DateTime,
        Email,
        Message,
        Name,
        Subject,
        bgcolor,
        id
    } = props.msg
    return ( <>< div class = "container-fluid" > 
    <div className="col-xs-10">
        <div
            className="buttonmessage"
            onClick={e=>handleInputChange(Email,Subject,Message,id)} style={{backgroundColor:bgcolor}}>
                <p>{Name}  <span>{DateTime}</span></p>
        </div>
    </div>
    <div className = "col-xs-2">
        <span class="glyphicon glyphicon-trash messagedelete" onClick={e=>deletechange(id)}></span>
    </div>
     </div></ >)
}
export default Message;