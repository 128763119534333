
import React, {useState, useCallback} from 'react';
import "../common.css"
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import {useListVals,useListKeys} from 'react-firebase-hooks/database';
import ReactTooltip from 'react-tooltip'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();

function Conference() {

    var [previewdata] = useListKeys(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Conference'));
    previewdata.sort(function(a, b) {
        return parseFloat(b.workstartDate) - parseFloat(a.workstartDate) ;
    });
    const [selectval,setsetval] = useState('none');
    const [edstatus,
        setedstatus] = useState(false)
    const [type,settype] = useState('none')
const [role,setrole]  = useState('none')
    const [editInput,
        seteditinput] = useState(false)

    const subworkshop = e => {
        e.preventDefault();
        var workstartDate = document
            .getElementById('workstartDate')
            .value;
        if ((document.getElementById('worktitle').value.length === 0) || (workstartDate.length === 0)) {
            window.alert(type + " Title /  Start Date  cannot be empty");
            return;
        }
        if(!edstatus)
        {
            if( document.getElementById('workendDate').value.length === 0)
              {
                  window.alert(type + " End Date cannot be empty");
                  return
              }
        }
        var pushkey;
        if (editInput) {
            pushkey = document
                .getElementById('id')
                .value
        } else {
            pushkey = db.ref()
                .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Conference/'+type)
                .push()
                .key;
        }

        var exInsert = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Conference/'+type);
        exInsert
            .child(pushkey)
            .update({"id": pushkey});

       exInsert.child(pushkey).update({
                "Type" : type,
                'worktitle': document.getElementById('worktitle').value,
                'workstartDate': workstartDate
         })
         if (document.getElementById('workcompany').value.length != 0) {
            exInsert.child(pushkey).update({
                'workcompany': document.getElementById('workcompany').value
            })
        }else{
            exInsert.child(pushkey+'/workcompany').remove();
        }
        
        if (document.getElementById('worklocation').value.length != 0) {
            exInsert.child(pushkey).update({
                'worklocation': document.getElementById('worklocation').value
            })
        }
        else{
            exInsert.child(pushkey+'/worklocation').remove();
        }
       
        if (document.getElementById('workDes').value.length != 0) {
            exInsert.child(pushkey).update({
                'workDes': document.getElementById('workDes').value
            })
        }
        else{
            exInsert.child(pushkey+'/workDes').remove();
        }
        
        if (edstatus) {
            exInsert
                .child(pushkey)
                .update({"workendDate": 'present'})
        } else {
            exInsert
                .child(pushkey)
                .update({
                    "workendDate": document
                        .getElementById('workendDate')
                        .value
                });
        }
       
        if (document.getElementById('workrole').value != 'none') {
            exInsert.child(pushkey).update({
                'workrole': document.getElementById('workrole').value
            })
            if(document.getElementById('workrole').value == 'Presenter')
            {
                if (document.getElementById('workPaper').value.length != 0) {
                    exInsert.child(pushkey).update({
                        'workPaper': document.getElementById('workPaper').value
                    })
                }
                else{
                    exInsert.child(pushkey+'/workPaper').remove();
                }
            }
        }
        else{
            exInsert.child(pushkey+'/workrole').remove();
        }

        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
        log.push().update({
            "DateTime" : new Date().toLocaleString() + '',
            "Message": type + ' Data modified'
        })
        document.querySelector('.doneworkshop').style.display = 'block';
        setTimeout(() => {
            setsetval(selectval)
           // document.getElementById('pubListVal').value = selectval
            reset();
            document
                .querySelector('.doneworkshop')
                .style
                .display = 'none';
        }, 2500);
    }


    const editDatacontent = (e,t) => {
         reset();
         settype(t);
        
        setTimeout(() =>{
            seteditinput(true);
            document.getElementById('workshopType').value = t;
            document.getElementById('workStatus').value= 0
            var experiencePreviewId = db
                .ref()
                .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Conference/' + t+'/'+e);
             experiencePreviewId.once('value', function (snapshot) {
                snapshot
                    .forEach(function (elem) {     
              if(elem.key != 'Type')
              {
              if (elem.key === 'workendDate') {
                  if(elem.val() === 'present'){
                    setedstatus(true);
                   document.getElementById('workStatus').checked = 1
                
                }
                else{
                   setedstatus(false);
                  document.getElementById('workStatus').checked = 0
                    setTimeout(() => {
                        document.getElementById(elem.key).value = elem.val();
                    }, 300);
                   
                }
                 }
                else{
                   document.getElementById(elem.key).value = elem.val();
                }
                
                
              }                      
            })
        })
        },300)
           
      
      
    }
   
    const reset = e => {
        document.getElementById("workStatus").checked = '0'
        document
            .getElementById("workshopform")
            .reset(); 
       // seteditinput(false);
      
     
        setedstatus(false)
        setrole('none')
        setTimeout(() => {document.getElementById('workshopType').value = type},200)
        
    }
  
    const  deletetrainc = (e,t)=>{

        //setsetval('none')
        var experiencePreviewId = db
         .ref()
         .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Conference/' + t+'/'+e)
         .remove();
         setTimeout(() => {
            setsetval('none')  
            document.getElementById('pubListVal').value='none'
         }, 200);
         var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
        log.push().update({
            "DateTime" : new Date().toLocaleString() + '',
            "Message": t + ' Data modified'
        })
          reset();
     }
    var [selectdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Conference/'+selectval));
    return (
        <div >
            <div className="success-checkmark doneworkshop">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div class="col-sm-6 ">
                            <div className="first">
                                <center>
                                
                                    {editInput
                                        ?  
                                        type == 'none' ? 
                                        <label className="labelHead">Edit Conference / Symposium /  Workshop</label> :
                                         type == 'Workshop' ?  
                                         <label className="labelHead">Edit <s>Conference / Symposium / </s>  Workshop </label> :
                                         type == 'Symposium' ?  
                                         <label className="labelHead">Edit <s>Conference / </s> Symposium  <s>/  Workshop</s> </label>:
                                         <label className="labelHead">Edit Conference  <s>/ Symposium /  Workshop</s></label>

                                        :type == 'none' ? 
                                        <label className="labelHead">Insert Conference / Symposium /  Workshop</label> :
                                         type == 'Workshop' ?  
                                         <label className="labelHead">Insert <s>Conference / Symposium / </s>  Workshop </label> :
                                         type == 'Symposium' ?  
                                         <label className="labelHead">Insert <s>Conference / </s> Symposium  <s>/  Workshop</s> </label>:
                                         <label className="labelHead">Insert Conference  <s>/ Symposium /  Workshop</s></label>}
                                </center>
                                <form id="workshopform" onSubmit={subworkshop}>
                                    <div id="login_div" className="main-div">
                                        <input type="hidden" className="borLessInput" id="id"/>

                                        <span class="label">Select Type</span>
                                        <select id="workshopType" class="borLessInput" onChange={e=>settype(e.target.value)}>
                                            <option value="none">-</option>
                                            <option>Conference</option>
                                            <option>Symposium</option>
                                            <option>Workshop</option>
                                        </select>
                                        {type == 'none' ? <></> : 
                                        <>
                                        <span class="label">{type == 'none' ? <></> : type} Title </span>
                                        <input type="text" placeholder={type +" Title"} id="worktitle" class="borLessInput"/>
                                        <span class="label">Organisation</span>
                                        <input type="text" placeholder="Organisation " id="workcompany"
                                            class="borLessInput"/>
                                       <span class="label"> Location</span>
                                        <input type="text" placeholder=" Location " id="worklocation"
                                            class="borLessInput"/>
                                        <span class="label">Role</span>
                                        <select id="workrole" class="borLessInput" onChange={e=>setrole(e.target.value)}>
                                            <option value="none">-</option>
                                            <option>Coordinator</option>
                                            <option>Participant</option>
                                            <option>Presenter</option>
                                        </select>
                                        {role == 'Presenter' ? <><span class="label"> Paper Title</span>
                                            <input type="text" placeholder=" Paper Title " id="workPaper"
                                                class="borLessInput"/></>:<></>}
                                       
                                        <div class="form-group">
                                            <label >
                                                On Going  <input
                                                    type="checkbox"
                                                    id="workStatus"
                                                    onChange={e => setedstatus(e.target.checked)}
                                                   />
                                            </label><br/>

                                        </div>

                                        <div className="dateYear">
                                            <div className="form-group">

                                                <span class="label">Start date</span>
                                                <input type="date" id="workstartDate" class="borLessInput"/>

                                            </div>
                                            <div className="form-group" id="pass">

                                                <span class="label">End date</span>
                                                {edstatus
                                                    ? <h3>Present</h3>
                                                    : <input type="date" id="workendDate" class="borLessInput"/>}

                                            </div>
                                        </div>
                                        <span class="label">Description</span>
                                        <textarea id="workDes" placeholder="Description (optional)"
                                            class="borLessInput"></textarea>

                                        {editInput
                                            ? <div id="">
                                                    <div className="col-xs-6">
                                                        <button className="btn btn-default btn-edit btn-submit" onclick="updateedu()">Update</button>
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <button
                                                            className="btn btn-edit btn-default"
                                                            type="reset"
                                                            onClick={e => reset()}>Reset</button>
                                                    </div>
                                                </div>
                                            : <div id="">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="submit">Submit</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button
                                                        className="btn btn-edit btn-default"
                                                        type="reset"
                                                        onClick={e => reset()}>Reset</button>
                                                </div>
                                            </div>}

                                    </>
                                        }
                                        
                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div className="second">
                                <center>
                                    <label class="headi labelHead">Preview
                                    </label>
                                </center>
                                <div id="login_div" class="main-div">

                                <select id="pubListVal " onChange={e=>setsetval(e.target.value)}>
                                    <option value='none'>Select Type</option>
                                    {previewdata && previewdata.map(data => <Previewdata prop={data} />)}
                                   </select>
                                   <div id='pubvalueappend'>
                                   {selectdata && 
                                   selectdata.map(data => 
                                   <TrainCdetail prop={data} deletetc={(e,t)=>deletetrainc(e,t)} edittrainc={(e,t)=>editDatacontent(e,t)}/>)}
                                    </div>
                                </div>   
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

var error = 0;
function Previewdata(props) {
    var pubTypeItem = []  
    const handleInputChange = useCallback(event => {
        props.editdata(event)
    }, [props.showsection])
  // var [previewdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Conference/'));
    if(props.prop){
       // console.log(pubTypeItem.indexOf(props.prop))
        if(pubTypeItem.indexOf(props.prop) === -1)
        {
            pubTypeItem.push(props.prop) 
            var dec = props.prop;
            error = 0;
        }
        else error = 1
    }
   
    return (<>
        {error ? <></>: <option value={dec}>{dec}</option>}
        </>
    ) 

}
function TrainCdetail(props)
{

    const handleInputChangeD = useCallback((event,type) => {
        props.deletetc(event,type)
    }, [props.showsection])
    
    const handleInputChangeE  = useCallback((event,type) => {
        props.edittrainc(event,type)
    }, [props.showsection])
   if(props.prop)
     {
         var {id,workDes,workstartDate,workcompany,worktitle,Image,Type,workendDate,worklocation,workrole,workPaper}= props.prop;
     }
    return (<> <div class="container-fluid">
        <div class="col-xs-3 exImg">
                {Image
                    ? <img src={Image} width="90%"/>
                    : <center><img
                        src="/image/logo_here.png"
                        width="90%"
                        style={{
                        marginTop: '30px'
                    }}/></center>}

            </div>
    <div class="col-xs-7">
    <b><p>{worktitle}</p></b>
    {workcompany ?<p>{workcompany}</p> :<></>}
    {worklocation ?<p>{worklocation}</p> :<></>}
    {workrole ? <p>Role: {workrole}</p>:<></>}
     {workPaper ? <p>Paper Title: {workPaper}</p>:<></>}
    <p>{workstartDate} - {workendDate} </p>
    {workDes
                    ? <div>
                            <button
                                class="btn btn-default bnt1"
                                data-toggle="collapse"
                                data-target={'#' + id}>Description</button>
                            <div id={id} className="collapse">
                            

                                <p>{workDes}</p>
                            </div>
                        </div>
                    : <div></div>}
    </div> 
    <div class="col-xs-2">
    
       <button    data-tip="Delete" class="btn bthBorderLess btn-default" onClick={(e,t)=>handleInputChangeD(id,Type)}>
           <span class="glyphicon glyphicon-trash" ></span>
       </button> 
       <button
        data-tip="Edit"
           onClick={(e,t) => handleInputChangeE(id,Type)}
           class="btn bthBorderLess btn-default">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
    </div>
    <ReactTooltip /> 
    </div>   <hr/></>)
}
export default Conference;
