import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function Education(props) {
    userid = props.id
    var [educationdata] = useListVals(db.ref('UserAccount/' + userid + '/Education'));
    educationdata.sort(function (a, b) {
        return parseFloat(b.startDate) - parseFloat(a.startDate);
    });

    return ( <> <p className="p">Education</p>
        <table>
            <thead> 
            <tr><th>YEAR</th>  <th>DEGREE</th><th>INSTITUTE NAME</th> <th>DEPARTMENT</th>  <th>CGPA / %</th>  </tr>
            </thead>   
            <tbody>
            {educationdata && educationdata.map(data => <Educationval edu={data}/>)}
            </tbody>
        </table>
     </>)
}
var countedu = []
function Educationval(props)
{
  var {
    Image,
    branchDegreename,
    deptClassname,
    endDate,
    id,
    schoolCollegename,gradeName,
    startDate,supervisor,titleDes,titleName,projecttitle
} = props.edu;

return (<>
<tr>
<td>{startDate.split('-')[0]} - {endDate.split('-')[0]} </td>
<td>{branchDegreename}</td>
<td>{schoolCollegename}</td>
<td>{deptClassname}</td>
<td>{gradeName ? gradeName : <>-</>}</td>
</tr>
   </>)
}
export default Education