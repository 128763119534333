
import React, {useState, useCallback} from 'react';
import "../common.css"
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import {useListVals,useListKeys} from 'react-firebase-hooks/database';
import Compressor from 'compressorjs'
import ReactTooltip from 'react-tooltip'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target.result)
    };
    reader.readAsDataURL(file);
})
function Achivement() {

    var [previewdata] = useListKeys(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Achivement'));
    previewdata.sort(function(a, b) {
        return parseFloat(b.projstartDate) - parseFloat(a.projstartDate) ;
    });
    const [selectval,setsetval] = useState('none');
    const [type,settype] = useState('none')

    const [editInput,
        seteditinput] = useState(false)
     const [dataUri,
            setDataUri] = useState('')
    const subachive = e => {
        e.preventDefault();
        var achivdate = document
            .getElementById('achivdate')
            .value;
        if ((document.getElementById('achivtitle').value.length === 0) || (achivdate.length === 0) || (document.getElementById('achivtitle').value.length === 0)) {
            window.alert(type + " Title /  Date  cannot be empty");
            return;
        }

        var pushkey;
        if (editInput) {
            pushkey = document
                .getElementById('id')
                .value
        } else {
            pushkey = db.ref()
                .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Achivement/'+type)
                .push()
                .key;
        }

        var exInsert = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Achivement/'+type);
        exInsert
            .child(pushkey)
            .update({"id": pushkey});

       exInsert.child(pushkey).update({
                "Type" : type,
                'achivtitle': document.getElementById('achivtitle').value,
                'achivdate': achivdate
         })
         if (document.getElementById('achivorg').value.length !== 0) {
            exInsert.child(pushkey).update({
                'achivorg': document.getElementById('achivorg').value
            })
        }else{
            exInsert.child(pushkey+'/achivorg').remove();
        }
        
        if (document.getElementById('achivDesc').value.length !== 0) {
            exInsert.child(pushkey).update({
                'achivDesc': document.getElementById('achivDesc').value
            })
        }
        else{
            exInsert.child(pushkey+'/achivDesc').remove();
        }
       
        if (dataUri !== '') 
            exInsert.child(pushkey).update({"Image": dataUri});
            var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
            log.push().update({
                "DateTime" : new Date().toLocaleString() + '',
                "Message": type + ' Data Modified'
            })
       
        document
            .querySelector('.doneachive')
            .style
            .display = 'block';
        setTimeout(() => {
            reset();
            document
                .querySelector('.doneachive')
                .style
                .display = 'none';
        }, 2500);
    }


    const editDatacontent = (e,t) => {
         reset();
         settype(t);
            document.getElementById('achiveType').value = t;
        
            seteditinput(true);
            setTimeout(() => {
                document.getElementById('achiveType').value = t;
                var experiencePreviewId = db
                .ref()
                .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Achivement/' + t+'/'+e);
             experiencePreviewId.once('value', function (snapshot) {
                snapshot
                    .forEach(function (elem) {     
                      
              if(elem.key !== 'Type')
              {
                try{  if (elem.key === 'Image') {
                    setDataUri(elem.val())
                }
                else{
                   document.getElementById(elem.key).value = elem.val();
                }}
                catch{}   
              
              }                      
            })
        })
            }, 200);   
    }
    const onChange = (file) => {
        //console.log(file) setfilename(file.name)
        if (!file) {
            setDataUri('');
            return;
        }
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            if (file.size / 1024 > 1024) {
                alert("File size should be less than 1 MB")
            } else {
                var qt = 0.8;
                if (file.size / 1024 > 800) {
                    qt = 0.6
                }
                new Compressor(file, {
                    quality: qt, // 0.6 can also be used, but its not recommended to go below.
                    success: (compressedResult) => {
                        fileToDataUri(compressedResult).then(dataUri => {
                            setDataUri(dataUri)
                        })
                    }
                });
            }
        } else {
            alert("image is supported")

        }
    }
    const reset = e => {
     
        document
            .getElementById("achiveform")
            .reset(); 
        seteditinput(false);
        setDataUri('')
        setTimeout(() => {document.getElementById('achiveType').value = type},200)
        
    }
    const  deletetrainc = (e,t)=>{

        //setsetval('none')
        var experiencePreviewId = db
         .ref()
         .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Achivement/' + t+'/'+e)
         .remove();
         var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
         log.push().update({
             "DateTime" : new Date().toLocaleString() + '',
             "Message": t + ' Data deleted'
         })
         setTimeout(() => {
            setsetval('none')  
            document.getElementById('achivListVal').value='none'
         }, 200);
         reset();
     }
    var [selectdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Achivement/'+selectval));
    return (
        <div >
            <div className="success-checkmark doneachive">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div class="col-sm-6 ">
                            <div className="first">
                                <center>
                                   
                                    {editInput
                                        ?  
                                        type === 'none' ? 
                                        <label className="labelHead">Edit Achivement / Award /  Honour</label> :
                                         type === 'Honour' ?  
                                         <label className="labelHead">Edit <s>Achivement / Award / </s>  Honour </label> :
                                         type === 'Award' ?  
                                         <label className="labelHead">Edit <s>Achivement / </s> Award  <s>/  Honour</s> </label>:
                                         <label className="labelHead">Edit Achivement  <s>/ Award /  Honour</s></label>

                                        :type === 'none' ? 
                                        <label className="labelHead">Insert Achivement / Award /  Honour</label> :
                                         type === 'Honour' ?  
                                         <label className="labelHead">Insert <s>Achivement / Award / </s>  Honour </label> :
                                         type === 'Award' ?  
                                         <label className="labelHead">Insert <s>Achivement / </s> Award  <s>/  Honour</s> </label>:
                                         <label className="labelHead">Insert Achivement  <s>/ Award /  Honour</s></label>}
                                </center>
                                <form id="achiveform" onSubmit={subachive}>
                                    <div id="login_div" className="main-div">
                                        <input type="hidden" className="borLessInput" id="id"/>

                                        <span class="label">Select Type</span>
                                        <select id="achiveType" class="borLessInput" onChange={e=>settype(e.target.value)}>
                                            <option value="none">-</option>
                                            <option>Achivement</option>
                                            <option>Award</option>
                                            <option>Honour</option>
                                        </select>
                                        {type === 'none' ? <></> : 
                                        <>
                                        <span class="label">{type === 'none' ? <></> : type} Title </span>
                                        <input type="text" placeholder={type +"Title"} id="achivtitle" class="borLessInput"/>
                                        <span class="label"> Date </span>
                                        <input type="date" placeholder="Title" id="achivdate" class="borLessInput"/>
                                        <span class="label">Organisation</span>
                                        <input type="text" placeholder="Organisation " id="achivorg"
                                            class="borLessInput"/>
                                        <br></br>
                                        {editInput
                                            ? <div id="CollegeLogoUp">

                                                    <div id="collegeLogodiv" className="updateLogo"></div>
                                                    <div id="instLogoUp">

                                                        {dataUri
                                                            ? <img src={dataUri} width="30%" alt="logo"/>
                                                            : <img
                                                                src="/image/logo_here.png"
                                                                width="30%" alt="logo"
                                                                style={{
                                                                marginTop: '30px'
                                                            }}/>}
                                                    </div>
                                                    <div className="editinputLogo">
                                                        <label id="pict">Update Organisation Logo
                                                            <input
                                                                type="file"
                                                                id="CollegeLogoedit"
                                                                onChange={(event) => onChange(event.target.files[0] || null)}
                                                                className="borLessInput"/>
                                                        </label>
                                                    </div>
                                                    <br/>
                                                </div>
                                            : <div id="CollegeLogoIn">
                                                <div id="instLogo"><img src={dataUri} width="30%" alt="logo"/></div>
                                                <div className="editinputLogo">
                                                    <label id="pict">Organisation Logo
                                                        <input
                                                            type="file"
                                                            id="CollegeLogo"
                                                            onChange={(event) => onChange(event.target.files[0] || null)}/>
                                                    </label>
                                                </div>
                                            </div>}
                                        <br/><br/>
                                        <span class="label">Description</span>
                                        <textarea id="achivDesc" placeholder="Description (optional)"
                                            class="borLessInput"></textarea>

                                        {editInput
                                            ? <div id="">
                                                    <div className="col-xs-6">
                                                        <button className="btn btn-default btn-edit btn-submit" onclick="updateedu()">Update</button>
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <button
                                                            className="btn btn-edit btn-default"
                                                            type="reset"
                                                            onClick={e => reset()}>Reset</button>
                                                    </div>
                                                </div>
                                            : <div id="">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="submit">Submit</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button
                                                        className="btn btn-edit btn-default"
                                                        type="reset"
                                                        onClick={e => reset()}>Reset</button>
                                                </div>
                                            </div>}

                                    </>
                                        }
                                        
                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div className="second">
                                <center>
                                    <label class="headi labelHead">Preview
                                    </label>
                                </center>
                                <div id="login_div" class="main-div">

                                <select id="achivListVal" onChange={e=>setsetval(e.target.value)}>
                                    <option value='none'>Select Type</option>
                                    {previewdata && previewdata.map(data => <Previewdata prop={data} />)}
                                   </select>
                                   <div id='pubvalueappend'>
                                   {selectdata && 
                                   selectdata.map(data => 
                                   <TrainCdetail prop={data} deletetc={(e,t)=>deletetrainc(e,t)} edittrainc={(e,t)=>editDatacontent(e,t)}/>)}
                                    </div>
                                    
                                </div>   
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

var error = 0;
function Previewdata(props) {
    var pubTypeItem = []  
  // var [previewdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Achivement/'));
    if(props.prop){
       // console.log(pubTypeItem.indexOf(props.prop))
        if(pubTypeItem.indexOf(props.prop) === -1)
        {
            pubTypeItem.push(props.prop) 
            var dec = props.prop;
            error = 0;
        }
        else error = 1
    }
   
    return (<>
        {error ? <></>: <option value={dec}>{dec}</option>}
        </>
    ) 

}
function TrainCdetail(props)
{

    const handleInputChangeD = useCallback((event,type) => {
        props.deletetc(event,type)
    }, [props.showsection])
    
    const handleInputChangeE  = useCallback((event,type) => {
        props.edittrainc(event,type)
    }, [props.showsection])
   if(props.prop)
     {
         var {id,achivDesc,achivdate,achivorg,achivtitle,Image,Type}= props.prop;
     }
    return (<> <div className="container-fluid">
        <div className="col-xs-3 exImg">
                {Image
                    ? <img src={Image} width="90%" alt="logo"/>
                    : <center><img
                        src="/image/logo_here.png"
                        width="90%" alt="logo"
                        style={{
                        marginTop: '30px'
                    }}/></center>}

            </div>
    <div className="col-xs-7">
    <b><p>{achivtitle}</p></b>
    {achivorg ?<p>{achivorg}</p> :<></>}
    <p>{achivdate} </p>
    {achivDesc
                    ? <div>
                            <button
                                className="btn btn-default bnt1"
                                data-toggle="collapse"
                                data-target={'#' + id}>Description</button>
                            <div id={id} className="collapse">
                                <p>{achivDesc}</p>
                            </div>
                        </div>
                    : <div></div>}
    </div> 
    <div className="col-xs-2">
    
       <button data-tip="Delete" className="btn bthBorderLess btn-default" onClick={(e,t)=>handleInputChangeD(id,Type)} >
           <span className="glyphicon glyphicon-trash" ></span>
       </button> 
       <button
        data-tip="Edit"
           onClick={(e,t) => handleInputChangeE(id,Type)}
           className="btn bthBorderLess btn-default">
                        <span className="glyphicon glyphicon-edit" ></span>
                    </button>
    </div>
    <ReactTooltip /> 
    </div>   <hr/></>)
}
export default Achivement;
