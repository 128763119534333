import parse from 'html-react-parser';
import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid
function Publication(props){
    userid = props.id
    var [Publicationdata] = useListKeys(db.ref('UserAccount/' + userid + '/Publication'));

return(<>
          {Publicationdata && Publicationdata.map(data => <Publicationtype edu={data}/>)}
</>)
}

function Publicationtype(props)
{
    var type = props.edu
    var [skilldata] = useListVals(db.ref('UserAccount/' + userid + '/Publication/'+props.edu));
    return(<>
    {type ? <div class="" ><h4 style={{color:'#379683'}}>{type.replaceAll('+',' ')} :</h4>
    {skilldata && skilldata.map(item=> <PublicationTypeval val={item}/>)}
    </div>: <></>}
    </>

    );
}

function PublicationTypeval(props) {
    var {Description} = props.val;
    return(<><div class=" " style={{padding:'5px'}}><p style={{color:'#ffffff',fontSize:'20px'}}> {parse(Description)}</p></div></>)
}
export default Publication;