import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function Project(props) {
    userid = props.id
    var [Projectdata] = useListVals(db.ref('UserAccount/' + userid + '/Project'));
    Projectdata.sort(function (a, b) {
        return parseFloat(b.projendDate) - parseFloat(a.projendDate) ;
    });

    return ( <> <p className="p">project</p> <ul>{
        Projectdata && Projectdata.map(data => <Projectval edu={data}/>)
    }</ul> </>)
}
var countedu = []
function Projectval(props)
{
  var {
    Associatedwith,
    projectDes,projectSupervisor,
    projectlocation,projectname,
    projendDate,projectrole,projectUrl,
    id,
    projstartDate,toolUsed,projectnShortDescp
} = props.edu;

return (<>
<li style={{paddingLeft:'10px'}}>
<b>{projectname}</b><br/>
{projectnShortDescp? <>{projectnShortDescp }<br/> </>:<></>}
{Associatedwith ? <>Associated with : {Associatedwith} {projectlocation ?<>, {projectlocation}</> :<></>}<br/></>:<></>}
{projstartDate.split('-').reverse().join('/')} - {projendDate.split('-').reverse().join('/')}<br/>
{toolUsed? <><u>Tool Used:</u> {toolUsed}   <br/></> :<></>}
{projectDes ?<> {parse(projectDes)} <br/></>:<></>}
{projectSupervisor ?<> Supervisor : <ul><li> {projectSupervisor.replace(/\n/g, ", ")}</li></ul></>:<></>}
</li><br/>
 </>)
}
export default Project