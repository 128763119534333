import React, {useState, useEffect} from 'react';
import "./dashboard.css"
import "./dashboardres.css"
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'

if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
var percentage;

function Completion() {

    const scanprofile = async e => {
        var count = 0;
        var co = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/AboutData')
        await co.once('value', snapshot => {
            count = count + snapshot.numChildren();
        })
        var co = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Achivement')
            await co.once('value', snapshot => {
            count = count + snapshot.numChildren();
        })
        var co = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Education')
            await co.once('value', snapshot => {
            if (snapshot.numChildren() > 0) {
                count = count + 1;
            }
        })
        var co = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ProfessionalMembership')
            await co.once('value', snapshot => {
            if (snapshot.numChildren() > 0) {
                count = count + 1;
            }
        })
        var co = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/WorkExperience')
            await co.once('value', snapshot => {
            if (snapshot.numChildren() > 0) {
                count = count + 1;
            }
        })
        var co = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Skill')
            await co.once('value', snapshot => {
            if (snapshot.numChildren() > 0) {
                count = count + 1;
            }
        })
        var co = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Project')
            await co.once('value', snapshot => {

            if (snapshot.numChildren() > 0) {
                count = count + 1;
            }
        })
        var co = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Conference')
            await co.once('value', snapshot => {
            count = count + snapshot.numChildren();
        })
        var co = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TrainingCourse')
            await co.once('value', snapshot => {
            count = count + snapshot.numChildren();
        })
        var co = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TeachingExperience')
            await  co.once('value', snapshot => {
            if (snapshot.numChildren() > 0) {
                count = count + 1;
            }
        })
        var co = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Publication')
            await co.once('value', snapshot => {
            if (snapshot.numChildren() > 0) {
                count = count + 1;
            }
        })
        var co = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ResearchArea')
            await co.once('value', snapshot => {
            count = count + snapshot.numChildren();
        })
       
        setTimeout(() => {
            percentage = count
            setscanning(false)
        }, 6000)
    }
    const [scanning, setscanning] = useState(true)
    useEffect(() => {
        scanprofile()
    }, [])

    return (
        <div className="dashbox">
            <h1>Profile Completion (Beta)</h1>
            {scanning
                ? <p id="calculating">Scanning Your Profile....</p>
                : <div className="progress1">

                    <CircularProgressbar
                        value={`${percentage}`}
                        maxValue={30}
                        text={`${ ((percentage * 100) / 30).toFixed(0)}%`}/>

                </div>}

            <p></p>
        </div>
    )
}

export default Completion