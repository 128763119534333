import React, {useState, useRef, useCallback, useEffect} from 'react';
import './signup.css'
import '../commonaccount.css'
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import GoogleLogin from 'react-google-login';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
var md5 = require("md5"); 
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();

function Signup() {
    useEffect(() => {
        loagingscreen();
    }, [])
    const loagingscreen = e => {
        document.getElementById('loadersignup').style.display = "block"
       document.getElementById('mainSignupSection').style.display = "none"
        setTimeout(() => {
            document
                .getElementById('loadersignup')
                .style
                .display = "none"
            document
                .getElementById('mainSignupSection')
                .style
                .display = "block"
        }, 1000);
    }

    const [email,
        setemail] = useState('');
    const [sentemail,
        setsentemail] = useState(false);
    const [password,
        setpassword] = useState('');
    const [code,
        setcode] = useState('')
        const [oyp,
            setoyp] = useState('')
    const test = e => {
        console.log(e.target.value)
    }
    const randomcode = e => {
        setcode(Math.random().toString(36).substr(2, 6));
        setoyp(Math.floor(100000 + Math.random() * 900000));
    }
    useEffect(() => {
        randomcode();
    }, [])
    const signup = e => {
        e.preventDefault()

       
        if(email.trim() === '' && md5(password.trim()) === '' && md5(document.getElementById('userPasswordCreate1').value.trim()) === '')
        {
            toast("Input cannot be blank", {
                style: {
                  backgroundColor:'#F9AAAA',
                  color:'black',
                  fontSize:'20px',
                
                },
              });
              return
        }
      if(md5(document.getElementById('userPasswordCreate1').value) !== md5(password.trim())) {
        loagingscreen();
        setTimeout(() => {
            toast("Password Didn't matched", {
                style: {
                  backgroundColor:'#F9AAAA',
                  color:'black',
                  fontSize:'20px',
                
                },
              });
        }, 500);
       return 
      }
      if(document.getElementById('CaptchaInputcreate').value !== code) {
        loagingscreen();
        setTimeout(() => {
            toast("Code Didn't matched", {
                style: {
                  backgroundColor:'#F9AAAA',
                  color:'black',
                  fontSize:'20px',
                
                },
              });
        }, 500);
       return 
      }
        var modifiedemail = email.replace(/\./g, '(dot)')
        modifiedemail = modifiedemail.replace(/\$/g, '(dollar)')
        modifiedemail = modifiedemail.replace(/\[/g, '(left)')
        modifiedemail = modifiedemail.replace(/\]/g, '(right)')
        modifiedemail = modifiedemail.replace(/\//g, '(slash)')
        var docRef = db.ref("UserAccount/" + modifiedemail + '/id');
        docRef.once("value", snapshot => {
            if (snapshot.val() === null) {
                loagingscreen();
               
                localStorage.setItem('makeyacvUserotp', oyp);
                  emailjs.sendForm('makeyacvemail', 'template_wbjtki1', e.target, 'user_Kq3uHzVSXjitau67AcDx1')
                  .then((result) => {
                        // console.log(result.text);
                     }, (error) => {
                        // console.log(error.text);
                     });
                  setsentemail(true)
            } else {
                loagingscreen();
                randomcode();
                setTimeout(() => {
                    toast("User Already exist", {
                        style: {
                          backgroundColor:'#F9AAAA',
                          color:'black',
                          fontSize:'20px',
                        
                        },
                      });
                }, 500);
            }
        })
    }
    const otpverify = e=>{
     if(document.getElementById('verifyusercode').value.trim() !== localStorage.getItem('makeyacvUserotp'))
        {
            loagingscreen();
            setTimeout(() => {
                toast("Invalid OTP", {
                    style: {
                      backgroundColor:'#F9AAAA',
                      color:'black',
                      fontSize:'20px',
                    
                    },
                  });
            }, 500);
            return
        }
        var modifiedemail = email.replace(/\./g, '(dot)')
        modifiedemail = modifiedemail.replace(/\$/g, '(dollar)')
        modifiedemail = modifiedemail.replace(/\[/g, '(left)')
        modifiedemail = modifiedemail.replace(/\]/g, '(right)')
        modifiedemail = modifiedemail.replace(/\//g, '(slash)')
        var id = db
                    .ref("UserAccount/" + modifiedemail)
                    .push()
                    .key;
                var insert = db
                    .ref()
                    .child('UserAccount/');
                insert
                    .child(modifiedemail)
                    .set({"Password": md5(password.trim()), "id": id, "Status": 'active'});
                var insert = db
                    .ref()
                    .child('UserAccount/' + modifiedemail);
                insert
                    .child('AboutData')
                    .set({"email": email});
                //localStorage.setItem('makeyacvUser', modifiedemail);
                setTimeout(() => {
                    toast("Account Created", {
                        style: {
                          backgroundColor:'#CDFA7D',
                          color:'black',
                          fontSize:'20px',
                        
                        },
                      });
                      localStorage.removeItem('makeyacvUserotp')
                      setoyp('')
                }, 500);
                loagingscreen();
                localStorage.setItem('makeyacvUser', modifiedemail);
                localStorage.setItem('newmakeyacvUser', modifiedemail);
                var profileInsertkey = db.ref().child('UserAccount/' + modifiedemail  + '/Notification/Profile').push().key;
                var messageInsert = db
                            .ref()
                            .child('UserAccount/' + modifiedemail + '/Notification/Profile');
                        messageInsert
                            .child(profileInsertkey)
                            .set({
                                "Message": "Welcome to MakeYaCV",
                                "DateTime": new Date().toLocaleString() + '',
                                "id" :profileInsertkey
                            })
                        var log = db.ref().child('UserAccount/' +modifiedemail + '/ActivityLog')
                     log.push().update({
                         "DateTime" : new Date().toLocaleString() + '',
                         "Message": 'Account created'
                     })
                setTimeout(() => {
                    window.location.href = "/Dashboard"
                }, 800)
           
    }
    const responseGoogle = (response) => {
        try{
     var modifiedemail = response.profileObj.email
             modifiedemail = modifiedemail.replace(/\./g, '(dot)')
             modifiedemail = modifiedemail.replace(/\$/g, '(dollar)')
             modifiedemail = modifiedemail.replace(/\[/g, '(left)')
             modifiedemail = modifiedemail.replace(/\]/g, '(right)')
             modifiedemail = modifiedemail.replace(/\//g, '(slash)')
             var docRef = db.ref("UserAccount/" + modifiedemail + '/id');
        docRef.once("value", snapshot => {
            if (snapshot.val() === null) {
                loagingscreen();
                var id = db
                    .ref("UserAccount/" + modifiedemail)
                    .push()
                    .key;
                var insert = db
                    .ref()
                    .child('UserAccount/');
                insert
                    .child(modifiedemail)
                    .set({"id": id, "Status": 'active'});
                var insert = db
                    .ref()
                    .child('UserAccount/' + modifiedemail);
                insert
                    .child('AboutData')
                    .set({"email": response.profileObj.email,"name" :response.profileObj.name });
                setTimeout(() => {
                    toast("Account Created", {
                        style: {
                          backgroundColor:'#CDFA7D',
                          color:'black',
                          fontSize:'20px',  
                        },
                      });
                }, 500);
                loagingscreen();
                localStorage.setItem('makeyacvUser', modifiedemail);
                localStorage.setItem('newmakeyacvUser', modifiedemail);
                var profileInsertkey = db.ref().child('UserAccount/' + modifiedemail  + '/Notification/Profile').push().key;
                var messageInsert = db
                            .ref()
                            .child('UserAccount/' + modifiedemail + '/Notification/Profile');
                        messageInsert
                            .child(profileInsertkey)
                            .set({
                                "Message": "Welcome to MakeYaCV",
                                "DateTime": new Date().toLocaleString() + '',
                                "id" :profileInsertkey
                            })
                        var log = db.ref().child('UserAccount/' +modifiedemail + '/ActivityLog')
                     log.push().update({
                         "DateTime" : new Date().toLocaleString() + '',
                         "Message": 'Account created'
                     })
                setTimeout(() => {
                    window.location.href = "/Dashboard"
                }, 800)
            } else {
                loagingscreen();
                randomcode();
                setTimeout(() => {
                    toast("User Already exist", {
                        style: {
                          backgroundColor:'#F9AAAA',
                          color:'black',
                          fontSize:'20px',
                        
                        },
                      });
                }, 500);
            }
        })
        }
        catch{}
    }
    return ( <> <div className="box">
        <nav className="navbar navbar-default">
            <div className="container-fluid">
                <div className="navbar-header">
                    <a className="navbar-brand navbar-brandsignup" href="/"><img src="/image/MakeYaCV_Logo_01.svg"/></a>
                </div>
            </div>
        </nav>
        <div className="container">
            <div className="container-box">
                <img src="/image/undraw_thought_process_re_om58.svg" width="50%"/>
            </div>
            <div className="container-box-login">
                <div id="loadersignup">
                    <img src="./image/Pulse-0.9s-200px.svg"/>
                </div>
                <div id="mainSignupSection">
                    {!sentemail
                        ? <> <div className="signup_form" id="signup_section">
                            <p className="headText">Signup</p>
                        <form onSubmit={signup}>
                            <div className="form__group field">
                                <input
                                    type="text"
                                    className="form__field"
                                    id='userEmailCreate'
                                    autoComplete="off"
                                    placeholder="Email"
                                    name="to_name"
                                    value={email}
                                    onChange={e => setemail(e.target.value)}/>
                                <label for="name" className="form__label">Email</label>
                            </div>
                            <div className="form__group field">
                                <input
                                    type="password"
                                    className="form__field"
                                    placeholder="Password"
                                    id='userPasswordCreate'
                                    value={password}
                                    onChange={e => setpassword(e.target.value)}
                                    autoComplete="off"/>
                                <label for="name" className="form__label">Password</label>
                            </div>
                            <div className="form__group field" id="email">
                                <input
                                    type="password"
                                    className="form__field"
                                    placeholder="Confirm"
                                    id='userPasswordCreate1'
                                    autoComplete="off"/>
                                <label for="name" className="form__label">Confirm Password</label>
                            </div>
                           
                                <input
                                    type="hidden"
                                    name="message"
                                    value={oyp}
                                    />
                               
                     
                            <div className="form__group field">
                                <input
                                    className="form-control"
                                    type="hidden"
                                    id="txtCaptchacreate"
                                    autoComplete="off"/>
                                <input
                                    type="input"
                                    className="form__field"
                                    placeholder="Security Code"
                                    name="code"
                                    id='CaptchaInputcreate'
                                    autoComplete="off"/>
                                <label for="code" className="form__label">Enter Code</label>
                                <label className="form__label__error" id="codeErrorsignup">Invalid Captcha</label>
                            </div>
                            <div className=" CBox">
                                <div id="CaptchaDivBox" className="marquee">
                                    <p id="CaptchaDivcreate">{code}</p>
                                </div>
                                <span className="glyphicon glyphicon-refresh" onClick={e => randomcode()}></span>
                            </div>
                            <div className="container-fluid">
                                <br></br>
                                <div className="col-md-12">
                                    <button className=" btn-account " type="submit">Create Account</button>
                                </div>

                            </div>
                            </form>
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <br></br>
                                    <p className="text-center">Or
                                    </p>
                                    <div id="gSignInWrapper">

                                        <div id="customBtn" >
                                        <GoogleLogin
                    clientId="397559100424-23dqgqaigoig1vr6un23ape9c5lsbg3m.apps.googleusercontent.com"
                    buttonText="Signup with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}/>
                                        </div>
                                    </div>

                                </div>
                                <br></br>
                                <div className="col-md-12">
                                    <p className="signin">
                                        <a href="/Login">
                                            Already have account.</a>
                                    </p>
                                </div>
                            </div>
                        </div> </>:<>
                    <div className="signup_form" >
                        <p className="headText headText1">Check Your Email Id for OTP</p >
                         <div className="form__group field">

                            <input
                                type="input"
                                className="form__field"
                                placeholder="Password"
                                name="verifyusercode"
                                id='verifyusercode'/>
                            <label for="verifyusercode" className="form__label">Security Code</label>
                        </div> < div className = "container-fluid" > <div className="row">

                            <br></br><br/>
                            <div className="col-md-12">
                                <button className=" btn-account " onClick={e=>otpverify()}>Submit</button>
                            
                                <br></br><br/>
                            </div>
                             <p className="text-center">*Note: Check your spam folder</p>
                        </div> </div>
                    </div > </>}

                  
                </div>
            </div>
            <ToastContainer/>
        </div>
    </div> </>);
}

export default Signup;