import React, {useState, useRef, useCallback, useEffect} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../commonaccount.css'
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import {useObjectVal, useListVals,useListKeys} from 'react-firebase-hooks/database';
import GoogleLogin from 'react-google-login';
var md5 = require("md5"); 

if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
function Login() {
    useEffect(()=>{
  loagingscreen();
    },[])
   
const loagingscreen = e=>{
    document.getElementById('loadersignup').style.display = "block"
    document.getElementById('mainSignupSection').style.display = "none"
     setTimeout(() => {
    document.getElementById('loadersignup').style.display = "none"
    document.getElementById('mainSignupSection').style.display = "block"
      }, 2000);
}
var [aboutme] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/AboutData'));
    const loginuser = e => {
        
        var email = document.getElementById('userEmailCreate').value;
        var modifiedemail = email.replace(/\./g, '(dot)')
        modifiedemail = modifiedemail.replace(/\$/g, '(dollar)')
        modifiedemail = modifiedemail.replace(/\[/g, '(left)')
        modifiedemail = modifiedemail.replace(/\]/g, '(right)')
        modifiedemail = modifiedemail.replace(/\//g, '(slash)')
        var docRef = db.ref("UserAccount/" + modifiedemail + '/Password');
        docRef.once("value", snapshot => {
            if (snapshot.val() === null) {
                loagingscreen();
                toast("Invalid Details", {
                    style: {
                      backgroundColor:'#F9AAAA',
                      color:'black',
                      fontSize:'20px',
                    },
                  });
            } else {
                if(snapshot.val() !== md5(document.getElementById('userPasswordCreate').value))
                {
                    loagingscreen();
                    toast("Password Incorrect", {
                        style: {
                          backgroundColor:'#F9AAAA',
                          color:'black',
                          fontSize:'20px',
                        
                        },
                      });
                      return
                }
                localStorage.setItem('makeyacvUser', modifiedemail);
                
                loagingscreen();
                setTimeout(() => {
                  window.location.href = "/Dashboard"
                }, 1000)

            }
        })
    }
    const logoutuser = e => {
        localStorage.removeItem('makeyacvUser')
        window.location.href = "/Login"
    }
    const responseGoogle = (response) => {
        try{ var email = response.profileObj.email;
        var modifiedemail = email.replace(/\./g, '(dot)')
        modifiedemail = modifiedemail.replace(/\$/g, '(dollar)')
        modifiedemail = modifiedemail.replace(/\[/g, '(left)')
        modifiedemail = modifiedemail.replace(/\]/g, '(right)')
        modifiedemail = modifiedemail.replace(/\//g, '(slash)')
        var docRef = db.ref("UserAccount/" + modifiedemail + '/id');
        docRef.once("value", snapshot => {
            if (snapshot.val() === null) {
                loagingscreen();
                toast("User Doesn't Exist", {
                    style: {
                      backgroundColor:'#F9AAAA',
                      color:'black',
                      fontSize:'20px',
                    
                    },
                  });
            } else {
                var docRefp = db.ref("UserAccount/" + modifiedemail + '/Password');
                docRefp.once("value", snapshot => {
                    if (snapshot.val() === null) {
                        localStorage.setItem('makeyacvUser', modifiedemail);
                        loagingscreen();
                        setTimeout(() => {
                          window.location.href = "/Dashboard"
                        }, 1000)
                    }
                    else{
                        loagingscreen();
                        toast("This account cannot be login using third party", {
                        style: {
                          backgroundColor:'#F9AAAA',
                         color:'black',
                          fontSize:'20px',
                    
                         },
                  });
                    }
                })
                
            }
        })
    }
        catch{}
    }
   
    return (
        <div className="box">
            <nav className="navbar navbar-default">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/"><img src="/image/MakeYaCV_Logo_01.svg"/></a>
                    </div>

                </div>
            </nav>
            <div className="container">
                <div className="container-box">
                    <img src="/image/undraw_thought_process_re_om58.svg" width="50%"/>
                </div>
                <div className="container-box-login">
                    <div id="loadersignup">
                        <img src="./image/Pulse-0.9s-200px.svg"/>
                    </div>
                    <div id="mainSignupSection">
                        {localStorage.getItem('makeyacvUser')  ? <>
    <div className="welcomeback">
       {aboutme ? aboutme.image ? <img src={aboutme.image} alt={aboutme.name} className="img-responsive img-circle"/> :<img src="/image/unnamed.png"/> :<></>}
                <h3>Welcome Back <br/> {aboutme ? aboutme.name :<></>}</h3>
                
   
    <a href="/Dashboard"><button className="btn btn-default" >Dashboard</button></a>
   <button className="btn btn-default" onClick={e=>logoutuser()}>Logout</button>
    </div>
   
    </>: <><div className="signup_form" id="signup_section">
                            <p className="headText">Login</p>

                            <div className="form__group field">
                                <input
                                    type="text"
                                    className="form__field"
                                    id='userEmailCreate'
                                    placeholder="Email"
                                 
                                    autoComplete="off"
                                   />
                                <label for="name" className="form__label">Email</label>
                            </div>
                            <div className="form__group field">
                                <input
                                    type="password"
                                    className="form__field"
                                    id='userPasswordCreate'
                                    placeholder="Password"
                                    autoComplete="off"/>
                                <label for="name" className="form__label">Password</label>
                            </div>

                            <div className="container-fluid">
                                <br></br>
                                <div className="col-md-12">
                                    <button className=" btn-account " onClick={e => loginuser()}>Login</button>
                                </div>

                            </div>
                            <div className="container-fluid">
                            <div className="col-md-12">
                                    <p className="signin">
                                        <a href="/Forget">Forget Password?</a>
                                    </p>
                                </div>
                                <div className="col-md-12">
                                    <br></br>
                                    <p className="text-center">Or
                                    </p>
                                    <div id="">

                                    <div id="customBtn" >
                                        <GoogleLogin
                    clientId="397559100424-23dqgqaigoig1vr6un23ape9c5lsbg3m.apps.googleusercontent.com"                  
                    buttonText="Signin with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}                
                    cookiePolicy={'single_host_origin'}/>
                                        </div>
                                    </div>

                                </div>
                                <br></br><br/>
                                <div className="col-md-12">
                                    <p className="signin">
                                        <a href="/Signup">Don't have account? SignUp</a>
                                    </p>
                                </div>
                            </div>
                        </div> </>}
                         
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
}

export default Login;
