import React, {useEffect, useState} from 'react';
import './Templet1.css'
import Education from './Education/Education'
import WorkExperience from './Experience/workexperience'
import TeachingExperience from './Experience/teachingexperience'
import Publication from './Publication/Publication'
import Membership from './Membership/membership'
import Project from './Project/project'
import Skill from './Skill/skill'
import ResearchArea from './ResearchArea/researcharea'
import Award from './Achive/award'
import Training from './TrainingCourse/training'
import Conference from './Conference/conference'
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../config'
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
import SocialLinks from './links/social'
import Modal from 'react-modal';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
var userid,
    explength,
    teachlength,
    code;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
function Templet1(props) {
    var menulist = [];
    var menuname = [];
    var menu = [];
    const [modalIsOpen,
        setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    const [selectsection,
        setselectsection] = useState(0);
    useEffect(() => {
        // document.querySelector('.templet1bg').style.backgroundImage =
        // "url(/image/pexels-scott-webb-1022928.jpg)";
    }, [])
    const secretcode = e => {
        code = Math.floor(Math.random() * 9999) + 10000;
    }
    useEffect(() => {
        secretcode();
    }, [])

    userid = props.id;

    var [aboutdata] = useObjectVal(db.ref('UserAccount/' + userid + '/AboutData'));

    var menulist = [];
    var menulistres = [];
    var menuname = [];
    var active = [];
    var activeres = [];
    var activity = []
    var menuname = []
    var list =[]
    const [openNav,
        setopenNav] = useState(false);
    const [section,
        setsection] = useState(0)
    var [previewdata,
        loading] = useListVals(db.ref('UserAccount/' + userid + '/MenuList/'));
    const acti = e => {
        if (menuname.indexOf('Activity') == -1) {
            active.push(
                <li className="dropdown1">
                    <a className="dropbtn " id="dropexp">Activity
                        <span className="caret"></span>
                    </a>
                    <div className="dropdown-content" id="">
                        {activity}
                    </div>
                </li>
            )
            activeres.push(
                <li>
                    <a data-toggle="collapse" data-target="#Activity12">Activity
                        <span className="caret"></span>
                    </a>
                    <div
                        id="Activity12"
                        className="collapse"
                        style={{
                        paddingLeft: '30px'
                    }}>
                        {activity}
                    </div>
                </li>
            )
            menuname.push('Activity')
        }
    }
    if (!loading) {
        previewdata.map(item => {
            var m = item.Name
            menuname.push(m)
            if (m == 'Education') {
                menulist.push(
                    <li>
                        <a onClick={e => setselectsection(0)} className="a" href="#Education">Education</a>
                    </li>
                )
                menulistres.push( <li className="">
                <a  onClick={e => {setselectsection(0);setopenNav(false)}} href="#Education">Education</a>
            </li>)

            } else if (m === 'Work Experience' || m === 'Teaching Experience') {
                if (menuname.indexOf('Experience') == -1) {
                    menulist.push(
                        <li className="">
                            <a href="#Experience" onClick={e => setselectsection(0)}>Experience</a>
                        </li>
                    )
                    menulistres.push( <li className="">
                    <a href="#Experience" onClick={e => {setselectsection(0);setopenNav(false)}}>Experience</a>
                </li>)
                    menuname.push('Experience')
                }

            } else if (m == 'Research Area' || m === 'Skill') {
                if (menuname.indexOf('Expertise') == -1) {
                    menulist.push(
                        <li >
                            <a href="#Expertise" onClick={e => {setselectsection(0);setopenNav(false)}}>
                                Expertise</a>
                        </li>
                    )
                    menulistres.push( <li className="">
                    <a href="#Expertise" onClick={e => {setselectsection(0);setopenNav(false)}}>Expertise</a>
                </li>)
                    menuname.push('Expertise')
                }

            } else if (m == 'Project') {
                menulist.push(
                    <li>
                        <a href="#project"
                            onClick={e => {
                            setselectsection(1);setopenNav(false)}}
                            className="a">Project</a>
                    </li>
                )
                menulistres.push( <li className="">
                    <a href="#project" onClick={e => {setselectsection(1);setopenNav(false)}}>Project</a>
                </li>)

            } else if (m == 'Publication') {
                menulist.push(
                    <li>
                        <a href="#publication"
                            onClick={e => {
                            setselectsection(2);
                            setopenNav(false)
                        }}
                            className="a">Publication</a>
                    </li>
                )
                menulistres.push(
                    <li>
                        <a href="#publication"
                            onClick={e => {
                                setselectsection(2);
                            setopenNav(false)
                        }}
                            className="a">Publication</a>
                    </li>
                )

            } else if (m == 'Professional Membership') {
                acti()
                activity.push(
                    <a href="#Membership"
                    onClick={e => {
                        setselectsection(3);
                        setopenNav(false)
                    }}
                        className="a">Membership</a>
                )

            } else if (m == 'Achivement / Award / Honour') {
                acti()
                activity.push(
                    <a href="#achivement"
                    onClick={e => {
                        setselectsection(4);
                        setopenNav(false)
                    }}
                        className="a">Achivement / Award / Honour</a>
                )

            } else if (m == 'Training / Course') {
                acti()
                activity.push(
                    <a href="#train"
                    onClick={e => {
                        setselectsection(5);
                        setopenNav(false)
                    }}
                        className="a">Training / Course</a>
                )

            } else if (m == 'Conference / Symposium / Workshop') {
                acti()
                activity.push(
                    <a href="#Conference"
                    onClick={e => {
                        setselectsection(6);
                        setopenNav(false)
                    }}
                        className="a">Conference / Symposium / Workshop</a>
                )

            }
        })
    }
    const sendMessage = e => {
        e.preventDefault();
        if (document.getElementById('outsidername3').value.length === 0 || document.getElementById('outsideremail3').value.length === 0 || document.getElementById('outsidersubject3').value.length === 0 || document.getElementById('outsidermessage3').value.length === 0) {
            alert("Input field cannot be empty!.... error");
            return;
        }
        setIsOpen(true);
    }
    const verifyCode3 = e => {
        if ((document.getElementById('CaptchaInput3').value).trim() === code.toString()) {
            var messageInsertkey = db
                .ref()
                .child('UserAccount/' + userid + '/Message')
                .push()
                .key;
            var messageInsert = db
                .ref()
                .child('UserAccount/' + userid + '/Message');
            messageInsert
                .child(messageInsertkey)
                .set({
                    "Name": document
                        .getElementById('outsidername3')
                        .value,
                    "Email": document
                        .getElementById('outsideremail3')
                        .value,
                    "Subject": document
                        .getElementById('outsidersubject3')
                        .value,
                    "Message": document
                        .getElementById('outsidermessage3')
                        .value,
                    "DateTime": new Date().toLocaleString() + '',
                    "bgcolor": 'transparent',
                    "id": messageInsertkey
                })
            var messageInsertNotificationkey = db
                .ref()
                .child('UserAccount/' + userid + '/Notification')
                .push()
                .key;
            var messageInsertNotification = db
                .ref()
                .child('UserAccount/' + userid + '/Notification');
            messageInsertNotification
                .child('Message')
                .child(messageInsertNotificationkey)
                .update({
                    "Name": document
                        .getElementById('outsidername3')
                        .value,
                    "DateTime": new Date().toLocaleString() + '',
                    "bgcolor": 'transparent',
                    "id": messageInsertNotificationkey
                })

            var log = db
                .ref()
                .child('UserAccount/' + userid + '/ActivityLog')
            log
                .push()
                .update({
                    "DateTime": new Date().toLocaleString() + '',
                    "Message": 'New Message'
                })
            window.alert("Message Send")
            setTimeout(() => {
                document
                    .getElementById("outsideform")
                    .reset()
                secretcode();
                setIsOpen(false);

            }, 200)
        } else {
            window.alert("Incorrect code")
        }
    }
    return ( <> <div className="webtemplet1">

        <nav class="navbar navbar-inverse navbar-fixed-top navbartemp1">
            <div class="container-fluid">
                <div class="navbar-header">
                    <a class="navbar-brand navbar-brandTemp1" href="#">
                        {aboutdata
                            ? <> {aboutdata.name ? aboutdata.name.split(' ')[0] :<></>
                            } </>:<>MakeYaCV</ >}</a>
                </div>

                <ul class="nav navbar-nav navbar-right navbar-righttemp1">
                    <li>
                        <a className="a" href="#" onClick={e => setselectsection(0)}>Home</a>
                    </li>
                    {menulist}
                    {menuname.indexOf('Activity') > 0 ? active :<></>}
                    <li>
                        <a className="a" href="#contactme" onClick={e => setselectsection(0)}>Contact</a>
                    </li>
                </ul>
            </div>
        </nav>
        <div id = "navbaresponsive" > 
    <mav className="navbarres">
        <a onClick={e =>{setselectsection(0);setopenNav(false)}} className="navbar-brand  navbar-brandt3 navbar-brandTemp1">  {aboutdata
                            ? <>  {aboutdata.name ? aboutdata.name.split(' ')[0] :<></>
                        }</>:<>MakeYaCV</ >}</a>
        <a onClick={e=>setopenNav(true)} id="menures">
            <span className="glyphicon glyphicon-align-justify"></span>
        </a>
    </mav> 
    < div id = "mySidenav" className = "sidenavt1" style={{width : !openNav ? 0 : '100%'}}>
         <a href="javascript:void(0)" className="closebtn" onClick={e=>setopenNav(false)}>&times;</a> 
    < span id = "navPreviewResponsive" >
         <li>
             <a onClick={e =>{setselectsection(0);setopenNav(false)}}>Home</a>
         </li> 
         {menulistres}
         {menuname.indexOf('Activity') > 0 ? activeres :<></>}
         <li>
                        <a className="a" href="#contactme" onClick={e =>{setselectsection(0);setopenNav(false)}}>Contact</a>
          </li>
    </span>
       </div > 
       </div> 
        <div
            class="animate__animated animate__fadeIn"
            style={{
            display: selectsection === 0
                ? 'block'
                : 'none'
        }}>
            <div className="webtemplet1_content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div id="webtemplet1_content_img">
                                {aboutdata
                                    ? aboutdata.image
                                        ? <img src={aboutdata.image} className="img-responsize img-circle"/>
                                        : <img src="/image/unnamed.png" className="img-responsize img-circle"/>
                                    : <></>}
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="selt_webtemplet1">
                                <p>Hello I'm</p>
                                <p>
                                    {aboutdata
                                        ? aboutdata.name
                                        : <></>}</p>
                                <p>{aboutdata
                                        ? aboutdata.description
                                        : <></>}</p>
                                 <nav id="sociallinks">
                                 <SocialLinks id={userid} />
                                </nav>       
                             
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custom-shape-divider-bottom-1638111173">
                    <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1200 120"
                        preserveAspectRatio="none">
                        <path
                            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                            opacity=".25"
                            class="shape-fill"></path>
                        <path
                            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                            opacity=".5"
                            class="shape-fill"></path>
                        <path
                            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                            class="shape-fill"></path>
                    </svg>
                </div>
            </div>
            <div className="webtemplet1_about_content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">

                            <img src="/image/undraw_feeling_proud_qne1.svg"/>

                        </div>
                        <div className="col-md-6">
                            <div className="selt_about_webtemplet1">
                                <div className="">
                                    <h2>About Me</h2>
                                    <p>{aboutdata
                                            ? aboutdata.aboutmedetail
                                            : <></>}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="webtemplet1_wave_content">
                <div class="custom-shape-divider-top-1638112199">
                    <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1200 120"
                        preserveAspectRatio="none">
                        <path
                            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                            opacity=".25"
                            class="shape-fill"></path>
                        <path
                            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                            opacity=".5"
                            class="shape-fill"></path>
                        <path
                            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                            class="shape-fill"></path>
                    </svg>
                </div>
            </div>
            <div
                className="webtemplet1_expertise_content webtemplet1__content"
                id="Expertise" style={{display : (menuname.indexOf('Research Area') >= 0 || menuname.indexOf('Skill') >= 0) ? 'block' : 'none'}}>
                <div className="container">
                    <div className="">
                        <div className="col-md-12 section_headert1">
                            <h2>Expertize</h2>
                        </div>
                        <div id="researcharea" style={{display : menuname.indexOf('Research Area') >= 0 ? 'block' : 'none'}}>
                            <ResearchArea id={userid}/>
                        </div>
                      
                        <div style={{display : menuname.indexOf('Skill') >= 0 ? 'block' : 'none'}}>
                            <Skill id={userid}/>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="webtemplet1_education_content webtemplet1__content"
                id="Education" style={{display : menuname.indexOf('Education') >= 0 ? 'block' : 'none'}}>
                <div className="container">
                    <div class="row">
                        <div className="col-md-12 section_headert1">
                            <h2>Education</h2>

                        </div>
                        <Education id={userid}/>
                    </div>
                </div>
            </div>
            <div className="webtemplet1__content" id="Experience" style={{display : menuname.indexOf('Work Experience') >= 0 ? 'block' : 'none'}}>
                <div className="container">
                    <div class="row">
                        <div className="col-md-12 section_headert1">
                            <h2>Work Experience</h2>
                        </div>
                        <WorkExperience id={userid}/>
                    </div>
                </div>
            </div>

            <div className="webtemplet1__content" id="Experience" style={{display : menuname.indexOf('Teaching Experience') >= 0 ? 'block' : 'none'}}>
                <div className="container">
                    <div class="row">
                        <div className="col-md-12 section_headert1">
                            <h2>Teaching Experience</h2>
                        </div>
                        <TeachingExperience id={userid}/>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="animate__animated animate__fadeInDown"
            style={{
            display: selectsection === 1
                ? 'block'
                : 'none'
        }}>
            <div className="webtemplet1__content" id="project" style={{display : menuname.indexOf('Project') >= 0 ? 'block' : 'none'}}>
                <div className="container">
                    <div class="row">
                        <div className="col-md-12 section_headert1">
                            <h2>Project</h2>
                        </div>
                        <div id="project_section1">
                            <ul className="timelinet1">
                                <Project id={userid}/>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="animate__animated animate__fadeInDown"
            style={{
            display: selectsection === 2
                ? 'block'
                : 'none'
        }}>
            <div className="webtemplet1__content" id="publication" style={{display : menuname.indexOf('Publication') >= 0 ? 'block' : 'none'}}>
                <div className="container">
                    <div class="row">
                        <div className="col-md-12 section_headert1">
                            <h2>Publication</h2>

                        </div>

                        <Publication id={userid}/>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="animate__animated animate__fadeInDown"
            style={{
            display: selectsection === 3
                ? 'block'
                : 'none'
        }}>
            <div className="webtemplet1__content" id="Membership" style={{display : menuname.indexOf('Professional Membership') >= 0 ? 'block' : 'none'}}>
                <div className="container">
                    <div class="row">
                        <div className="col-md-12 section_headert1">
                            <h2>Professional Membership</h2>

                        </div>

                        <Membership id={userid}/>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="animate__animated animate__fadeInDown"
            style={{
            display: selectsection === 4
                ? 'block'
                : 'none'
        }}>
            <div className="webtemplet1__content" id="achivement" style={{display : menuname.indexOf('Achivement / Award / Honour') >= 0 ? 'block' : 'none'}}>
                <div className="container">
                    <div class="row">
                        
                        <Award id={userid}/>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="animate__animated animate__fadeInDown"
            style={{
            display: selectsection === 5
                ? 'block'
                : 'none'
        }}>
            <div className="webtemplet1__content" id="train" style={{display : menuname.indexOf('Training / Course') >= 0 ? 'block' : 'none'}}>
                <div className="container">
                    <div class="row">
                      
                        <Training id={userid}/>
                    </div>
                </div>
            </div>
        </div> 
        <div
            class="animate__animated animate__fadeInDown"
            style={{
            display: selectsection === 6
                ? 'block'
                : 'none'
        }}>
            <div className="webtemplet1__content" id="Conference" style={{display : menuname.indexOf('Conference / Symposium / Workshop') >= 0 ? 'block' : 'none'}}>
                <div className="container">
                    <div class="row">
                      
                        <Conference id={userid}/>
                    </div>
                </div>
            </div>
        </div>

        <div className="gap"></div>
        <div className=" webtemplet1__content_contact">
            <div class="custom-shape-divider-top-1638198874">
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none">
                    <path
                        d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                        opacity=".25"
                        class="shape-fill"></path>
                    <path
                        d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                        opacity=".5"
                        class="shape-fill"></path>
                    <path
                        d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                        class="shape-fill"></path>
                </svg>
            </div>
            <div className="contact_section" id="contactme">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Contact Me</h2>
                        </div>
                        <div className="col-md-6">
                            <img src="/image/undraw_contact_us_-15-o2.svg"></img>
                        </div>
                        <div className="col-md-6">
                            <div className="login_form" id="login_section">
                                <form id="outsideform">
                                    <div className="container-fluid">
                                        <div className="col-sm-6">
                                            <div className="form__group field" id="email">
                                                <input
                                                    type="input"
                                                    className="form__field"
                                                    placeholder="Name"
                                                    name="userEmail"
                                                    id='outsidername3'
                                                    autocomplete="off"
                                                    required/>
                                                <label for="userEmail" className="form__label">Name</label>

                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form__group field" id="email">
                                                <input
                                                    type="input"
                                                    className="form__field"
                                                    placeholder="Name"
                                                    name="userEmail"
                                                    id='outsideremail3'
                                                    autocomplete="off"
                                                    required/>
                                                <label for="userEmail" className="form__label">Email</label>

                                            </div>
                                        </div>
                                        <div className="col-sm-12">

                                            <div className="form__group field" id="email">
                                                <input
                                                    type="input"
                                                    className="form__field"
                                                    placeholder="Name"
                                                    name="userEmail"
                                                    id='outsidersubject3'
                                                    autocomplete="off"
                                                    required/>
                                                <label for="userEmail" className="form__label">Subject</label>

                                            </div>

                                        </div>
                                        <div className="col-sm-12">

                                            <div className="form__group field" id="email">
                                                <textarea
                                                    type="input"
                                                    className="form__field"
                                                    placeholder="Name"
                                                    name="userEmail"
                                                    id='outsidermessage3'
                                                    autocomplete="off"
                                                    required></textarea>
                                                <label for="userEmail" className="form__label">Description</label>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="container-fluid">
                                        <div className="row">

                                            <br/><br/>
                                            <div className="col-md-12 text-center">
                                                <button className=" btn-descp " type="submit" onClick={e => sendMessage(e)}>Send</button>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">
                    <div className="container-fluid">
                        <div className="col-md-12 text-center">
                            <label >
                                ENTER THE SECURITY CODE
                            </label>
                            <br/><br/>
                        </div>
                        <div className="col-sm-6">
                            <label>
                                <div
                                    id="CaptchaDiv3"
                                    style={{
                                    textDecoration: 'line-through'
                                }}>{code}</div>
                            </label>
                        </div>
                        <div className="col-sm-6">
                            <div className="capbox-inner">

                                <input className="form-control" type="hidden" id="txtCaptcha3" value={code}/>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="CaptchaInput"
                                    placeholder="Enter the code"
                                    id="CaptchaInput3"
                                    size="15"
                                    autocomplete="off"/><br/>

                            </div>
                        </div>
                        <div className="col-md-12">
                            <button className="btn btn-verify btn-default" onClick={e => verifyCode3()}>Verify and Send
                            </button>
                        </div>
                    </div>

                </Modal>
            </div>
        </div>
        <div className="footertemp1 text-center">
            <h5> {aboutdata
                            ? <> {
                                aboutdata
                                    .name
                                    
                            } </>:<></ >}</h5>
            <p>Powered By MakeYaCV  </p>
           
        </div>
    </div> </>
    );
}

export default Templet1;