import React, {useEffect, useState} from 'react';
import "../common.css"
import "./website.css"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
import Modal from 'react-modal';
var createHash = require('hash-generator');
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
var urlval;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
var websitecode;
function Website({changewebsite}) {
    useEffect(()=>{
        try{}
        catch{}
        var str = "makeyacv.priyonujdey.in"
        var seed = 0
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
        h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
         websitecode = 4294967296 * (2097151 & h2) + (h1>>>0)
    },[])
    const [editname,
        seteditname] = useState(false);
    const [errormsg,
        seterormsg] = useState(false)
    var exist = 0;
    var [previewdata,
        loading] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/website'));
    var [websitecodedata] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Code/websitecode'));

    if (!loading) {
        if (previewdata) {
            exist = 1;
            urlval = '/' + previewdata.url
        }
    }
    var validate = e => {
        var val = db.ref('Website/' + e.replace(/ /g,'').trim())
        val.once("value", elem => {
         
            if (elem.child('id').val() === null) {
                seterormsg(false)
            } else {
                seterormsg(true)
            }

        })
    }
    const generate = (e, t) => {
        var val = db.ref('Website/' + document.getElementById(t).value.replace(/ /g,'').trim())
        val.once("value", elem => {
          
            if (elem.child('id').val() === null) {
                seterormsg(false)
                if (e) 
                    var valremove = db.ref('Website/' + previewdata.url).remove()
                setTimeout(() => {
                    if (document.getElementById(t).value.length != 0) {
                        var val = db
                            .ref('Website')
                            .child(document.getElementById(t).value.replace(/ /g,'').trim())
                            .update({
                                id: localStorage.getItem('makeyacvUser')
                            })
                        var val = db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/website').update({
                            url: document
                                .getElementById(t)
                                .value.replace(/ /g,'').trim()
                        })
                        toast('Name Changed', {
                            style: {
                                backgroundColor: '#B7F9AA',
                                color: 'black',
                                fontSize: '20px'
                            }
                        });
                        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
                        log.push().update({
                            "DateTime" : new Date().toLocaleString() + '',
                            "Message": 'Domain Name Modified'
                        })
                        resetname()
                    } else {
                        toast('Enter name', {
                            style: {
                                backgroundColor: '#FB8F8F',
                                color: 'black',
                                fontSize: '20px'
                            }
                        });
                    }

                }, 200)

            } else {
                seterormsg(true)
            }

        })
    }

    const resetname = r => {
        seterormsg(false)
        seteditname(false)
    }
    const clipboard = e => {
        var copyText = e + previewdata.url;
        try{ navigator.clipboard.writeText(copyText);

            toast('URL Copied', {
                style: {
                    backgroundColor: '#B7F9AA',
                    color: 'black',
                    fontSize: '20px'
                },
                autoClose:1000
            });}
        catch(e){toast("Error", {
            style: {
                backgroundColor: '#FA967D',
                color: 'black',
                fontSize: '20px'
            },
            autoClose:1000
        });}
       
    }

    const submitcode = (e) =>{
        document.getElementById('submitbtn').innerHTML = "Verifying....";
        setTimeout(() => {
            if(document.getElementById('websiteusercode').value.trim() != websitecode)
            {
                toast('Invalid Website Preview Code', {
                    style: {
                        backgroundColor: '#FB8F8F',
                        color: 'black',
                        fontSize: '20px'
                    },
                    autoClose:1000
                });
                document.getElementById('submitbtn').innerHTML = "Submit";
            }
            else{
                setTimeout(() => {
                    toast('Website Unlocked', {
                        style: {
                            backgroundColor: '#B7F9AA',
                            color: 'black',
                            fontSize: '20px'
                        },
                        autoClose:1000
                        
                    });
                    var val = db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Code').update({
                        "websitecode" : 'done'
                     })  
                     var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
                     log.push().update({
                         "DateTime" : new Date().toLocaleString() + '',
                         "Message": 'Website Unlocked'
                     })
                }, 500);
               
                document.getElementById('submitbtn').innerHTML = "Enjoy";
            }
        }, 800);
       
       
    }
    const resetwebsitecode = e=>{
        document.getElementById('websiteusercode').value = ""
        document.getElementById('submitbtn').innerHTML = "Submit";
    }
    
    return (
        <div >{websitecodedata ? <div className="container previewcode">
            
                  
                  <input className="form-control websitecode" placeholder="Enter the Website Preview Code (Beta Testing)" type="text" id="websiteusercode"/>
                  <br/>
                         <button className="btn btn-success " style={{outline:'none',float:'left'}} onClick={e=>submitcode()} id="submitbtn">Submit</button>
                         <button className="btn btn-danger " style={{outline:'none',float:'right'}} onClick={e=>resetwebsitecode()}>Reset</button>
                         
        </div> :
        exist
                ? <center>
                        <div className="generatewebsite">
                        {!editname ?<>   <div id="demodownloadprintcv" className=" webtempletcopllapse">


<button
    className="btn bthBorderLess btn-default" onClick = {
        e => seteditname(true)
    }
    data-tip = "Edit URL" >
    < span className = "glyphicon glyphicon-edit"   > </span>
</button>

<button
    className="btn bthBorderLess btn-default" data-tip="Copy URL" onClick = {
        e => clipboard('makeyacv.priyonujdey.in/')
    }
  >
   <span className = "glyphicon glyphicon-copy"   > </span>
</button>
<button
    className="btn bthBorderLess btn-default" data-tip="Change Templet" onClick = {
        e => changewebsite('WebTempletSelect')
    } 
  >
   < span className = "glyphicon glyphicon-folder-open"  > </span> 
</button>
< ReactTooltip />
</div></> :<></>}
                  

                            <h1>Preview Site</h1>
                            <h3 style={{fontSize:'20px'}}>makeyacv.priyonujdey.in/{!editname
                                    ? <>{previewdata.url}   </>
                                    : <input
                                        type="text"
                                        placeholder=" your name "
                                        id="webnamechange"
                                        placeholder={previewdata.url}
                                        onChange={e => validate(e.target.value)}
                                        class="borLessInput"
                                        autoComplete="off"/>}

                            </h3>

                            {errormsg
                                ? <p
                                        style={{
                                        paddingLeft: "20%",
                                        fonSize: "15px",
                                        color: "red"
                                    }}>Already Taken</p>
                                : <p></p>}
                            <br></br>
                            <br></br>
                            {!editname
                                ? <a href={urlval} target="_blank"><button
                                        className="btn btn-primary btn-preview"
                                        >Preview</button></a>
                                : <> {
                                    !errormsg
                                        ? <button
                                                className="btn btn-primary btn-preview"
                                                onClick={e => generate(1, 'webnamechange')}>Change</button>
                                        : <></>}
                     <button className="btn btn-primary btn-preview" onClick={e=>resetname()}>Reset</button > </>}

                        </div>
                    </center>
                : <center>
                    <div className="generatewebsite">
                        <h1>Create Site</h1>

                        makeyacv.priyonujdey.in/<input
                            type="text"
                            placeholder=" your name "
                            id="webname"
                            placeholder="Your Name"
                            onChange={e => validate(e.target.value)}
                            class="borLessInput"
                            autoComplete="off"/>
                        <br></br>
                        {errormsg
                            ? <p
                                    style={{
                                    paddingLeft: "20%",
                                    fonSize: "15px",
                                    color: "red"
                                }}>Already Taken</p>
                            : <p
                                style={{
                                paddingLeft: "20%",
                                fonSize: "15px",
                                color: "green"
                            }}></p>}
                        <br></br>
                        {!errormsg
                            ? <button
                                    className="btn btn-primary btn-preview"
                                    onClick={e => generate(0, 'webname')}>Generate Your Site</button>
                            : <></>}

                    </div>
                </center>
}

        </div>
    );
}

export default Website;
