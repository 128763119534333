import { useEffect } from 'react';
import './cvtemplet1.css'
import firebase from 'firebase'
import 'firebase/database'
import config from '../../config'
import {
  useObjectVal,
  useListVals,
  useListKeys
} from 'react-firebase-hooks/database';


if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
var arritems = ["Education", "Research Area", "Work Experience", "Teaching Experience", "Skill", "Project", "Publication", "Membership", "Training",
        "Course",  "Honour", "Achivement", "Award", "Personal Detail","Certification"

    ]
   
    var countdiv = 1;
function CV1Formate() {
    //useEffect(()=>{
        var accountTypeVal;
      /*  var menuArray = [];
            var menu = db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/MenuList');
            menu.once('value', snapshot => {
                snapshot.forEach(element => {
                    var menuName = element.child('Name').val().replaceAll(' ', '');
                    menuName = menuName.split('/');
                    for (var i = 0; i < menuName.length; i++) {
                        menuArray.push(menuName[i])
                    }
                })
               
                var arrdivp = [];
                var countp = 0;
                var countdiv = 1;
                var checkPval = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/CVFormate/c1');
                checkPval.once('value', snapshot => {
                    snapshot.forEach(element => {
                        countp++;
    
                        arrdivp.push(element.child('Value').val())
                        if (element.child('Value').val() == 'Personal Detail') {
                           // $('#div' + countdiv).append('<p id="drag' + countdiv + '" draggable="true" ondragstart="drag(event)" class="cvfirst1DivBoxp">' + element.child('Value').val() + '</p>')
                            countdiv++;
                        }
                        if (menuArray.indexOf(element.child('Value').val().replace(' ', '')) > -1) {
    
                           // $('#div' + countdiv).append('<p id="dragp' + countdiv + '" draggable="true" ondragstart="drag(event)" class="cvfirst1DivBoxp">' + element.child('Value').val() + '</p>')
                            countdiv++;
                        }
                    });
                   
                    if (countp == 0) {
                        var j = 1;
                        for (var i = 1; i <= 17; i++) {
                            if (arritems[i - 1] == 'Personal Detail') {
                               // $('#div' + j).append('<p id="drag' + j + '" draggable="true" ondragstart="drag(event)" class="cvfirst1DivBoxp">' + arritems[i - 1] + '</p>')
                                j++;
                            }
                            if (menuArray.indexOf(arritems[i - 1].replace(' ', '')) > -1) {
    
                               // $('#div' + j).append('<p id="drag' + j + '" draggable="true" ondragstart="drag(event)" class="cvfirst1DivBoxp">' + arritems[i - 1] + '</p>')
                                j++;
                            }
    
                        }
    
                    } else {
                        var j = 1;
                        for (var i = 1; i <= 17; i++) {
    
                            if (arrdivp.includes(arritems[i - 1]) == false) {
                                if (arritems[i - 1] == 'Personal Detail') {
                                   // $('#dragdiv' + j).append('<p id="drag' + j + '" draggable="true" ondragstart="drag(event)" class="cvfirst1DivBoxp">' + arritems[i - 1] + '</p>')
                                    j++;
                                }
                                if (menuArray.indexOf(arritems[i - 1].replace(' ', '')) > -1) {
    
                                   // $('#dragdiv' + j).append('<p id="drag' + j + '" draggable="true" ondragstart="drag(event)" class="cvfirst1DivBoxp">' + arritems[i - 1] + '</p>')
                                    j++;
                                }
    
                            }
                        }
    
                    }
                })
            })
        
    //},[])
*/


  
    var [previewdata,loading] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/MenuList/'));
    if(!loading){
        var parent = document.getElementById('menuListItem')
        //document.getElementById('SkillRatingVal0').innerHTML = '';
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
        var j = 0;
        previewdata.map(item=>{
  
           
            var temp = item.Name
            var menuName = temp.replaceAll(' ', '');
            menuName = menuName.split('/');
            for (var i = 0; i < menuName.length; i++) {
               // menuArray.push(menuName[i])
               try{
                if(arritems.includes(arritems[i]))
                   {

                    let data = `<div class="cvfirst1DivBox  text-center" id="dragdiv${j}"  ondrop="${drop('event', j)}"  ondragover="allowDrop(event)">
                                  <p id="drag123" draggable="true"  ondragstart="drag(event)" class="cvfirst1DivBoxp">${menuName[i]}</p>
                                </div>`
                     document.getElementById('menuListItem').insertAdjacentHTML("beforeend", data);
                     j++
                   }
       
            }
               catch{}
               
            }
       
        })
    }
    
    var accountTypeVal;
    var menuArray = [];
    function allowDrop(ev) {
        ev.preventDefault();
      
    }

    function drag(ev) {
      
        ev.dataTransfer.setData("Text", ev.target.id);
    
    }
    
    function drop(ev,id) {
console.log(ev,id)
       //console.log(document.getElementById(ev.target.id).innerHTML)
      var data = ev.dataTransfer.getData("Text");
    //  ev.target.appendChild(document.getElementById(data));
        //var res = $('#' + id).text();
        //(if (res.trim().length == 0) {
          //  ev.target.appendChild(document.getElementById(data));
    
       // }
        //ev.preventDefault();
    }
    function handleDrag() {
        console.log("Dragging...")
    }
    return (
        <div className="container-fluid">
        <div className="row">
            <div className="col-xs-12 text-center" onClick={e=>drop(e,1)}>
                <h4 style={{color:'black'}}>Drag item from Menu Section and Place in the CV Templet </h4>
            </div>
            <div className="col-xs-8">
                <center>
                    <h3 style={{color:'black'}}>CV TEMPLET</h3>
                </center>
                <div draggable onDrag={handleDrag}>
                   Hello World
                </div>
                <div className="cvfirst cvfirst1">
                    <div className="cvzero">
                        <div className="cvzero2 ">
                            <p> ADDRESS <br/>
                                MOBILE <br/>
                                EMAIL <br/></p>
                        </div>
                        <div className="cvzero1 ">
                            NAME
                        </div>
                    </div>
                    <div id="div1" className="cvfirstDivBox  " onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    <p id="drag" draggable="true" onDragStart={event=>drag(event)} className="cvfirst1DivBoxp">test</p>
                    </div>
                    <div className=" cvfirstDivBox " id="div2" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div3" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div4" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div5" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div6" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div7" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div8" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div9" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div10" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div11" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div12" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div13" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox  " id="div14" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox " id="div15" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox  " id="div16" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
                    <div className=" cvfirstDivBox  " id="div17" onDrop={event=>drop(event)} onDragOver={event => allowDrop(event)}>
                    </div>
    
                </div>
            </div>
            <div className="col-xs-4">
                <center>
                    <h3 style={{color:'black'}}>MENU SECTION </h3>
                </center>
                <div className="cvfirst">
                    <div className="cvfirstcontect" id="menuListItem">
                        {/* <div className=" cvfirst1DivBox  text-center" id="dragdiv1" onDrop={event=>drop(event,'dragdiv1')}

                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv2" onDrop={event=>drop(event,'dragdiv2')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv3" onDrop={event=>drop(event,'dragdiv3')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv4" onDrop={event=>drop(event,'dragdiv4')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv5" onDrop={event=>drop(event,'dragdiv5')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv6" onDrop={event=>drop(event,'dragdiv6')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv7" onDrop={event=>drop(event,'dragdiv7')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv8" onDrop={event=>drop(event,'dragdiv8')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv9" onDrop={event=>drop(event,'dragdiv9')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv10" onDrop={event=>drop(event,'dragdiv10')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv11" onDrop={event=>drop(event,'dragdiv11')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv12" onDrop={event=>drop(event,'dragdiv12')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv13" onDrop={event=>drop(event,'dragdiv13')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv14" onDrop={event=>drop(event,'dragdiv14')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv15" onDrop={event=>drop(event,'dragdiv15')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv16" onDrop={event=>drop(event,'dragdiv16')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div>
                        <div className=" cvfirst1DivBox  text-center" id="dragdiv17" onDrop={event=>drop(event,'dragdiv17')}
                            onDragOver={event => allowDrop(event)}>
    
                        </div> */}
    
                    </div>
                </div>
                <br/><br/><br/>
    
            </div>
        </div>
    </div>
    )
}
export default CV1Formate;