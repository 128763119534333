import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function Membership(props) {
    userid = props.id
    var [ProfessionalMembershipdata] = useListVals(db.ref('UserAccount/' + userid + '/ProfessionalMembership'));
    ProfessionalMembershipdata.sort(function (a, b) {
        return parseFloat(b.teacstartDate) - parseFloat(a.teacstartDate);
    });

    return ( <>   <p className="p">Membership</p><ul style={{fontSize:"12pt"}}>
        {
        ProfessionalMembershipdata && ProfessionalMembershipdata.map(data => <ProfessionalMembershipval edu={data}/>)
    }</ul> </>)
}
var countedu = []
function ProfessionalMembershipval(props)
{
    var {
        proftitle,profStatus,profmemendDate,profmemstartDate,proforg,profresNo
    } = props.edu;
    
    return (<>
    <b>{proforg}</b> , {proftitle}<br/>
    {profresNo}
       </>);
}
export default Membership