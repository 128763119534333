import React, {useState} from 'react';
import './pasword.css'
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../config'
import { useObjectVal} from 'react-firebase-hooks/database';
var md5 = require("md5"); 
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
function Password() {
    const notify = () => toast('Here is your toast.');
    var [previewdata] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Password'));
    const [verify,
        setverify] = useState(false);
    const oldpassverify = e => {
        if (md5(document.getElementById('oldPass').value) === previewdata) {
            document
                .getElementById('oldPass')
                .value = ''
            setverify(true)
        } else {
            toast('Password Incorrect', {
                style: {
                  backgroundColor:'#F9AAAA',
                  color:'black', fontSize:'20px',
                },
              });
        }
    }
    const changeoldpass = e => {
        if (md5(document.getElementById('newpassuser').value) === md5(document.getElementById('newpassuser1').value)) {
            var up = db
                .ref('UserAccount/' + localStorage.getItem('makeyacvUser'))
                .update({
                    'Password': md5(document
                        .getElementById('newpassuser')
                        .value)
                })
            document
                .getElementById('newpassuser')
                .value = ''
            document
                .getElementById('newpassuser1')
                .value = ''
            setverify(false)
            toast('Password Update Successfully', {
                style: {
                    backgroundColor:'#B7F9AA',
                    color:'black',
                    fontSize:'20px',
                },
              });

              var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
                     log.push().update({
                         "DateTime" : new Date().toLocaleString() + '',
                         "Message": 'Password Changed'
                     })
        }else {
            toast('Password did not match', {
                style: {
                  backgroundColor:'#F9AAAA',
                  color:'black',
                  fontSize:'20px',
                
                },
              });
        }
    }
const cancelpass =e =>{
  
    if(verify){
        document.getElementById('newpassuser').value = "";
        document.getElementById('newpassuser1').value = "";
        
    }else {
        document.getElementById('oldPass').value = "";
    }
    setverify(false)
}
    return ( <> <center>
        <label class="labelHead">Changed Password</label>
    </center> < div class = "container-fluid" > <div class="col-sm-6">
        <div class="">
            <div id="login_div" class="main-div">
                <div class="container-fluid">
                    {previewdata != null
                        ? <div id="passwordavailable">
                                {!verify
                                    ? <div id="oldpassword">
                                            <span class="label">
                                                <span id="oldval">Old Password</span>
                                            </span>
                                            <input
                                                type="password"
                                                placeholder="Old Password"
                                                id="oldPass"
                                                class="borLessInput"/>

                                            <button class="btn btn-default  btn-enter" onClick={e => oldpassverify()} id="">Verify</button>
                                           
                                        </div>
                                    : <div id="newpassword">
                                        <span class="label">New Password</span>
                                        <input
                                            type="password"
                                            placeholder="New Password "
                                            id="newpassuser"
                                            class="borLessInput"/>
                                        <span class="label">
                                            Confirm Password</span>
                                        <input
                                            type="password"
                                            placeholder=" Confirm Password "
                                            id="newpassuser1"
                                            class="borLessInput"/>

                                        <button class="btn btn-default  btn-enter" onClick={e => changeoldpass()} id="">Changed Password</button>

                                    </div>}
                                <div id="resetbtn">
                                    
                                   
                                        <button class="btn btn-danger " onClick={e=>cancelpass()}>Reset</button>
                                 
                                </div>

                            </div>
                        : <div id="passwordnotavialable">
                            <div class="col-md-12 text-center">
                                <p style={{fontWeight: 'bold',fontSize:'30px'}}>You Cannot Change your Third Party Password From Here</p>
                            </div>
                        </div>}

                </div>
            </div>
        </div>
    </div> < div class = "col-sm-6" > 
        <img src="image/undraw_authentication_fsn5.svg" width="100%"/>
   </div>
                    </div > </>)
}

export default Password;