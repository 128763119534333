import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function TeachingExperience(props) {
    userid = props.id
    var [TeachingExperiencedata] = useListVals(db.ref('UserAccount/' + userid + '/TeachingExperience'));
    TeachingExperiencedata.sort(function (a, b) {
        return parseFloat(b.teacstartDate) - parseFloat(a.teacstartDate);
    });

    return ( <> {
        TeachingExperiencedata && TeachingExperiencedata.map(data => <TeachingExperienceval edu={data}/>)
    } </>)
}
var countedu = []
function TeachingExperienceval(props)
{
  var {
    Image,
    teacPosition,
    teaccompany,teacCType,
    teacendDate,
    id,
    teacstartDate,teacClass,teacDes,teacSubject,teacCourseN,teaclocation
} = props.edu;
if(countedu.indexOf(id) == -1 && id) {
    countedu.push(id)
   
}
return (<>
    <div className="col-sm-6 col-xs-12">
      <div className="container-fluid experiecne1">
         <div className="row">
           <div className="col-xs-3">
           {Image ? <center><img src={Image} /></center> : <></>}
           </div > <div className="col-xs-9">
           <h3>{teacPosition}</h3>
        <p><i>{teaccompany}</i> </p>
        {teacCType ?<p>Course Type : {teacCType}</p>:<></> }
        {teaclocation ? <p>{teaclocation}</p>:<></>}
        <p><span className="glyphicon glyphicon-calendar"></span> {teacstartDate} - {teacendDate}</p>
        { teacDes || teacSubject || teacCourseN
            ? <> <button
                type="button"
                class="btn btn-default btn-descp"
                data-toggle="modal"
                data-target={"#myModal" + id}>Description</button> 

                <div id = {"myModal" + id} class = "modal fade modalt1" role = "dialog" > 
                <div class="modal-dialog">

                <div class="modal-content">
                    
                    <div class="modal-body">
                    {teacCourseN ? <p>Course Name : <b>{teacCourseN} {teacClass ? <>, {teacClass}</>:<></> }</b></p>:<></>}
                    {teacSubject ? <p>Subject Taught : <b>{teacSubject}</b></p> : <></>}
                    {teacDes ? <p>Description : <b>{teacDes}</b></p> : <></>}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default  btn-descp" data-dismiss="modal">Close</button>
                    </div>
                </div>

            </div> </div>  
            </ >
            : <></>}
    </div> </div>
     </div> </div> </>)
}
export default TeachingExperience