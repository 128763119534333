import React, {useEffect,lazy,Suspense} from 'react';
import './m.css'
import Templet1 from './Templet/Templet1/Templet1'

import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
var page = 1;
var ren = 1;
function M(props) {
    useEffect(() => {

        //document.getElementById('dashboardmain').style.display = 'block';
        document.getElementById('loaderm').style.display = 'block';
        document.getElementById('maintemplet').style.display = 'none';
    
    }, [])
const show_page = e => {
    document
    .getElementById('loaderm')
    .style
    .display = 'none';
document
    .getElementById('maintemplet')
    .style
    .display = 'block';
}

    var temp = props.id;
    temp = temp.trim()
    if (temp.indexOf('#') > 0) {
        temp = temp.substring(0, temp.indexOf('#'));
        temp = temp.trim()
    }
    var [previewdata,loading] = useObjectVal(db.ref('Website/' + temp + '/id'));
    if (!loading) {
        if (!previewdata) 
            page = 0;
        }
    var Service ;
    var [Templet,load] = useObjectVal(db.ref('UserAccount/' + previewdata + '/Templet/Website/Templet'));
    if(!load && Templet) {
        Service = lazy(() => import('./Templet/Templet'+Templet+'/Templet'+Templet));
        show_page()
    }
      
    useEffect(() => {
        var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = dd + '/' + mm + '/' + yyyy;
        setTimeout(() => {
            if (page && previewdata && ren === 1 ) {
              var profileInsertkey = db.ref().child('UserAccount/' + previewdata  + '/Notification/Profile').push().key;
                if (localStorage.getItem('makeyacvUser')) {
                    if (localStorage.getItem('makeyacvUser') != previewdata) {
                        if (ren == 1) {
                            var nameuser = db
                                .ref()
                                .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/AboutData/name');
                            nameuser.once('value', snap => {
                                var messageInsert = db
                                    .ref()
                                    .child('UserAccount/' + previewdata + '/Notification/Profile');
                                messageInsert
                                    .child(profileInsertkey)
                                    .set({
                                        "Message": snap.val() + " viewed your profile",
                                        "DateTime": new Date().toLocaleString() + '',
                                        "id" :profileInsertkey
                                    })
                            })
                            var val = db.ref('UserAccount/' + previewdata + '/Analytic').push().update({
                                "Message": "profile viewed",
                                "date" :new Date().toLocaleString() + ''
                             })  
                        }
                    }
                } else {
                    if (ren === 1) {
                        var messageInsert = db
                            .ref()
                            .child('UserAccount/' + previewdata + '/Notification/Profile');
                        messageInsert
                            .child(profileInsertkey)
                            .set({
                                "Message": "Someone viewed your profile",
                                "DateTime": new Date().toLocaleString() + '',
                                "id" :profileInsertkey
                            })
                            var val = db.ref('UserAccount/' + previewdata + '/Analytic').push().update({
                                "Message": "profile viewed",
                                "date" :((new Date().toLocaleString() + '').split(',')[0])
                             }) 
                    }

                }
                ren++;
            }
        }, 2500);
    }, previewdata)
    return ( <> <div id="loaderm">
        <center>
            <img src="./image/Pulse-0.9s-200px.svg"/>
        </center>
    </div> < div id = "maintemplet" > {
        !page
            ? <div className="notfound"><center><img src="/image/undraw_page_not_found_re_e9o6.svg" width="40%"/>
            <h1 >Account Not Found</h1>
            <a href="/"><button className="btn btn-default">MakeYaCV</button></a>
            </center></div>
            :   <Suspense fallback={<div>Website is Loading...</div>}>
                  {Templet ? <Service id={previewdata}/>   : <Templet1 id={previewdata}/>}
                </Suspense>
                        
    } </div></ >);
}

export default M;
