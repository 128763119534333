import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid
function ResearchArea(props){
    userid = props.id
    var [ResearchAreadata] = useListKeys(db.ref('UserAccount/' + userid + '/ResearchArea'));
    
return(<>
          {ResearchAreadata && ResearchAreadata.map(data => <ResearchAreatype edu={data}/>)}
</>)
}

function ResearchAreatype(props)
{
    var type = props.edu
    var [skilldata] = useListVals(db.ref('UserAccount/' + userid + '/ResearchArea/'+props.edu));
    
    return(<>
    {type ? <div className="ResearchAreat3"><div class="container-fluid"><div className="row">
    <u><h2>{type.replaceAll('+',' ')} :</h2></u>
    {skilldata && skilldata.map(item=> <ResearchAreaTypeval val={item}/>)}
    </div></div>
    </div>: <></>}
    </>

    );
}

function ResearchAreaTypeval(props) {
    var {Description,SubDomain,id} = props.val;
    return(<><div class="col-md-4 col-xs-6 ">
      {Description ? <h4 style={{fontWeight:'bold'}} data-toggle="collapse" data-target={"#demo"+id} style={{cursor:'pointer'}}> {SubDomain} 
      <span className="caret" ></span></h4>: <h4 style={{fontWeight:'bold'}}> {SubDomain} </h4>}
      <div id={"demo"+id}class="collapse" style={{paddingLeft:'10px'}}>
         <p>   -  {Description}</p>
       </div>
         </div></>)
}
export default ResearchArea;