import parse from 'html-react-parser';
import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid
function Publication(props){
    userid = props.id
    var [Publicationdata] = useListKeys(db.ref('UserAccount/' + userid + '/Publication'));

return(<><p className="p">Publication</p><ul>
          {Publicationdata && Publicationdata.map(data => <Publicationtype edu={data}/>)}</ul>
</>)
}

function Publicationtype(props)
{
    var type = props.edu
    var [skilldata] = useListVals(db.ref('UserAccount/' + userid + '/Publication/'+props.edu));
    return(<>
    {type ?<> <p style={{fontSize:"15pt",fontWeight:'bold'}}>{type.replaceAll('+',' ')} </p>
   {skilldata && skilldata.map(item=> <PublicationTypeval val={item}/>)}</>
: <></>}
    </>

    );
}

function PublicationTypeval(props) {
    var {Description} = props.val;
    return(<> <li><p style={{fontSize:"12pt"}}>{parse(Description)} </p></li></>)
}
export default Publication;