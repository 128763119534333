import parse from 'html-react-parser';
import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../../config'
import { CircularProgressbar,buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid,skilllen
function Skill(props){
    userid = props.id
    var [Skilldata] = useListKeys(db.ref('UserAccount/' + userid + '/Skill'));
    skilllen = Skilldata.length
return(<>
<div class="container-fluid">
        <div className="row">
        {Skilldata && Skilldata.map(data => <Skilltype edu={data}/>)}
        </div>
        </div>
         
</>)
}

function Skilltype(props)
{
    var type = props.edu
    var [skilldata] = useListVals(db.ref('UserAccount/' + userid + '/Skill/'+props.edu));
    skilldata.sort(function (a, b) {
        return parseFloat(b.SkillRating) - parseFloat(a.SkillRating);
    });
    return(<>
    {type ? 
        <div className={skilllen > 1 ? 'col-md-6' : 'col-md-12' } >
        <h4 style={{color:'white',textAlign:'center'}}> {type.replaceAll('+',' ')} </h4>
            {skilldata && skilldata.map(item=> <SkillTypeval val={item}/>)}

    </div>: <></>}
    </>

    );
}

function SkillTypeval(props) {
    var {Skill,SkillRating} = props.val;
    return(<><div className={skilllen > 1 ? 'col-md-4 col-xs-6' : 'col-md-2 col-xs-6' } >
        <div className="skillval skillcolort3" style={{margin:'20px'}}>
        <CircularProgressbar value={SkillRating*10} text={Skill}styles={buildStyles({
    strokeLinecap: 'butt',
    textSize: '16px',
    pathTransitionDuration: 0.5,
    pathColor: `#379683`,
    trailColor: '#ffffff',
  
  })} />
          </div></div></>)
}
export default Skill;