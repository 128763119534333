import React, {useState, useRef, useCallback, useEffect} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../commonaccount.css'
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import emailjs from 'emailjs-com';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
var md5 = require("md5"); 
const db = firebase.database();
function Login() {
    useEffect(() => {
        loagingscreen();
    }, [])
    const [email,
        setemail] = useState('');
    const [sentemail,
        setsentemail] = useState(false);
    const [password,
        setpassword] = useState('');
        const [oyp,
            setoyp] = useState('')
    const loagingscreen = e => {
        document
            .getElementById('loadersignup')
            .style
            .display = "block"
        document
            .getElementById('mainSignupSection')
            .style
            .display = "none"
        setTimeout(() => {
            document
                .getElementById('loadersignup')
                .style
                .display = "none"
            document
                .getElementById('mainSignupSection')
                .style
                .display = "block"
        }, 500);
    }
    const randomcode = e => {
        setoyp(Math.floor(100000 + Math.random() * 900000));
    }
    useEffect(() => {
        randomcode();
    }, [])
    const loginuser = e => {
        e.preventDefault()
        var email = document
            .getElementById('userEmailCreate')
            .value;
        var modifiedemail = email.replace(/\./g, '(dot)')
        modifiedemail = modifiedemail.replace(/\$/g, '(dollar)')
        modifiedemail = modifiedemail.replace(/\[/g, '(left)')
        modifiedemail = modifiedemail.replace(/\]/g, '(right)')
        modifiedemail = modifiedemail.replace(/\//g, '(slash)')
      
                var docRef = db.ref("UserAccount/" + modifiedemail + '/id');
                docRef.once("value", snapshot => {
                    if (snapshot.val() === null) {
                        loagingscreen();
                        toast("Invalid Email id", {
                            style: {
                                backgroundColor: '#F9AAAA',
                                color: 'black',
                                fontSize: '20px'
                            }
                        });
                    } else {
                        var docRef = db.ref("UserAccount/" + modifiedemail + '/Password');
                        docRef.once("value", snapshot => {
                            if (snapshot.val() === null) {
                                loagingscreen();
                                toast("You Cannot Change your Third Party Password From Here", {
                                    style: {
                                        backgroundColor: '#F9AAAA',
                                        color: 'black',
                                        fontSize: '20px'
                                    }
                                });
                            }
                            else{
                                if (password.trim() !== document.getElementById('userPasswordforget1').value) {
                                    loagingscreen();
                                    toast("Password Didn't Match", {
                                        style: {
                                            backgroundColor: '#F9AAAA',
                                            color: 'black',
                                            fontSize: '20px'
                                        }
                                    });
                                    return
                                }
                                loagingscreen();
                                localStorage.setItem('makeyacvUserotp', oyp);
                                emailjs.sendForm('makeyacvemail', 'template_wbjtki1', e.target, 'user_Kq3uHzVSXjitau67AcDx1')
                                .then((result) => {
                                      // console.log(result.text);
                                   }, (error) => {
                                      // console.log(error.text);
                                   });
                                setsentemail(true)
                            }
                        })       
        
                    }
                })
         
       
    }
    const otpverify = e=>{
        if(document.getElementById('verifyusercode').value.trim() !== localStorage.getItem('makeyacvUserotp'))
        {
            loagingscreen();
            setTimeout(() => {
                toast("Invalid OTP", {
                    style: {
                      backgroundColor:'#F9AAAA',
                      color:'black',
                      fontSize:'20px',
                    
                    },
                  });
            }, 500);
            return
        }
        var modifiedemail = email.replace(/\./g, '(dot)')
        modifiedemail = modifiedemail.replace(/\$/g, '(dollar)')
        modifiedemail = modifiedemail.replace(/\[/g, '(left)')
        modifiedemail = modifiedemail.replace(/\]/g, '(right)')
        modifiedemail = modifiedemail.replace(/\//g, '(slash)')
        var insert = db.ref().child('UserAccount/'+modifiedemail).update({"Password": md5(password)});
                    toast("Password Changed", {
                        style: {
                          backgroundColor:'#CDFA7D',
                          color:'black',
                          fontSize:'20px',
                        
                        },
                      });
                      localStorage.removeItem('makeyacvUserotp')
                      setoyp('')
                loagingscreen();
                setTimeout(() => {
                    window.location.href = "/Login"
                }, 800)
    }
    return (
        <div className="box">
            <nav className="navbar navbar-default">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/"><img src="/image/MakeYaCV_Logo_01.svg"/></a>
                    </div>

                </div>
            </nav>
            <div className="container">
                <div className="container-box">
                    <img src="/image/undraw_thought_process_re_om58.svg" width="50%"/>
                </div>
                <div className="container-box-login">
                    <div id="loadersignup">
                        <img src="./image/Pulse-0.9s-200px.svg"/>
                    </div>
                    <div id="mainSignupSection">

                        {!sentemail
                            ? <> <div className="signup_form" id="signup_section">
                                <p className="headTextf">Forget Password</p>
                                <br/>
                              <form onSubmit={loginuser}>
                                <div className="form__group field">
                                    <input
                                        type="text"
                                        className="form__field"
                                        id='userEmailCreate'
                                        placeholder="Email"
                                        value={email}
                                        name="to_name"
                                        onChange={e => setemail(e.target.value)}
                                        autoComplete="off"/>
                                    <label for="name" className="form__label">Email</label>
                                </div>
                                <div className="form__group field">
                                    <input
                                        type="password"
                                        className="form__field"
                                        id='userPasswordforget'
                                        placeholder="New Password"
                                        value={password}
                                        onChange={e => setpassword(e.target.value)}
                                        autoComplete="off"/>
                                    <label for="name" className="form__label">New Password</label>
                                </div>
                                <div className="form__group field">
                                    <input
                                        type="password"
                                        className="form__field"
                                        id='userPasswordforget1'
                                        placeholder="Confirm Password"
                                        autoComplete="off"/>
                                    <label for="name" className="form__label">Confirm Password</label>
                                </div>
                                <input
                                    type="hidden"
                                    name="message"
                                    value={oyp}
                                    />
                                <div className="container-fluid">
                                    <br></br>
                                    <div className="col-md-12">
                                        <button className=" btn-account " type="submit">Submit</button>
                                    </div>
                                    <div className="col-md-12">
                                    <p className="signin">
                                        <a href="/Login">
                                            Back to Login</a>
                                    </p>
                                </div>
                                </div>

                                </form>
                            </div> </>:<>
                    <div className="signup_form" >
                        <p className="headText headText1">Check Your Email Id for OTP</p > <div className="form__group field">

                                <input
                                    type="input"
                                    className="form__field"
                                    placeholder="Password"
                                    name="verifyusercode"
                                    id='verifyusercode'/>
                                <label for="verifyusercode" className="form__label">Security Code</label>
                            </div> < div className = "container-fluid" > <div className="row">

                                <br></br><br/>
                                <div className="col-md-12">
                                    <button className=" btn-account " onClick={e => otpverify()}>Submit</button>

                                    <br></br><br/>
                                </div>
                                <p className="text-center">*Note: Check your spam folder</p>
                            </div> </div>
                    </div > </>}

                    </div>
                </div>
                <ToastContainer/>
            </div>
        </div>
    );
}

export default Login;
