

import React, {useState, useCallback} from 'react';
import "../common.css"
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import {useListVals,useListKeys} from 'react-firebase-hooks/database';
import ReactTooltip from 'react-tooltip'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();

function TrainingCourse() {

    var [previewdata] = useListKeys(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TrainingCourse'));
    previewdata.sort(function(a, b) {
        return parseFloat(b.confstartDate) - parseFloat(a.confstartDate) ;
    });
    const [selectval,setsetval] = useState('none');
    const [type,settype] = useState('none')
    const [edstatus,
        setedstatus] = useState(false)
    const [editInput,
        seteditinput] = useState(false)

    const subtrainc = e => {
        e.preventDefault();
        var startDate = document
            .getElementById('confstartDate')
            .value;
        if ((document.getElementById('conftitle').value.length === 0) || (startDate.length === 0) ) {
            window.alert(type + " Name / Start date  cannot be empty");
            return;
        }
        if(!edstatus)
        {
            if( document.getElementById('confendDate').value.length === 0)
              {
                  window.alert(type + " End Date cannot be empty");
                  return
              }
        }

        var pushkey;
        if (editInput) {
            pushkey = document
                .getElementById('id')
                .value
        } else {
            pushkey = db.ref()
                .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TrainingCourse/'+type)
                .push()
                .key;
        }

        var exInsert = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TrainingCourse/'+type);
        exInsert
            .child(pushkey)
            .update({"id": pushkey});

       exInsert.child(pushkey).update({
                "Type" : type,
                'conftitle': document.getElementById('conftitle').value,
                'confstartDate': startDate
         })
         if (document.getElementById('confcompany').value.length != 0) {
            exInsert.child(pushkey).update({
                'confcompany': document.getElementById('confcompany').value
            })
        }else{
            exInsert.child(pushkey+'/confcompany').remove();
        }


        if (document.getElementById('conflocation').value.length != 0) {
            exInsert.child(pushkey).update({
                'conflocation': document.getElementById('conflocation').value
            })
        }
        else{
            exInsert.child(pushkey+'/conflocation').remove();
        }


        if (document.getElementById('confyour').value != 'none') {
            exInsert.child(pushkey).update({
                'confyour': document.getElementById('confyour').value
            })
        }
        else{
            exInsert.child(pushkey+'/confyour').remove();
        }

        
        if (document.getElementById('confDes').value.length != 0) {
            exInsert.child(pushkey).update({
                'confDes': document.getElementById('confDes').value
            })
        }
        else{
            exInsert.child(pushkey+'/confDes').remove();
        }
       
        
        {
            edstatus
                ? exInsert
                    .child(pushkey)
                    .update({"confendDate": 'present'})
                : exInsert
                    .child(pushkey)
                    .update({
                        "confendDate": document
                            .getElementById('confendDate')
                            .value
                    });
        }
        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
     log.push().update({
         "DateTime" : new Date().toLocaleString() + '',
         "Message": type+ ' Data Deleted'
     })
        document
            .querySelector('.donetrainc')
            .style
            .display = 'block';
        setTimeout(() => {
            reset();
            document
                .querySelector('.donetrainc')
                .style
                .display = 'none';
        }, 2500);
    }


    const editDatacontent = (e,t) => {
         reset();
         settype(t);
        
        setTimeout(() => {
            document.getElementById('confType').value = t;
            setedstatus('0');
            document.getElementById('confStatus').checked = 0
            seteditinput(true);
            var experiencePreviewId = db
                .ref()
                .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TrainingCourse/' + t+'/'+e);
             experiencePreviewId.once('value', function (snapshot) {
                snapshot
                    .forEach(function (elem) {        
              if(elem.key != 'Type')
              {
                if (elem.key == "confendDate") {
                    if(elem.val() === 'present'){
                        setedstatus(true);
                       document.getElementById('confStatus').checked = 1
                    
                    }
                    else{
                       setedstatus(false);
                      document.getElementById('confStatus').checked = 0
                        setTimeout(() => {
                            document.getElementById(elem.key).value = elem.val();
                        }, 300);
                       
                    }
                }
                
                else{
                  
                        document
                        .getElementById(elem.key)
                        .value = elem.val();
                   
                   
                }
              } 
                        
                    })
    
            })
        },200) 
      
    }
    const reset = e => {
        document.getElementById('confStatus').checked = '0'
        document
            .getElementById("traincform")
            .reset(); 
        seteditinput(false);
        setedstatus(false)
        setTimeout(() => {document.getElementById('confType').value = type},200)
        
    }
    const  deletetrainc = (e,t)=>{

        //setsetval('none')
        var experiencePreviewId = db
         .ref()
         .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TrainingCourse/' + t+'/'+e)
         .remove();
         setTimeout(() => {
            setsetval('none')  
            document.getElementById('trainselectListVal').value= 'none'
         }, 200);
         var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
         log.push().update({
             "DateTime" : new Date().toLocaleString() + '',
             "Message": t+ ' Data Deleted'
         })
          reset();
     }
    var [selectdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TrainingCourse/'+selectval));
    return (
        <div >
            <div className="success-checkmark donetrainc">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6 ">
                            <div className="first">
                                <center>  
                                    {editInput
                                        ?  
                                        type == 'none' ? 
                                        <label className="labelHead">Edit Training / Course </label> :
                                         type == 'Course' ?  
                                         <label className="labelHead">Edit <s>Training /</s>  Course </label> :
                                         <label className="labelHead">Edit Training  <s>/ Course</s> </label>

                                        : type == 'none' ? 
                                        <label className="labelHead">Insert Training / Course </label> :
                                         type == 'Course' ?  
                                         <label className="labelHead">Insert <s>Training / </s> Course </label> :
                                         <label className="labelHead">Insert Training  <s>/ Course</s> </label>}
                                </center>
                                <form id="traincform" onSubmit={subtrainc}>
                                    <div id="login_div" className="main-div">
                                        <input type="hidden" className="borLessInput" id="id"/>
                                        <span className="label">Select Type</span>
                                        <select id="confType" class="borLessInput" onChange={e=>settype(e.target.value)}>
                                            <option value="none">-</option>
                                            <option>Training</option>
                                            <option>Course</option>

                                        </select>
                                        {type == 'none' ? <></> : 
                                        <>
                                        <span className="label">{type == 'none' ? <></> : type} Name </span>
                                        <input type="text" placeholder="Name" id="conftitle" class="borLessInput"/>

                                        <span class="label">Organisation</span>
                                        <input type="text" placeholder="Organisation " id="confcompany"
                                            class="borLessInput"/>
                                        <span class="label"> Location</span>
                                        <input type="text" placeholder=" Location " id="conflocation"
                                            class="borLessInput"/>

                                        <span class="label">Role</span>
                                        <select id="confyour" class="borLessInput">
                                            <option value="none">-</option>
                                            <option>Coordinator</option>
                                            <option>Participant</option>

                                        </select>
                                        
                                        <div class="form-group">
                                            <label >
                                                On Going  <input
                                                    type="checkbox"
                                                    id="confStatus"
                                                    onChange={e => setedstatus(e.target.checked)}
                                                   />
                                            </label><br/>

                                        </div>

                                        <div className="dateYear">
                                            <div className="form-group">

                                                <span class="label">Start date</span>
                                                <input type="date" id="confstartDate" class="borLessInput"/>

                                            </div>
                                            <div className="form-group" id="pass">

                                                <span class="label">End date</span>
                                                {edstatus
                                                    ? <h3>Present</h3>
                                                    : <input type="date" id="confendDate" class="borLessInput"/>}

                                            </div>
                                        </div>
                                        <span class="label">Description</span>
                                        <textarea id="confDes" placeholder="Description (optional)"
                                            class="borLessInput"></textarea>
                                        {editInput
                                            ? <div id="">
                                                    <div className="col-xs-6">
                                                        <button className="btn btn-default btn-edit btn-submit" onclick="updateedu()">Update</button>
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <button
                                                            className="btn btn-edit btn-default"
                                                            type="reset"
                                                            onClick={e => reset()}>Reset</button>
                                                    </div>
                                                </div>
                                            : <div id="">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="submit">Submit</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button
                                                        className="btn btn-edit btn-default"
                                                        type="reset"
                                                        onClick={e => reset()}>Reset</button>
                                                </div>
                                            </div>}

                                    </>
                                        }
                                        
                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div className="second">
                                <center>
                                    <label class="headi labelHead">Preview
                                    </label>
                                </center>
                                <div id="login_div" class="main-div">

                                <select id="trainselectListVal" onChange={e=>setsetval(e.target.value)}>
                                    <option value='none'>Select Type</option>
                                    {previewdata && previewdata.map(data => <Previewdata prop={data} />)}
                                   </select>
                                   <div id='pubvalueappend'>
                                   {selectdata && 
                                   selectdata.map(data => 
                                   <TrainCdetail prop={data} deletetc={(e,t)=>deletetrainc(e,t)} edittrainc={(e,t)=>editDatacontent(e,t)}/>)}
                                    </div>
                                </div>   
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

var error = 0;
function Previewdata(props) {
    var pubTypeItem = []  
    const handleInputChange = useCallback(event => {
        props.editdata(event)
    }, [props.showsection])
  // var [previewdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TrainingCourse/'));
    if(props.prop){
       // console.log(pubTypeItem.indexOf(props.prop))
        if(pubTypeItem.indexOf(props.prop) === -1)
        {
            pubTypeItem.push(props.prop) 
            var dec = props.prop;
            error = 0;
        }
        else error = 1
    }
   
    return (<>
        {error ? <></>: <option value={dec}>{dec}</option>}
        </>
    ) 

}
function TrainCdetail(props)
{

    const handleInputChangeD = useCallback((event,type) => {
        props.deletetc(event,type)
    }, [props.showsection])
    
    const handleInputChangeE  = useCallback((event,type) => {
        props.edittrainc(event,type)
    }, [props.showsection])
   if(props.prop)
     {
         var {id,confDes,Type,confcompany,confendDate,conflocation,confstartDate,conftitle,confyour}= props.prop;
     }
    return (<> <div class="container-fluid">
    <div class="col-xs-10">
    <p>{conftitle}</p>
    {confcompany ?<p>{confcompany}</p> :<></>}
    {conflocation ?<p>{conflocation}</p> :<></>}
    {confyour ?<p>Role: {confyour}</p> :<></>}
    <p>{confstartDate} - {confendDate}</p>
    {confDes
                    ? <div>
                            <button
                                class="btn btn-default bnt1"
                                data-toggle="collapse"
                                data-target={'#' + id}>Description</button>
                            <div id={id} className="collapse">
                                <p>{confDes}</p>
                            </div>
                        </div>
                    : <div></div>}
    </div> 
    <div className="col-xs-2">
    
       <button data-tip="Delete" className="btn bthBorderLess btn-default" onClick={(e,t)=>handleInputChangeD(id,Type)}>
           <span className="glyphicon glyphicon-trash" ></span>
       </button> 
       <button
        data-tip="Edit"
           onClick={(e,t) => handleInputChangeE(id,Type)}
           className="btn bthBorderLess btn-default">
                        <span className="glyphicon glyphicon-edit"></span>
                    </button>
    </div>
    <ReactTooltip /> 
    </div>   <hr/></>)
}
export default TrainingCourse;
