import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function WorkExperience(props) {
    userid = props.id
    var [WorkExperiencedata] = useListVals(db.ref('UserAccount/' + userid + '/WorkExperience'));
    WorkExperiencedata.sort(function (a, b) {
        return parseFloat(b.EmpstartDate) - parseFloat(a.EmpstartDate);
    });

    return ( <>   <p className="p">Work EXPERIENCE</p> <ul>{
        WorkExperiencedata && WorkExperiencedata.map(data => <WorkExperienceval edu={data}/>)
    } </ul></>)
}

function WorkExperienceval(props)
{
  var {
    Image,
    EmpDes,Emptitle,
    Empcompany,Emplocation,
    EmpendDate,
    id,
    EmpstartDate,supervisor,titleDes,titleName,projecttitle
} = props.edu;
var startd = EmpstartDate.split('-')
var endd = EmpendDate.split('-')
return (<>
<li style={{paddingLeft:"10px"}}>
<b>{Emptitle}</b> <span style={{'fontSize':'15px'}}>( {startd[0]}  -  {endd[0]} )</span><br></br>
{Empcompany}<br/>
{Emplocation}
{titleName ?<> {titleName}: {projecttitle}</>:<></>}
{titleDes ?<> - {titleDes}</>:<></>}
{supervisor ?<>Supervisor - {supervisor}</>:<></>}
<br/>
</li>
     </>)
}
export default WorkExperience