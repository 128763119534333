import React, {useState,useCallback} from 'react';
import "./experience.css"
import "../common.css"
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import Compressor from 'compressorjs'
import ReactTooltip from 'react-tooltip';
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target.result)
    };
    reader.readAsDataURL(file);
})
function TeachingExperience() {
    var [previewdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TeachingExperience'));
    previewdata.sort(function(a, b) {
        return parseFloat(b.EmpstartDate) - parseFloat(a.EmpstartDate) ;
    });
    const [edstatus,
        setedstatus] = useState(false)
    const [editInput,
        seteditinput] = useState(false)
    const test = e => {
        console.log(e)
    }
    const [dataUri,
        setDataUri] = useState('')
    const suntecExp = e => {
      
        e.preventDefault();
        if (document.getElementById('teacCourseN').value.length == 0 || document.getElementById('teacstartDate').value.length == 0) {
            window.alert("Course Name / Start Date  cannot be empty")
            return
        }

        if ((document.getElementById('teacexStatus').checked == false) && (document.getElementById('teacendDate').value.length == 0)) {
            window.alert('Enter End date')
            return;
        }
        var pushkey;
        if (editInput) {
            pushkey = document
                .getElementById('id')
                .value
        } else {
            pushkey = db
                .ref()
                .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TeachingExperience')
                .push()
                .key;
        }
       
        var teach = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TeachingExperience');
        teach
            .child(pushkey)
            .update({"id": pushkey});
        teach
            .child(pushkey)
            .update({
                'teacCourseN': document
                    .getElementById('teacCourseN')
                    .value,
                'teacstartDate': document
                    .getElementById('teacstartDate')
                    .value
            })
        if (document.getElementById('teaccompany').value.length != 0) {
            teach
                .child(pushkey)
                .update({
                    'teaccompany': document
                        .getElementById('teaccompany')
                        .value
                })
        }
        else{
            teach.child(pushkey+'/teaccompany').remove();
        }
        if (document.getElementById('teacPosition').value.length != 0) {
            teach
                .child(pushkey)
                .update({
                    'teacPosition': document
                        .getElementById('teacPosition')
                        .value
                })
        }
        else{
            teach.child(pushkey+'/teacPosition').remove();
        }

        if (document.getElementById('teaclocation').value.length != 0) {
            teach
                .child(pushkey)
                .update({
                    'teaclocation': document
                        .getElementById('teaclocation')
                        .value
                })
        }
        else{
            teach.child(pushkey+'/teateaclocationccompany').remove();
        }

        if (document.getElementById('teacCType').value.length != 'none') {
            teach
                .child(pushkey)
                .update({
                    'teacCType': document
                        .getElementById('teacCType')
                        .value
                })
        }else{
            teach.child(pushkey+'/teacCType').remove();
        }

        if (document.getElementById('teacClass').value.length != 0) {
            teach
                .child(pushkey)
                .update({
                    'teacClass': document
                        .getElementById('teacClass')
                        .value
                })
        }else{
            teach.child(pushkey+'/teacClass').remove();
        }

        if (document.getElementById('teacSubject').value.length != 0) {
            teach
                .child(pushkey)
                .update({
                    'teacSubject': document
                        .getElementById('teacSubject')
                        .value
                })
        }else{
            teach.child(pushkey+'/teacSubject').remove();
        }

        if (document.getElementById('teacCredit').value.length != 0) {
            teach
                .child(pushkey)
                .update({
                    'teacCredit': document
                        .getElementById('teacCredit')
                        .value
                })
        }else{
            teach.child(pushkey+'/teacCredit').remove();
        }

        if (document.getElementById('teacexStatus').checked) {
            teach
                .child(pushkey)
                .update({'teacendDate': 'present'})
        } else {
            teach
                .child(pushkey)
                .update({
                    'teacendDate': document
                        .getElementById('teacendDate')
                        .value
                })

        }
        if (document.getElementById('teacDes').value.length != 0) {
            teach
                .child(pushkey)
                .update({
                    'teacDes': document
                        .getElementById('teacDes')
                        .value
                })
        }
        else{
            teach.child(pushkey+'/teacDes').remove();
        }

        if (dataUri != '') 
        {
            teach.child(pushkey).update({"Image": dataUri});
        }else{
            teach.child(pushkey+'/Image').remove();
        }
      
        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
            log.push().update({
                "DateTime" : new Date().toLocaleString() + '',
                "Message": 'Teaching Experience Data Modified'
            })
        document
            .querySelector('.doneteach')
            .style
            .display = 'block';
        setTimeout(() => {
            reset();
            document
                .querySelector('.doneteach')
                .style
                .display = 'none';
        }, 2500);
    }
    const onChange = (file) => {
        //console.log(file) setfilename(file.name)
        if (!file) {
            setDataUri('');
            return;
        }
        if (file.type == 'image/jpeg' || file.type == 'image/png') {
            if (file.size / 1024 > 1024) {
                alert("File size should be less than 1 MB")
            } else {
                var qt = 0.8;
                if (file.size / 1024 > 800) {
                    qt = 0.6
                }
                new Compressor(file, {
                    quality: qt, // 0.6 can also be used, but its not recommended to go below.
                    success: (compressedResult) => {
                        fileToDataUri(compressedResult).then(dataUri => {
                            setDataUri(dataUri)
                        })
                    }
                });
            }
        } else {
            alert("image is supported")

        }
    }
    const editdatacontent = e => {
        // console.log(e)
         seteditinput(true);
         setedstatus(false);
          document.getElementById('teacexStatus').checked = false;
         var experiencePreviewId = db
             .ref()
             .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TeachingExperience/' + e);
         experiencePreviewId.once('value', function (snapshot) {
             snapshot
                 .forEach(function (elem) {
                      // console.log(elem.val())
                     if (elem.key === 'Image') {
                         setDataUri(elem.val())
                     } 
                     else if(elem.key == "teacendDate")
                     {
                         if(elem.val() === 'present'){
                             setedstatus(true);
                             document.getElementById('teacexStatus').checked = true;
                         }
                         else{
                             setedstatus(false);
                             document.getElementById('teacexStatus').checked = false;
                             setTimeout(() => {
                                 document.getElementById(elem.key).value = elem.val();
                             }, 300);
                            
                         }
                     }
                     else {
                         document.getElementById(elem.key).value = elem.val();
                     }
                 })
 
         })
     }
    const reset = e => {

        document
            .getElementById("teachform")
            .reset();
        setDataUri('')
        seteditinput(false);
        setedstatus(false)
    }
    return (
        <div >
            <div className="success-checkmark doneteach">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div class="col-sm-6 ">
                            <div className="first">
                                <center>
                                    {editInput
                                        ? <label className="labelHead">Edit Teaching Experience</label>
                                        : <label className="labelHead">Insert Teaching Experience</label>}
                                </center>
                                <form id="teachform" onSubmit={suntecExp}>
                                    <div id="login_div" className="main-div">
                                        <input type="hidden" className="borLessInput" id="id"/>

                                        <span class="label">Institute Name</span>
                                        <input
                                            type="text"
                                            placeholder=" Institute Name "
                                            id="teaccompany"
                                            class="borLessInput"/>
                                        <br></br>
                                        {editInput
                                            ? <div id="LogoUp">

                                                    <div className="updateLogo"></div>
                                                    <div id="instLogoUp">
                                                        {dataUri
                                                            ? <img src={dataUri} width="30%"/>
                                                            : <img
                                                                src="/image/logo_here.png"
                                                                width="30%"
                                                                style={{
                                                                marginTop: '30px'
                                                            }}/>}
                                                    </div>
                                                    <div className="editinputLogo">
                                                        <label id="pict">Update Institute Logo
                                                            <input
                                                                type="file"
                                                                id="CollegeLogoedit"
                                                                onchange="imagedet(event,'instLogoUp')"
                                                                className="borLessInput"/>
                                                        </label>
                                                    </div>
                                                    <br/>
                                                </div>
                                            : <div id="LogoIn">
                                                <div id="instLogo"><img src={dataUri} width="30%"/></div>

                                                <div className="editinputLogo">
                                                    <label id="pict">Institute Logo
                                                        <input
                                                            type="file"
                                                            onChange={(event) => onChange(event.target.files[0] || null)}/>
                                                    </label>
                                                </div>

                                            </div>}

                                        <br/><br/>
                                        <span className="label">Institute Location</span>
                                        <input
                                            type="text"
                                            placeholder=" Location "
                                            id="teaclocation"
                                            className="borLessInput"/>

                                        <span className="label">
                                            Position / Title</span>
                                        <input
                                            type="text"
                                            placeholder=" Position"
                                            id="teacPosition"
                                            className="borLessInput"/>

                                        <span className="label">Course type</span>
                                        <select id="teacCType" className="borLessInput">
                                            <option value="none">-</option>
                                            <option>School Level - Primary</option>
                                            <option>School Level - Secondary</option>
                                            <option>School Level - Higher Secondary</option>
                                            <option>Training</option>
                                            <option>Under Graduate</option>
                                            <option>Post Graduate</option>
                                            <option>Doctoral</option>
                                            <option>Vocational</option>
                                            <option>Professional Training</option>
                                            <option>Private Coaching</option>

                                        </select>
                                        <span className="label">Course Name</span>
                                        <input
                                            type="text"
                                            placeholder="  Course Name "
                                            id="teacCourseN"
                                            className="borLessInput"/>

                                        <span className="label">class Name</span>
                                        <input
                                            type="text"
                                            placeholder="  Class Name (eg. First Year)"
                                            id="teacClass"
                                            className="borLessInput"/>

                                        <span className="label">
                                            Subject Name</span>
                                        <input
                                            type="text"
                                            placeholder="  Subject Name "
                                            id="teacSubject"
                                            className="borLessInput"/>

                                        <span className="label">Credit Hours(T-S-P)</span>
                                        <input
                                            type="text"
                                            placeholder="   Theory - Sessional - Practical "
                                            id="teacCredit"
                                            className="borLessInput"/>

                                        <div className="form-group">
                                            <label >
                                                I am currently working in this role
                                                <input
                                                    id="teacexStatus"
                                                    type="checkbox"
                                                    onChange={e => setedstatus(e.target.checked)}/>
                                            </label><br/>

                                        </div>

                                        <div className="dateYear">
                                            <div className="form-group">

                                                <span className="label">Start date</span>
                                                <input type="date" id="teacstartDate" className="borLessInput"/>

                                            </div>
                                            <div className="form-group" id="pass">

                                                <span className="label">End date</span>
                                                {edstatus
                                                    ? <h3>Present</h3>
                                                    : <input type="date" id="teacendDate" class="borLessInput"/>}

                                            </div>
                                        </div>
                                        <span class="label">Description</span>
                                        <textarea
                                            id="teacDes"
                                            placeholder="Responsibility (optional)"
                                            class="borLessInput"></textarea>

                                        {editInput
                                            ? <div id="">
                                                    <div className="col-xs-6">
                                                        <button className="btn btn-default btn-edit btn-submit" type="submit">Update</button>
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <button
                                                            className="btn btn-edit btn-default"
                                                            type="reset"
                                                            onClick={e => reset()}>Reset</button>
                                                    </div>
                                                </div>
                                            : <div id="">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="submit">Submit</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button
                                                        className="btn btn-edit btn-default"
                                                        type="reset"
                                                        onClick={e => reset()}>Reset</button>
                                                </div>
                                            </div>}

                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div className="second">
                                <center>
                                    <label class="headi labelHead">Preview
                                    </label>
                                </center>
                                {previewdata && previewdata.map(data => <Previewdata prop={data} editdata ={e => editdatacontent(e)}/>)}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

function Previewdata(props) {
    const handleInputChange = useCallback(event => {
        props.editdata(event)
    }, [props.showsection])
    if(props.prop)
    var {
        id,
        Image,
        teacCType,
        teacClass,
        teacCourseN,
        teacCredit,
        teacDes,
        teacPosition,
        teacSubject,
        teaccompany,teacendDate,teaclocation,teacstartDate
    } = props.prop;
    const deleteCont = e => { 
        db.ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/TeachingExperience/' + e)
            .remove();
            var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
            log.push().update({
                "DateTime" : new Date().toLocaleString() + '',
                "Message": 'Teaching Experience Data Deleted'
            })
    }
    return (
        <div class="container-fluid previewContent">
            <div class="col-xs-3 exImg">
                {Image
                    ? <img src={Image} width="90%"/>
                    : <center><img
                        src="/image/logo_here.png"
                        width="60%"
                        style={{
                        marginTop: '30px'
                    }}/></center>}

            </div>
            <div class="col-md-7 ebc">
                <p style={{
                    fontSize: '20px'
                }}>
                    <b >{teacPosition}</b>
                </p>
                <p>{teaccompany}</p>
                
                <p>
                    <span><img src="/image/event_available-white-36dp.svg"/></span>
                    {teacstartDate}
                    - {teacendDate}</p>
                {teaclocation
                    ? <p>{teaclocation}</p>
                    : <p></p>}

                {teacClass || teacCType || teacCourseN || teacCredit || teacSubject
                    ? <div>
                            <button
                                class="btn btn-default bnt1"
                                data-toggle="collapse"
                                data-target={'#' + id}>Additional</button>
                            <div id={id} className="collapse">
                            {teacClass
                    ? <p>Class: {teacClass}</p>
                    : <p></p>}
                      {teacSubject
                    ? <p>Subject: {teacSubject}</p>
                    : <p></p>}
                      {teacCType
                    ? <p>Course Type: {teacCType}</p>
                    : <p></p>}
                      {teacCredit
                    ? <p>Credit: {teacCredit}</p>
                    : <p></p>}
                    {teacDes
                    ? <p>Description: {teacDes}</p>
                    : <p></p>}
 
                            </div>
                        </div>
                    : <div></div>}
            </div>
            <div class="col-xs-2">
                <span
                    class="glyphicon glyphicon-option-horizontal"
                    data-toggle="collapse"
                    data-target={'#info' + id}></span>
                <div id={'info' + id} class="collapse edcopllapse">
                    <button
                        data-tip="Delete"
                        onClick={(e) => deleteCont(id)}
                        class="btn bthBorderLess btn-default">
                        <span class="glyphicon glyphicon-trash"></span>
                    </button>
                    <br/>
                    <button
                        data-tip="Edit"
                        onClick={(e) => handleInputChange(id)}
                        class="btn bthBorderLess btn-default">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                    <ReactTooltip />
                </div>
            </div>
        </div>
    )
}

export default TeachingExperience;
