import react from 'react';
import './links.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import ReactTooltip from 'react-tooltip';
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
function Links() {

var [sociallink] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Link/SocialLinks'));
var [professioanllink] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Link/ProfessionalLinks'));

const submitlinks = e=>{
    if(document.getElementById(e+'link').value == 'none'){
        toast('Select Media ', {
            style: {
              backgroundColor:'#F9AAAA',
              color:'black', fontSize:'20px',
            },
          });
          return 0;
       }
   if(document.getElementById(e+'Linkurl').value.length == 0){
    toast('Enter Url', {
        style: {
          backgroundColor:'#F9AAAA',
          color:'black', fontSize:'20px',
        },
      });
      return 0
   }
   var temp = e+'Links'
   var pushkey = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Link/'+temp).push().key;
   var links = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Link/'+temp);
   links.child(pushkey).update({
       "media" : document.getElementById(e+'link').value,
       "url" : document.getElementById(e+'Linkurl').value,
       "id" : pushkey
   })
   var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
   log.push().update({
       "DateTime" : new Date().toLocaleString() + '',
       "Message": ' Link Data Inserted'
   })
   resetsocial(e)
}
const resetsocial = e =>{
 
//    document.getElementById(e+'link').value = ""; 
  //  document.getElementById(e+'link').data-content('')
    document.getElementById(e+'Linkurl').value = ""; 
}


    return (
        <section id="home">
            <div className="container-fluid ">

                <div className="col-sm-6">

                    <div className="first">

                        <div className="container-fluid mylinks">
                            <div className="col-md-12">
                                <center>
                                    <label className="labelHead">Social Media Link</label>
                                </center>
                                <br/><br/>   <br/>
                            </div>
                           
                            <div className="col-xs-5 ">
                                <select className="selectpicker" id="Sociallink">
                                    <option value="none" data-content=''>-</option>  
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/facebook-f.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/facebook-f.png"/> Facebook'>
                                        Facebook</option>
                                    <option
                                        value="https://img.icons8.com/android/24/xyz/twitter.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/twitter.png"/> Twitter'>
                                        Twitter</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/instagram-new.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/instagram-new.png"/> Instagram'>
                                        Instagram</option>
                                    <option
                                        value="https://img.icons8.com/ios-glyphs/24/xyz/pinterest.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/pinterest.png"/> Pinterest'>
                                        Pinterest</option>
                                    <option
                                        value="https://img.icons8.com/ios/24/xyz/google-plus.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/google-plus.png"/> Google +'>
                                        Google +</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/youtube-play.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/youtube-play.png"/> YouTube'>
                                        YouTube</option>
                                    <option
                                        value="https://img.icons8.com/windows/24/xyz/blogger-b.png"
                                        data-content='<img src="https://img.icons8.com/windows/20/000000/blogger-b.png"/> Blogger'>
                                        Blogger</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/flickr.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/flickr.png"/> Flickr'>
                                        Flickr</option>

                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/myspace.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/myspace.png"/> Myspace.com'>
                                        Myspace.com</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/soundcloud.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/soundcloud.png"/> SoundCloud'>
                                        SoundCloud</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/tumblr--v2.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/tumblr--v2.png"/> Tumblr'>
                                        Tumblr</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/vimeo.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/vimeo.png"/> Vimeo'>
                                        Vimeo</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/vk-circled.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/vk-circled.png"/> VK'>
                                        VK</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/odnoklassniki.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/odnoklassniki.png"/> Odnoklassniki'>
                                        Odnoklassniki</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/yelp.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/yelp.png"/> Yelp'>
                                        Yelp</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/tripadvisor.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/tripadvisor.png"/> TripAdvisor'>
                                        TripAdvisor</option>
                                </select>

                            </div>
                            <div className="col-xs-7">
                                
                                <input
                                    type="text"
                                    id="SocialLinkurl"
                                    placeholder="URL" style={{width:'100%'}}
                                    className="borLessInput"/>
                            </div>
                            <br/>  
                        </div>
                            <div id="login_div" className="main-div">
                             
                                    <div className="col-xs-6">
                                        <button className="btn btn-edit btn-default" 
                                        onClick={e=>submitlinks('Social')}>Submit</button>
                                    </div>
                                    <div className="col-xs-6">
                                        <button className="btn btn-edit btn-default" type="reset"
                                         onClick={e=>resetsocial('Social')}>Reset</button>
                                    </div>
                            </div>
                           
                            <ToastContainer />
                      
                        <br/>
                        <br/>
                        <hr/>
                        <br/>
                        <br/>
                        <div className="container-fluid mylinks">
                            <div className="col-md-12">
                                <center>
                                    <label className="labelHead">Professional Link</label>
                                </center>
                                <br/><br/>
                            </div>
                        
                            <div className="col-xs-5 ">

                                <select id="Professionallink" className="selectpicker">
                                    <option value="none">-</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/angelist.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/angelist.png"/> Angelist'>Angelist</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/linkedin-2.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/linkedin-2.png"/> Linkdin'>Linkdin</option>
                                        <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/github.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/github.png"/> Github'>Github</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/google-scholar--v2.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/google-scholar--v2.png"/> GoogleScholar'>GoogleScholar</option>
                                    <option
                                        value="https://img.icons8.com/windows/24/xyz/orcid.png"
                                        data-content='<img src="https://img.icons8.com/windows/24/000000/orcid.png/"> ORCID'>ORCID</option>
                                    <option
                                        value="http://seekvectorlogo.com/wp-content/uploads/2019/08/academia-edu-vector-logo.png"
                                        data-content='<img src="http://seekvectorlogo.com/wp-content/uploads/2019/08/academia-edu-vector-logo.png" width="28px"/> Academia'>Academia</option>

                                    <option
                                        value="https://img.icons8.com/windows/24/xyz/mendeley.png"
                                        data-content='<img src="https://img.icons8.com/windows/24/xyz/mendeley.png"/> Mendeley'>Mendeley</option>

                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/vimeo.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/vimeo.png"/> Vimeo'>Vimeo</option>

                                    <option
                                        value="https://img.icons8.com/windows/24/xyz/behance.png"
                                        data-content='<img src="https://img.icons8.com/windows/20/000000/behance.png"/> Behance'>Behance</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/meetup.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/meetup.png"/> Meetup'>Meetup</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/xing.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/xing.png"/> Xing'>Xing</option>
                                    <option
                                        value="https://img.icons8.com/ios-filled/24/xyz/viadeo.png"
                                        data-content='<img src="https://img.icons8.com/ios-filled/20/000000/viadeo.png"/> Viadeo'>Viadeo</option>
                                </select>

                            </div>
                            <div className="col-xs-7">
                                <input
                                    type="text"
                                    id="ProfessionalLinkurl" style={{width:'100%'}}
                                    placeholder="URL"
                                    className="borLessInput"/>
                            </div>
                            <br/>
                        </div>
                            <div id="login_div" className="main-div">
                                <div id="">
                                    <div className="col-xs-6">
                                        <button className="btn btn-edit btn-default"
                                         onClick={e=>submitlinks('Professional')}>Submit</button>
                                    </div>
                                    <div className="col-xs-6">
                                        <button className="btn btn-edit btn-default" 
                                        type="reset"  onClick={e=>resetsocial('Professional')}>Reset</button>
                                    </div>
                                </div>

                            </div>
                       
                     

                    </div>

                </div>
                <div class="col-sm-6">
                    <div className="second">
                        <center>
                            <label class="headi labelHead">Preview
                            </label>
                        </center>
                        <div className="container-fluid">
                            <center><h3>Social Media link</h3></center>
                            <br/>
                        {sociallink && sociallink.map(data=> <SocialLinkpreview lk={data}/>)}
                        </div>
                        <br/><br/><br/><br/>
                        <div className="container-fluid">
                            <center><h3>Professional link</h3></center>
                            <br/>
                        {professioanllink && professioanllink.map(data=> <ProfessionallLinkpreview lk={data}/>)}
                       
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

function SocialLinkpreview(props){
const deletelink = e =>{
    var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
    log.push().update({
        "DateTime" : new Date().toLocaleString() + '',
        "Message": ' Link Data Deleted'
    })
    var links = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Link/SocialLinks').child(id).remove();
}
    var {media,url,id} = props.lk
    return(<>
    <div className="col-xs-3">
       <span class="glyphicon glyphicon-remove" data-tip="Delete" style={{cursor:'pointer'}} onClick={e=>deletelink(id)}>   
           </span> 
       <a href={url} target="_blank"><img src={media} style={{paddingLeft:'10px',width:'40px'}} /></a>
       <ReactTooltip />
    </div>
    
    </>)
}
function ProfessionallLinkpreview(props){
    const deletelink = e =>{
        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
        log.push().update({
            "DateTime" : new Date().toLocaleString() + '',
            "Message": ' Link Data Deleted'
        })
        var links = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Link/ProfessionalLinks').child(id).remove();
    }
        var {media,url,id} = props.lk
        return(<>
        <div className="col-xs-3">
           <span class="glyphicon glyphicon-remove" data-tip="Delete" style={{cursor:'pointer'}} onClick={e=>deletelink(id)}>   
               </span> 
           <a href={url} target="_blank"><img src={media} style={{paddingLeft:'10px',width:'40px'}}/></a>
           <ReactTooltip />
        </div>
        
        </>)
    }
export default Links