import React, {useState,useCallback, useEffect} from 'react';
//import "./researcharea.css"
import "../common.css"
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import ReactTooltip from 'react-tooltip';
import { useListVals,useListKeys} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();

function Publication() {
    var [previewdata] = useListKeys(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Publication'));
    const [selectval,setsetval] = useState('none');
    const [editInput,
        seteditinput] = useState(false)

    const [pubData,setpubdata] = useState('');
const subPub = e =>{
    e.preventDefault();
    var pubType = document.getElementById('pubType').value;
    if (( pubData.trim().length == 0 ) || (pubType.trim().length == 0)){
        window.alert("Input Field cannot be empty");
        return;
    } 
    pubType = pubType.trim().replaceAll(' ','+');
    
    var pushkey = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Publication').push().key;
    var pubInsert = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Publication/'+pubType);
    pubInsert.child(pushkey).update({
        "Description": pubData,
        "Type" :  pubType.replaceAll('+',' '),
        "id" :pushkey
    });
    var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
    log.push().update({
        "DateTime" : new Date().toLocaleString() + '',
        "Message": 'Publication Data Modified'
    })
    setsetval('none')
    document.querySelector('.donepub').style.display = 'block';
    setTimeout(() => {
        reset();
        document.querySelector('.donepub').style.display = 'none';
    }, 2500)
}
const reset = e => {
    document
        .getElementById("formpub")
        .reset();
    setpubdata('');
}
const  deletePub = (e,t)=>{
    setsetval('none')
    var experiencePreviewId = db
     .ref()
     .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Publication/' + t+'/'+e)
     .remove();
     var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
     log.push().update({
         "DateTime" : new Date().toLocaleString() + '',
         "Message": 'Publication Data Deleted'
     })
 }
var [selectdata,selectloading] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Publication/'+selectval));


    return (
        <div >
            <div className="success-checkmark donepub">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div class="col-sm-6 ">
                            <div className="first">
                                <center>
                                    {editInput
                                        ? <label className="labelHead">Edit Publication</label>
                                        : <label className="labelHead">Insert Publication</label>}
                                </center>
                                <form onSubmit={subPub} id="formpub">
                                <div id="login_div" className="main-div">
                                    <input type="hidden" className="borLessInput" id="branchDegreename"/>

                                    <span className="label">publication type</span>
                                    <input
                                        type="text"
                                        placeholder="Type (Eg. Journal)"
                                        id="pubType"
                                        className="borLessInput"
                                        list="publicationType"/>
                                    <datalist id="publicationType"></datalist>
                                    <span class="label">Description</span>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={pubData}
                                        onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setpubdata(data);
                                       // console.log({event, editor, data});
                                    }}
                                        onBlur={(event, editor) => {
                                        //console.log('Blur.', editor);
                                    }}
                                        onFocus={(event, editor) => {
                                       // console.log('Focus.', editor);
                                    }}/>
                                    <br/><br/> {editInput
                                        ? <div id="upSubEdu">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-default btn-edit btn-submit" type="submit">Update</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="reset" onClick={e=>reset()}>Reset</button>
                                                </div>
                                            </div>
                                        : <div id="subEdu">
                                            <div className="col-xs-6">
                                                <button className="btn btn-edit btn-default" id="" type="submit">Submit</button>
                                            </div>
                                            <div className="col-xs-6">
                                                <button className="btn btn-edit btn-default" type="reset" onClick={e=>reset()}>Reset</button>
                                            </div>
                                        </div>}

                                </div>
                                </form>
                            </div>

                        </div>

                        <div className="col-sm-6">
                            <div className="second">
                                <center>
                                    <label className="headi labelHead">Preview
                                    </label>
                                </center>
                                <div id="login_div" className="main-div">

                                    <select id="pubListVal" onChange={e=>setsetval(e.target.value)}>
                                    <option value='none'>Select Publication type</option>
                                    {previewdata && previewdata.map(data => <Previewdata prop={data} />)}
                                   </select>
                                   <div id='pubvalueappend'>
                                     {selectdata && selectdata.map(data => <Pubdetail prop={data} deletedata ={(e,t) => deletePub(e,t)}/>)}
                                    </div>
                                      
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
var error = 0;
function Previewdata(props) {
    var pubTypeItem = []  
    const handleInputChange = useCallback(event => {
        props.editdata(event)
    }, [props.showsection])
 
   var [previewdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Publication/'));
    if(props.prop){
       // console.log(pubTypeItem.indexOf(props.prop))
        if(pubTypeItem.indexOf(props.prop) === -1)
        {
            pubTypeItem.push(props.prop) 
            var dec = props.prop;
            error = 0;
        }
        else error = 1
       
    }
    
    return (<>
        {error ? <></>: <option value={dec}>{dec}</option>}
        </>
    )
}
function Pubdetail(props)
{

    const handleInputChange = useCallback((event,type) => {

        props.deletedata(event,type)
    }, [props.showsection])
    
   if(props.prop)
     {
         var {id,Description,Type}= props.prop;
     }
    return (<> <div class="container-fluid">
    <div class="col-xs-10">
    {parse(Description)}
 
    </div> 
    <div className="col-xs-2">
    
       <button className="btn bthBorderLess btn-default" onClick={(e,t)=>handleInputChange(id,Type)}>
           <span data-tip="Delete" className="glyphicon glyphicon-trash" ></span>
       </button> 
       <ReactTooltip />
    </div>

    </div><hr/></>)
}
export default Publication;
