import react from 'react';
import 'react-toastify/dist/ReactToastify.css';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../../config'
import {useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
var userid;
function SocialLinks(props) {
userid = props.id
var [sociallink] = useListVals(db.ref('UserAccount/' + userid + '/Link/SocialLinks'));
var [professioanllink] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Link/ProfessionalLinks'));
    return (
    <nav  id="socialMediaPreview">
        {sociallink && sociallink.map(data=> <SocialLinkpreview lk={data}/>)}
     
        {professioanllink && professioanllink.map(data=> <ProfessionalLinkpreview lk={data}/>)}
    </nav>
 
    )
}

function SocialLinkpreview(props){

    var {media,url,id} = props.lk
    return(<>
       <a href={url} target="_blank"><img src={media.replace('xyz','ffffff')} /></a>
    </>)
}
function ProfessionalLinkpreview(props){

    var {media,url,id} = props.lk
    return(<>
       <a href={url} target="_blank"><img src={media.replace('xyz','ffffff')} /></a>
    </>)
}

export default SocialLinks