import { useEffect} from 'react';
import './cvtemplet1.css'
import firebase from 'firebase'
import 'firebase/database'
import config from '../../config'
import Education from './Education/Education'
import WorkExperience from './Experience/workexperience'
import TeachingExperience from './Experience/teachingexperience'
import Publication from './Publication/Publication'
import Membership from './Membership/membership'
import Project from './Project/project'
import Skill from './Skill/skill'
import ResearchArea from './ResearchArea/researcharea'
import Award from './Achive/award'
import parse from 'html-react-parser';
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
var userid = localStorage.getItem('makeyacvUser')
var list = ['Education','WorkExperience','TeachingExperience','Project','Skill','ProfessionalMembership','AchivementAwardHonour','Publication']
function CVTemplet1() {
    var [previewdata] = useObjectVal(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/AboutData'));
    var [menuliost] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/MenuList'));
    menuliost.map(item=>{
        var temp = item.Name.replace(/ /g,'');
        temp = temp.replace(/\//g,'')
        temp = temp.trim()
        try{
            //document.querySelector(".personalSetailcv").style.display = "none"
            document.getElementById(temp).style.display = "block"
        }
        catch{}
    })
useEffect(()=>{
    try{
        for(var i=0;i<list.length;i++)
        document.getElementById(list[i]).style.display ='none'
    }catch{}
        
},[])
try{
    console.log(previewdata.carrerobjective)
}catch{}

    return (
    
        <div className="container-fluid cv1" >

            <div className="col-xs-12 bg1cv1" style={{fontSize:"12pt"}}>
              {previewdata ? <> <p className="addresscv1">{previewdata.persOffAddress}<br/>
             {previewdata.persMobile}<br/>
             {previewdata
                                    ? previewdata.perscvemail
                                    : <></>}<br/></p></>
                :<></>}
            </div>
            <div className="col-xs-12 bg2cv1">
            {previewdata ? <p
                    className="namecv1"
                    style={{fontSize:'25pt ', textTransform: 'uppercase',fontWeight:'bolder',
                    backgroundColor: 'rgb(207, 207, 207)'
                }}>{previewdata.name} {previewdata.description ? 
                <span  style={{fontSize:'10pt', textTransform: 'none'}}>{previewdata.description}</span> :<></>}</p>:<></>}
            </div>
            {previewdata
                    ? previewdata.aboutmedetail
                        ?  <div className="col-xs-12 bg-3cv1 obj">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="  ">
                                    <p className="p">About Me</p>
                                </div>
                                <div className=" ">
                                    <p className="carrerobjcv1">{parse(previewdata.aboutmedetail.replace(/\n/g,'<br/>'))}</p>
                                </div>
                            </div>
                        </div>
                        <hr/>
                    </div>
                        : <></>:<></ >}
            {/* {previewdata
                    ? previewdata.carrerobjective
                        ?  <div className="col-xs-12 bg-3cv1 obj">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="  ">
                                    <p className="p">OBJECTIVE</p>
                                </div>
                                <div className=" ">
                                    <p className="carrerobjcv1">{parse(previewdata.carrerobjective.replace(/\n/g,'<br/>'))}</p>
                                </div>
                            </div>
                        </div>
                        <hr/>
                    </div>
                        : <></>:<></ >} */}
           
            <span id="cv1Section">
                <div className="col-xs-12 bg-3cv1 workExp" id="WorkExperience">
                                    <WorkExperience id={userid}/>          
                   
               <hr/>
                </div>
                <div className="col-xs-12 bg-3cv1 workExp" id="TeachingExperience">     
                                    <TeachingExperience id={userid}/>   
               <hr/>
                </div>
                <div className="col-xs-12 bg-6cv1" id="Education">
                   
                   <Education id={userid}/>
              <hr/></div>
              <div className="col-xs-12 bg-3cv1 skill" id="Skill">
                  
                             <Skill id={userid} />
                           
                 <hr/>
                </div>
                <div className="col-xs-12 bg-3cv1 Project" id="Project">
                             <Project id={userid} />
                    <hr/>
                </div>
                
               
                <div className="col-xs-12 bg-3cv1 publication" id="Publication">
                                    <Publication id={userid}/>   
                    <hr/>
                </div>
                <div className="col-xs-12 bg-3cv1 publication" id="ProfessionalMembership">
                                    <Membership id={userid}/>   
                    <hr/>
                </div>
                <div className="col-xs-12 bg-3cv1 AchivementAwardHonour" id="AchivementAwardHonour">
                                    <Award id={userid}/>   
                </div>
                <div className="col-xs-12 bg-3cv1 ">
                <p className="p">Personal Info</p><ul>
                {previewdata
                            ? previewdata.persDOB ? <><li ><b>DOB :</b> {previewdata.persDOB}</li></>:<></>
                            : <></>}
                 {previewdata
                            ? previewdata.persGender ? <> <li ><b>Gender :</b> {previewdata.persGender}</li></>:<></>
                            : <></>}
                            {previewdata
                            ? previewdata.persNation ? <> <li ><b>Nationality :</b> {previewdata.persNation}</li></>:<></>
                            : <></>}
                    {previewdata
                            ? previewdata.pershobbie ? <><li ><b>Hobbie :</b> {previewdata.pershobbie}</li></>:<></>
                            : <></>}
                           {previewdata
                            ? previewdata.perslang ? <><li ><b>Language :</b> {previewdata.perslang}</li></>:<></>
                            : <></>}
                </ul>
                {previewdata ? <p
                    style={{float:'right',paddingTop:'30px'}}>{previewdata.name} </p>:<></>}
                </div>
            </span>

        </div>
    )
}
export default CVTemplet1;