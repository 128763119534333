import React, {useState, useEffect} from 'react';
import "./dashboard.css"
import "./dashboardres.css"
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import {useObjectVal, useListVals,useListKeys} from 'react-firebase-hooks/database';
import Modal from 'react-modal';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
if(window.innerWidth < 500)
{
    var customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: '-40%',
            bottom:'1px',
           
            backgroundColor:'white',
            transform: 'translate(-50%, -50%)',
            border: '5px solid black',
            borderRadius: '0px'
        }
    };
}
else{
    var customStyles = {
        content: {
            top: '50%',
            left: '52%',
            right: 'auto',
            bottom: 'auto',
            backgroundColor:'white',
            transform: 'translate(-50%, -50%)',
            border: '5px solid black',
            borderRadius: '50px'
        }
    };
}

function MenuList(props) {
    if(props.prop)
    setTimeout(() => {
        var section = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/MenuList');
        section.on("value", function (data) {
            data.forEach(element => {
                 document.getElementById( element
                    .child('id')
                    .val()).checked = true;
            })
        })
    }, 200);
    const btnApply = (t,v,i) => {
      if(props.sectiondivShow1 !== 'generatecv')
         props.section('Dashboard')
       
        var section = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/MenuList');
        var coursename = v.trim();
        if(t)
        {
              section.child(i).update({
                        "Name": coursename,
                        "id": i,
                        "image": document.getElementById('m' + i + 'Img').src
                    })
        }
        else{
            section.child(i).remove();
        }
    }
    

    return (
        <Modal
        ariaHideApp={false}
        isOpen={props.prop}
     
        style={customStyles}
        contentLabel="Example Modal">
        <div className="material-icons modalclose" onClick={e=>props.close(false)}>close</div>
        <div className="modalContent">
            <center>
                <p className="menulistptag">Selected items will be shown on your CV and your website</p>
            </center>
            <h3 className="labelHead">Standard</h3>
            <div className="container-fluid" id="Standard">
                <div className="row">

                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="1"
                                value="Education"
                                onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="1">
                                <span className="sw"></span><img
                                    src="https://img.icons8.com/ios-filled/30/000000/student-male--v1.png"
                                    id="m1Img"/>&nbsp;Education</label>
                        </div>
                    </div>

                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="6"
                                value="Work Experience"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="6">
                                <span className="sw"></span>
                                <img
                                    src="https://img.icons8.com/ios-filled/30/000000/yard-work.png"
                                    id="m6Img"/>
                               &nbsp; Work Experience</label>
                        </div>
                    </div>

                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="10"
                                value="Skill"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="10">
                                <span className="sw"></span><img
                                    src="https://img.icons8.com/ios-filled/30/000000/transformation-skill.png"
                                    id="m10Img"/>
                               &nbsp;  Skill
                            </label>
                        </div>
                    </div>

                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="3"
                                value="Training / Course"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="3">
                                <span className="sw"></span><img src="https://img.icons8.com/ios/30/000000/contract.png" id="m3Img"/>
                                &nbsp; Training / Course
                            </label>
                        </div>
                    </div>

                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="12"
                                value="Links"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="12">
                                <span className="sw"></span><img src="https://img.icons8.com/ios-filled/30/000000/share.png" id="m12Img"/>
                                &nbsp; links
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="11"
                                value="Project"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="11">
                                <span className="sw"></span><img
                                    src="https://img.icons8.com/ios-filled/30/000000/microscope.png"
                                    id="m11Img"/>
                                &nbsp;Project
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <hr/>
            <h3 className="labelHead">Premium
                <span className="premiumLabel">(<strike> upgrade plan </strike>) Free Trail</span>
            </h3>
            <div className="container-fluid" id="Premium">
                <div className="row">
                <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="13"
                                value="Certification"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="13">
                                <span className="sw"></span>
                                <img
                                    src="https://img.icons8.com/ios-filled/30/000000/microscope.png"
                                    id="m13Img"/>
                                &nbsp;Certification</label>
                        </div>
                    </div>
                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="2"
                                value="Research Area"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="2">
                                <span className="sw"></span>
                                <img
                                    src="https://img.icons8.com/ios-filled/30/000000/microscope.png"
                                    id="m2Img"/>
                                &nbsp;Research Area</label>
                        </div>
                    </div>
                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="7"
                                value="Teaching Experience"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="7">
                                <span className="sw"></span><img
                                    src="https://img.icons8.com/ios-glyphs/30/000000/tuition--v1.png"
                                    id="m7Img"/>
                                &nbsp;Teaching Experience</label>
                        </div>
                    </div>
                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="5"
                                value="Publication"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="5">
                                <span className="sw"></span><img src="https://img.icons8.com/ios-filled/30/000000/add-book.png" id="m5Img"/>
                                &nbsp; Publication
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="9"
                                value="Professional Membership"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="9">
                                <span className="sw"></span>
                                <img
                                    src="https://img.icons8.com/pastel-glyph/30/000000/badge--v2.png"
                                    id="m9Img"/>
                               &nbsp; Professional Membership
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="8"
                                value="Achivement / Award / Honour"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="8">
                                <span className="sw"></span><img
                                    src="https://img.icons8.com/pastel-glyph/30/000000/medal--v1.png"
                                    id="m8Img"/>
                               &nbsp; Achivement / Award / Honour
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-6 ">
                        <div className="sectionItem">
                            <input
                                type="checkbox"
                                className="ios-switch "
                                id="4"
                                value="Conference / Symposium / Workshop"
                               onChange={e=>btnApply(e.target.checked,e.target.value,e.target.id)}/>
                            <label for="4">
                                <span className="sw"></span><img src="https://img.icons8.com/ios/30/000000/presentation.png" id="m4Img"/>
                                &nbsp; Conference / Symposium / Workshop
                            </label>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    </Modal>
    )
}

export default MenuList
