import React, {useState, useCallback} from 'react';
import "./working.css"


function Working() {
 
   
    return (
        <div >
              <div className="success-checkmark doneexp">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div class="col-sm-6 ">
                            <div className="">
                                <h1 style={{paddingTop:'30%',fontSize:'30pt',textAlign:'center',
                                letterSpacing:'0.1em',fontWeight:'bolder'}}>Under Construction</h1>
                            
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div className="">
                                <img src="./image/undraw_dev_productivity_umsq.svg" style={{width:'70%',paddingTop:'15%'}}/>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}


export default Working;
