import React, {useState,useCallback} from 'react';
import "./researcharea.css"
import "../common.css"
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import ReactTooltip from 'react-tooltip';
import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();

function ResearchArea() {
    var [ResearchAreadata] = useListKeys(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ResearchArea'));
    const [editInput,
        seteditinput] = useState(false)
    const test = e => {
        console.log(e)
    }
    const [addSubdomain,
        setaddSubdomain] = useState(1)
    const addSubDomain = () => {
        var appendSub = ' <span class="label">sub domain name</span>'
        appendSub += ' <input type="hidden"  id="subdomainid'+addSubdomain +'"/>'
        appendSub += '<input type="text" placeholder="Enter Sub Domain Name "  id="subdomain'+addSubdomain +'" />'
        appendSub += '<span class="label">sub domain name description</span>'
        appendSub += '<textarea id="subdomainDes'+ addSubdomain+'" placeholder="Sub Domain Description" ></textarea>'
        setaddSubdomain(addSubdomain + 1)

        document.getElementById('Subdomainlist').insertAdjacentHTML("beforeend", appendSub);
    }
    const subresearch = e => {
        e.preventDefault();
        var ResearchAreaInsert = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ResearchArea');
        var ResearchAreaDomain = document
            .getElementById('domain')
            .value;
        if (ResearchAreaDomain.length == 0) {
            window.alert("Please Enter Domain and Sub Domain!.....")
            return;
        }
       
        ResearchAreaDomain = ResearchAreaDomain.replace(/ /g, "+");
         for (var i = 0; i < addSubdomain; i++) {
            if (document.getElementById('subdomain' + i).value.length != 0) {
                // console.log(document.getElementById('skillRange' + i))
                var pushkey;
                {editInput ? pushkey = document.getElementById('subdomainid' + i).value
                :   pushkey = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ResearchArea').push().key;
                }
              
                ResearchAreaInsert.child(ResearchAreaDomain).child(pushkey).update({
                        "SubDomain": document.getElementById('subdomain' + i).value,
                        "id" :pushkey,
                        "Domain" : ResearchAreaDomain
                    })
                {document.getElementById('subdomainDes' + i).value.length !=0 ? 
                ResearchAreaInsert.child(ResearchAreaDomain).child(pushkey).update({
                    "Description": document.getElementById('subdomainDes' + i).value,
                })
                 :<></>}
            }

        }
        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
        log.push().update({
            "DateTime" : new Date().toLocaleString() + '',
            "Message": 'Research Area Data Modified'
        })
        document
            .querySelector('.doneresearch')
            .style
            .display = 'block';
        setTimeout(() => {
            resetrs();
            document
                .querySelector('.doneresearch')
                .style
                .display = 'none';
        }, 2500);
    }
    const editDatars = e =>{ 
        resetrs();
        var rAcount = 0;
          seteditinput(true)
          var data = [];
          document.getElementById('domain').value = e;
          var researchPreview = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ResearchArea/' + e);
          researchPreview.once('value', function (snapshot) {
            snapshot.forEach(element => {
                data.push(element.key);
                data.push(element.child('SubDomain').val());
                if (element.child('Description').val() == null) {
                    data.push('null');
                } else {
                    data.push(element.child('Description').val());
                }
    
            })
            for (var i = 0; i < data.length; i = i + 3) {
                document.getElementById('subdomain' + rAcount).value = data[i + 1];
                document.getElementById('subdomainid' + rAcount).value = data[i];
                if (data[i + 2] != 'null') {
                    document.getElementById('subdomainDes' + rAcount).value = data[i + 2];
                }
    
    
                rAcount++;
                setaddSubdomain(rAcount )
                if (i + 3 < data.length) {
                    var appendSub = ' <span class="label">sub domain name</span>'
                    appendSub += ' <input type="hidden"  id="subdomainid'+rAcount +'"/>'
                    appendSub += '<input type="text" placeholder="Enter Sub Domain Name "  id="subdomain'+rAcount +'" />'
                    appendSub += '<span class="label">sub domain name description</span>'
                    appendSub += '<textarea id="subdomainDes'+ rAcount+'" placeholder="Sub Domain Description" ></textarea>'
                   
            
                    document.getElementById('Subdomainlist').insertAdjacentHTML("beforeend", appendSub);
                   // option += '<div class="col-xs-10"><input type="text" placeholder="Enter Sub Domain Name " class="borLessInput" id="subdomain' +
                       // rAcount + '"> <textarea id="subdomainDes' + rAcount + '" placeholder="Sub Domain Description" class="borLessInput"></textarea></div><div class="col-xs-2"></div>'
    
                   // $('#subdomainAdd0').append(option);
                } else {
                    rAcount = rAcount - 1;
                }
    
            }
          })
    }
    const resetrs = () => {
        setaddSubdomain(1)
        seteditinput(false)
        document
            .getElementById("formResearch")
            .reset();
        var parent = document.getElementById('Subdomainlist')
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }

    }
     
    return (
        <div >
            <div className="success-checkmark doneresearch">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div class="col-sm-6 ">
                            <div className="first">
                                <center>
                                    {editInput
                                        ? <label className="labelHead">Edit Research Area</label>
                                        : <label className="labelHead">Insert Research Area</label>}
                                </center>
                                <form id="formResearch" onSubmit={subresearch}>
                                    <div id="login_div" className="main-div">
                                        <input type="hidden" className="borLessInput" id="branchDegreename"/>

                                        <span className="label">domain name</span>
                                        <input
                                            type="text"
                                            placeholder="Enter Domain Name "
                                            id="domain"
                                            class="borLessInput"/>
                                        <input type="hidden" className="borLessInput" id="subdomainid0"/>
                                        <span className="label">sub domain name</span>
                                        <input
                                            type="text"
                                            placeholder="Enter Sub Domain Name "
                                            id="subdomain0"
                                            className=""/>
                                        <span className="label">sub domain name description</span>
                                        <textarea id="subdomainDes0" placeholder="Sub Domain Description" className=""></textarea>

                                        <div id="Subdomainlist"></div>

                                        <span className="material-icons modeInfo" data-tip="Add More Subdomain" onClick={e => addSubDomain()}>
                                            add
                                        </span>
                                        <ReactTooltip />
                                        <div id="subdomainAdd0"></div>

                                        <br></br>

                                        {editInput
                                            ? <div id="upSubEdu">
                                                    <div className="col-xs-6">
                                                        <button className="btn btn-default btn-edit btn-submit" type="submit">Update</button>
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <button
                                                            className="btn btn-edit btn-default"
                                                            type="reset"
                                                            onClick={e => resetrs()}>Reset</button>
                                                    </div>
                                                </div>
                                            : <div id="subEdu">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="submit" id="">Submit</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button
                                                        className="btn btn-edit btn-default"
                                                        type="reset"
                                                        onClick={e => resetrs()}>Reset</button>
                                                </div>
                                            </div>}

                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div className="second">
                                <center>
                                    <label class="headi labelHead">Preview
                                    </label>
                                </center>
                                {ResearchAreadata && ResearchAreadata.map(data => <ResearchAreaShow sk={data} editdata ={(e) => editDatars(e)}/>)}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

function ResearchAreaShow(props)
{
    const handleInputChange = useCallback((event) => {
          props.editdata(event)
      }, [props.showsection])
      const deleteCont = e => {
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ResearchArea/' + e)
            .remove();
            var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
            log.push().update({
                "DateTime" : new Date().toLocaleString() + '',
                "Message": 'Research Area Deleted'
            })
    }
    var type = props.sk
    var [ResearchAreadata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ResearchArea/'+props.sk));
    return(<><div class="container-fluid">
    <div className="col-xs-10 " >

    <u><h4>{type.replaceAll('+',' ')} :</h4></u>

    <hr/>
    {ResearchAreadata && ResearchAreadata.map(item=> <ResearchAreaval val={item}/>)}
    </div>
    <div className="col-xs-2">
    <span className="glyphicon glyphicon-option-horizontal" data-toggle="collapse" data-target={"#expdemo"+type.replaceAll('+','') }></span>

    <div id={"expdemo"+type.replaceAll('+','')}className="collapse edcopllapse">
    <button data-tip="Delete All" className="btn bthBorderLess btn-default" onClick={e=>deleteCont(type)}><span className="glyphicon glyphicon-trash"></span></button>
    <br/> <button data-tip="Edit" className="btn bthBorderLess btn-default" onClick={e=>handleInputChange(type)}><span className="glyphicon glyphicon-edit"></span></button>
    <ReactTooltip />
    </div>
    </div>
  
    <br/>  <br/>
    </div></>);
}

function ResearchAreaval(props) {
     const deleteCont = (e,t) => {
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ResearchArea/' + t+'/'+e)
            .remove();
            var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
            log.push().update({
                "DateTime" : new Date().toLocaleString() + '',
                "Message": 'Research Area Data Deleted'
            })
    }
    var {Description,id,SubDomain,Domain} = props.val;
    return(<><div className="col-xs-9 " >
        {Description ?  <> <div style={{display: 'flex'}}><i className="glyphicon glyphicon-remove"  data-tip="Delete One" onClick={e=>deleteCont(id,Domain)} style={{cursor: 'pointer',marginTop: '3px'}}> </i>&nbsp;&nbsp;  <li  data-toggle="collapse" data-target={"#demo"+id}  style={{  width: 'fit-content',cursor: 'pointer'}}> <b>{SubDomain}</b>
     <span className="caret"></span></li> </div>
    <div id={"demo"+id} className="collapse democollapse">
          <p><span className="glyphicon glyphicon-check"></span> {Description}</p>
        </div></>
        :   <p> <b>{SubDomain}</b></p>}
      <br/>
      <ReactTooltip />
    </div>
   
    </>)
}
export default ResearchArea;
