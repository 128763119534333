import './App.css';
import firebase from 'firebase'
import 'firebase/database'
import config from './config'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import {useEffect, useState} from 'react';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
var urlval,
    page = 0;

const customStyles = {
    content: {
        top: '53%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
function Main() {
    const [modalIsOpen,
        setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    const [feature,
        setfeature] = useState(1);

    const [webfeature,
        setwebfeature] = useState('Generate your personalised website with a personalised domain link');
        const [webfeatureimg,
            setwebfeatureimg] = useState('/image/undraw_about_me_wa29.svg');
    const displayfeature = e => {
        if (e == feature) 
            return 1
        return 0.3
    }
    if (window.scrollY > 20) {
       // document.querySelector(".navbar").className = "navbar 
       console.log("hello")
       // scroll";
      } else {
        //document.querySelector(".navbar").className = "navbar";
      }
      useEffect(()=>{
       
      },[window.scrollY])
     
  useEffect(() => {
      try{ document
        .getElementById('loadindex')
        .style
        .display = "block"
    document
        .getElementById('mainindexcontent')
        .style
        .display = "none"
    setTimeout(() => {
        document
            .getElementById('loadindex')
            .style
            .display = "none"
        document
            .getElementById('mainindexcontent')
            .style
            .display = "block"
    }, 3500)}
      catch{}
       
    }, [])
    const listenScrollEvent = () => {
        try{ if (window.scrollY >= 70 && window.innerWidth > 500) {
           
               document.querySelector('.navbarindex').style.backgroundColor = "black"
            document.querySelector('.navbarindex').style.padding = "20px"
        } else if (window.scrollY < 70 && window.innerWidth > 500) {
            
              document.querySelector('.navbarindex').style.backgroundColor = "transparent"
            document.querySelector('.navbarindex').style.padding = "50px"
        }}
        catch{}
       
    };
    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        }
    }, [])
    return ( <> <div id="loadindex">
        <div class="containerload">
            <h1 class="loadtext">MakeYaCV</h1>
        </div>
    </div> 
    < div id = "mainindexcontent" class = "animate__animated animate__fadeIn" >
  
         <nav className="navbar navbarindex navbar-inverse navbar-fixed-top">
        <div className="container-fluid">
            <div className="navbar-header">
                <button
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target="#myNavbar">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand navbar-brandmain" href="/"><img src="/image/MakeYaCV_Logo_01.svg"/></a>
            </div>
            <div className="collapse navbar-collapse" id="myNavbar">

                <ul className="nav navbar-nav navbar-right navbar-rightindex">
                    <li>
                        <a
                            onClick={e => {
                            toast('Under Construction', {
                                style: {
                                    backgroundColor: '#F9AAAA',
                                    color: 'black',
                                    fontSize: '20px'
                                }
                            });
                        }}>
                            Pricing & Plans</a>
                    </li>

                    <li>
                        <Link to="/Login">
                            Login</Link>
                    </li>
                    <li>
                        <Link to="/Signup">
                            Signup</Link>
                    </li>

                </ul>
            </div>
        </div>
    </nav> < div className = "bg-1index" > <img src="/image/pexels-lukas-590016.jpg" width="100%" className="bg-img"/> < h1 > Make Future </h1> <p style={{
                    color: 'white',
                    fontWeight: 'bolder'
                }}>Grab Your CV Now
        
    </p ><a href="/Signup">
            <button className="btn btn-default btn-pagecreate">Create Your Page Now</button>
        </a> </div > < img src = "/image/Group 79@2x.png" className = "cvtemplet" /> <div id="c" className="itemvertical">
        <span></span>
        <div className="container">
            <div className="col-md-12 text-center">
                <h1 id="h1" style={{}}>Show Your Skill to the world</h1>

            </div>
        </div>
        <div className="container bg-who">
            <div className="row">

                <div className="col-md-6 bg-who-second">

                    <br/>
                    <h4>Create Your Future</h4>
                    <div className="dash"></div>
                    <br/>
                    <p>Writing a CV is a boring and time-consuming. With the MakeYaCV, you’ll create
                        a document that shows you at your best fast.
                    </p>

                </div>
                <div className="col-md-6">
                    <img src="/image/undraw_folder_files_re_2cbm.svg" width="80%"/>
                </div>
            </div>
        </div>
    </div> < div className = "bg-2main" > <div className="container-fluid">
        <div className="col-sm-12 text-center">
            <h3>Features</h3>
            <br/><br/>
        </div>
   
            <div className="col-md-12">
                <div className="featuredetail ">
                    <center>{feature === 1
                            ? <p>Create a professional cv with our online cv generator</p>
                            : feature === 2
                                ? <p>
                                        Pick the perfect CV from wide range of templets to highlight your career journey</p>
                                : <p>Download CV any time you like</p>}</center>
                </div>
                <div className="col-xs-4 cvfeatureh3">
                    <div
                        className="bg2Box"
                        style={{
                        opacity: displayfeature(1)
                    }}>
                        <img
                            src="/image/undraw_hiring_cyhs.svg"
                            className="features-bg-img"
                            onClick={e => setfeature(1)}/>
                        <h3>Job Ready CV</h3>
                    </div>
                </div>
                <div className="col-xs-4 cvfeatureh3">
                    <div
                        className="bg2Box"
                        style={{
                        opacity: displayfeature(2)
                    }}>
                        <img
                            src="/image/005.png"
                            className="features-bg-img"
                            onClick={e => setfeature(2)}/>
                        <h3>Multiple Templets</h3>
                    </div>
                </div>
                <div className="col-xs-4 cvfeatureh3">
                    <div
                        className="bg2Box"
                        style={{
                        opacity: displayfeature(3)
                    }}>
                        <img
                            src="/image/undraw_Reviewed_docs_re_9lmr.svg"
                            className="features-bg-img"
                            onClick={e => setfeature(3)}/>
                        <h3 >Easy Export</h3>
                    </div>

                </div>
            </div>
    </div> </div>


<div className="container-fluid headline">
  <div className="row">
      <div className="col-md-12 text-center">
          <h2>Not just a CV Maker</h2 > </div> </div>

</div > <div className="bg-3">

        <div className="container-fluid">
            <div className="row">
                <center>
                    <h1>With a single click, you can create and launch your personal website</h1>
                </center>
                <br/><br/><br/>
                <div className="col-xs-3">
                    <div id="imgbg31">
                        <img src="/image/pageRes1.png"/>
                    </div>

                </div>
                <div className="col-xs-9">
                    <div id="imgbg32">
                        <img src="/image/page1.png"/>
                    </div>

                </div>

            </div>
        </div>
        <br/><br/>
        <center>
            <h1>Grab your Personal Page Now 
            </h1>
        </center>

        <a href="/Signup">
            <button className="btn btn-default btn-pagecreate btn-pagecreate1">Create Your Page Now</button>
        </a>
    </div> 
    
    < div className = "bg-3main" > 
    <div className="container-fluid">
        <div className="bg-3main_content">
            <div className="col-sm-12 text-center">

                <h3 style={{fontWeight: "bolder"}}>Features</h3>
                <br/><br/>

            </div>
            <div className="col-xs-4">
                <div className="col-xs-12">
                    <div className="webfeaturebox" style={{opacity : webfeatureimg === '/image/undraw_about_me_wa29.svg' ? '1' : '0.5'}}>
                        <h3 onClick={e => {setwebfeature('Generate your personalised website with a personalised domain link');
                        setwebfeatureimg('/image/undraw_about_me_wa29.svg')}}><i className="glyphicon glyphicon-th-large"></i> Personal Site</h3>
                    </div>

                </div>
                <div className="col-xs-12">
                    <div className="webfeaturebox" style={{opacity : webfeatureimg === '/image/Social Dilemma.svg' ? '1' : '0.5'}}>

                        <h3 onClick={e => {setwebfeature('Link your social network wih your personal website');
                        setwebfeatureimg('/image/Social Dilemma.svg')}}><i className="glyphicon glyphicon-globe"></i> Connect Media links</h3>
                    </div>

                </div>

                <div className="col-xs-12">
                    <div className="webfeaturebox" style={{opacity : webfeatureimg === '/image/undraw_Share_re_9kfx.svg' ? '1' : '0.5'}}>
                        
                        <h3 onClick={e => {setwebfeature('Using your customised domain URL, you can easily share your website with others');
                        setwebfeatureimg('/image/undraw_Share_re_9kfx.svg')}}><i className="glyphicon glyphicon-random"></i> One link for everything</h3>
                    </div>

                </div>

                <div className="col-xs-12">
                    <div className="webfeaturebox" style={{opacity : webfeatureimg === '/image/undraw_tabs_jf82.svg' ? '1' : '0.5'}}>
                        
                        <h3 onClick={e => {setwebfeature('Choose the ideal website for highlighting your job path from a variety of temple' +
                                'ts.');
                                setwebfeatureimg('/image/undraw_tabs_jf82.svg')}}><i className="glyphicon glyphicon-book"></i> Manage Multiple Templets</h3>
                    </div>

                </div>
                <div className="col-xs-12">
                    <div className="webfeaturebox" style={{opacity : webfeatureimg === '/image/undraw_skateboard_d6or.svg' ? '1' : '0.5'}}>
                        
                        <h3 onClick={e => {setwebfeature('No need to learn coding to build a website.');
                        setwebfeatureimg('/image/undraw_skateboard_d6or.svg')}}><i className="glyphicon glyphicon-leaf"></i> No Coding Required
                        </h3>
                    </div>

                </div>
                <div className="col-xs-12">
                    <div className="webfeaturebox" style={{opacity : webfeatureimg === '/image/undraw_charts_re_5qe9 (1).svg' ? '1' : '0.5'}}>
                        <h3 onClick={e => {setwebfeature('Track performance of your website with the built-in analytics');
                            setwebfeatureimg('/image/undraw_charts_re_5qe9 (1).svg')}}><i className="glyphicon glyphicon-tasks"></i> Analytics 
                        </h3>
                    </div>

                </div>
            </div>
            <div className="col-xs-8">
                <div className="webfeaturedetail ">
                    <center>
                        <img src={webfeatureimg} />
                        <p>{webfeature}</p>
                    </center>
                </div>
            </div>

        </div>
    </div> </div>

 <div className="bg-footer">
        <div className="container">
            <div className="col-sm-3">
                <h2>Our Company</h2 > <p>About Us</p> < p > Contact Us </p>
                <p>Pricing</p > </div> < div className = "col-sm-3" >{/* <h2>Resource</h2> < p > Career Blog </p>*/}
                </div>
            <div className="col-sm-3">
                <h2>Legal</h2 > <p>Terms of Use</p> < p > Privacy Policy </p>
                <p>Cookie Policy</p > </div> < div className = "col-sm-3" > <h2> Payment</h2> <p>Coming Soon</p> </div> < div className = "col-sm-12" > <div className="copyrightsection">
        <p className="text-center" style={{fontWeight:'bolder'}}> &copy;  MakeYaCV 2021</p>
    </div> </div>
        </div > <ToastContainer/> </div> </div > </ >);
}

export default Main;