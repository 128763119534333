
import { Offline, Online } from "react-detect-offline";
import Dashboard from './Dashboard/dashboard';
import Signup from './Signup/signup';
import Login from './Login/login';
import Forget from './Forget/forget'
import 'firebase/database'
import M from './M/m'
import Main from './main'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
    return ( <> 
 <Router>   
      <Switch>
        <Route exact path="/">
          <Main />
        </Route>
        <Route path="/Login" component={Login}>
        </Route>
        <Route path="/Signup" component={Signup}>
  
        </Route>
        <Route path="/Forget" component={Forget}>
        </Route>
        <Route path="/Dashboard" component={Dashboard}>

        </Route>
        <Route path="/:id" >
         <M id={window.location.pathname.replace('/','').trim()}/>
        </Route>
      </Switch>
    </Router>
      
</>
 
    );
  }

  export default App;