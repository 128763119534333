import React, {useState,useCallback, useEffect} from 'react';
import "../common.css"
import "./skill.css"
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import ReactTooltip from 'react-tooltip';
import {useObjectVal, useListVals,useListKeys} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
function Skill() {
    var [skillpreviewdata] = useListKeys(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Skill'));

    const [selectval,setsetval] = useState('none');
    var [selectdata,selectloading] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Skill/'+selectval));
    selectdata.sort(function (a, b) {
        return parseFloat(b.SkillRating) - parseFloat(a.SkillRating);
    });
   const [editInput,
        seteditinput] = useState(false)
    const test = e => {
        console.log(e)
    }
    const [addSkillId,
        setaddSkillId] = useState(1)
    const addSkill = e => {
        var appendSub = '';
        var val = addSkillId + 1;
        appendSub += '<div class="container-fluid">'
        appendSub += '<div class="row">'
        appendSub += '<div class="col-xs-6">'
        appendSub += '<input type="text" placeholder="Skill' + val + '" id="skillname' + addSkillId + '"   className="borLessInput"/>'
        appendSub += ' </div>'
        appendSub += '<div class="col-xs-6">'
        appendSub += ' <label style={{fontSize: "10px"}}>Level (1-10)'
        appendSub += ' <input type="range"  id="skillRange' + addSkillId + '" min="1" max="10"  className="borLessInput"  /></label>'
        appendSub += ' </div> '
        appendSub += ' </div>'
        appendSub += ' </div>'
        setaddSkillId(addSkillId + 1)
        document.getElementById('skillnamelist').insertAdjacentHTML("beforeend", appendSub);
        appendSub = ''
    }
    function range(a, b) {

        document
            .getElementById('SkillRatingVal' + b)
            .innerHTML = a;
    }
    const subSkill = e => {
        e.preventDefault();
        var skillInsert = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Skill');
        var skillDomain = document
            .getElementById('skillDomain')
            .value;
        if (skillDomain.length == 0) {
            window.alert("Please Enter Skill Domain!.....")
            return;
        }
        skillDomain = skillDomain.replace(/ /g, "+");

        for (var i = 0; i < addSkillId; i++) {
            if (document.getElementById('skillname' + i).value.length != 0) {
                // console.log(document.getElementById('skillRange' + i))
                var pushkey = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Skill').push().key;
                var skill = document
                    .getElementById('skillname' + i)
                    .value;
                skillInsert
                    .child(skillDomain)
                    .child(pushkey)
                    .update({
                        "Skill": skill,
                        "SkillRating": document
                            .getElementById('skillRange' + i)
                            .value,
                            "id" :pushkey,
                            "Type" : skillDomain
                    })

            }

        }
        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
        log.push().update({
            "DateTime" : new Date().toLocaleString() + '',
            "Message": 'Skill Data Modified'
        })
        document
            .querySelector('.doneskill')
            .style
            .display = 'block';
        setTimeout(() => {
            reset();
            document
                .querySelector('.doneskill')
                .style
                .display = 'none';
        }, 2500);
    }
    const reset = e => {
        setaddSkillId(1)
        document
            .getElementById("formSkill")
            .reset();
        var parent = document.getElementById('skillnamelist')
        //document.getElementById('SkillRatingVal0').innerHTML = '';
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }

    }
    const  deleteskill = (e,t)=>{

        setsetval('none')
        document.getElementById('skillListVal').value = 'none'
        var experiencePreviewId = db
         .ref()
         .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Skill/' + t+'/'+e)
         .remove();
         var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
         log.push().update({
             "DateTime" : new Date().toLocaleString() + '',
             "Message": 'Skill Data Deleted'
         })
     }
    return (
        <div >
            <div className="success-checkmark doneskill">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div class="col-sm-6 ">
                            <div className="first">
                                <center>
                                    {editInput
                                        ? <label className="labelHead"></label>
                                        : <center>
                                            <label class="labelHead">Skill & Expertise</label>
                                        </center>}
                                </center>
                                <form onSubmit={subSkill} id="formSkill">
                                    <div id="login_div" className="main-div">
                                        <input type="hidden" className="borLessInput" id="branchDegreename"/>

                                        <span className="label">Skill Domain Name</span>
                                        <input
                                            type="text"
                                            placeholder="Skill Domain (eg. Computer Skill)"
                                            id="skillDomain"
                                            className="borLessInput"
                                            list="skillList"/>
                                        <datalist id="skillList">
                                        {skillpreviewdata && skillpreviewdata.map(data => <AddSkillPreviewdata prop={data} />)}
                                        </datalist>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div class="col-xs-6">

                                                    <input type="text" placeholder="Skill1" id="skillname0"/>
                                                </div>
                                                <div class="col-xs-6">

                                                    <label
                                                        style={{
                                                        fontSize: '14px'
                                                    }}>Level (1-10)
                                                        <input type="range" id="skillRange0" min="1" max="10" class="borLessInput"/></label>
                                                </div>
                                               

                                            </div>
                                        </div>
                                        <div id="skillnamelist"></div>

                                        <span data-tip="Add more skill" class="material-icons modeInfo" onClick={e => addSkill()}>
                                            add
                                        </span>
                                        <ReactTooltip />
                                        <br></br>

                                        {editInput
                                            ? <div id="upSubEdu">
                                                    <div className="col-xs-6">
                                                        <button className="btn btn-default btn-edit btn-submit" type="submit">Update</button>
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <button
                                                            className="btn btn-edit btn-default"
                                                            type="reset"
                                                            onClick={e => reset()}>Reset</button>
                                                    </div>
                                                </div>
                                            : <div id="subEdu">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="submit" id="">Submit</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button
                                                        className="btn btn-edit btn-default"
                                                        type="reset"
                                                        onClick={e => reset()}>Reset</button>
                                                </div>
                                            </div>}

                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div className="second">
                                <center>
                                    <label class="headi labelHead">Preview
                                    </label>
                                </center>
                                <div id="login_div" class="main-div">
                                    <select id="skillListVal" onChange={e=>setsetval(e.target.value)}>
                                        <option value='none'>Select Skill type</option>
                                        {skillpreviewdata && skillpreviewdata.map(data => <SkillPreviewdata prop={data} />)}
                                    </select>
                                    {selectdata && selectdata.map(data => <Skilldetail prop={data} deletedata ={(e,t) => deleteskill(e,t)}/>)}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

var error = 0;
function AddSkillPreviewdata(props) {
    var pubTypeItem = []  
    if(props.prop){
       // console.log(pubTypeItem.indexOf(props.prop))
        if(pubTypeItem.indexOf(props.prop) === -1)
        {
            pubTypeItem.push(props.prop) 
            var dec = props.prop;
            error = 0;
        }
        else error = 1
       
    }
    
    return (<>
        {error ? <></>: <option value={dec.replaceAll('+',' ')}/>}
        </>
    )
}

var error = 0;
function SkillPreviewdata(props) {
    var pubTypeItem = []  
    if(props.prop){
       // console.log(pubTypeItem.indexOf(props.prop))
        if(pubTypeItem.indexOf(props.prop) === -1)
        {
            pubTypeItem.push(props.prop) 
            var dec = props.prop;
            error = 0;
        }
        else error = 1
       
    }
    
    return (<>
        {error ? <></>: <option value={dec}>{dec.replaceAll('+',' ')}</option>}
        </>
    )
}
function Skilldetail(props)
{
    const handleInputChange = useCallback((event,type) => {

          props.deletedata(event,type)
      }, [props.showsection])

   if(props.prop)
     {
         var {id,Skill,SkillRating,Type}= props.prop;
     }
    return (<> <div class="container-fluid">
    <div class="col-xs-10 skillcolor" style={{backgroundSize: SkillRating+"0%",}}>
    
    <p style={{fontWeight:'bold'}}>{Skill}</p>
    </div> 
    <div class="col-xs-2">
    
       <button data-tip="Delete" class="btn bthBorderLess btn-default" onClick={(e,t)=>handleInputChange(id,Type)}>
           <span class="glyphicon glyphicon-trash" ></span>
       </button> 
       <ReactTooltip />
    </div>
   <br/><br/> <br/>
    </div></>)
}
export default Skill;
