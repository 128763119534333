import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function TeachingExperience(props) {
    userid = props.id
    var [TeachingExperiencedata] = useListVals(db.ref('UserAccount/' + userid + '/TeachingExperience'));
    TeachingExperiencedata.sort(function (a, b) {
        return parseFloat(b.teacstartDate) - parseFloat(a.teacstartDate);
    });

    return ( <>  <p className="p">teaching EXPERIENCE</p><ul>{
        TeachingExperiencedata && TeachingExperiencedata.map(data => <TeachingExperienceval edu={data}/>)
    }</ul> </>)
}
var countedu = []
function TeachingExperienceval(props)
{
  var {
    Image,
    teacPosition,
    teaccompany,teacCType,
    teacendDate,
    id,
    teacstartDate,teacClass,teacDes,teacSubject,teacCourseN,teaclocation
} = props.edu;

var startd = teacstartDate.split('-')
var endd = teacendDate.split('-')
return (<>
<li style={{paddingLeft:"10px"}}>
<b>{teacPosition}</b><br></br>
{teaccompany}<br/>
{teaclocation}<br/>
{teacClass ? <><b>Class Name: </b>: {teacClass}<br/></>:<></>}
{teacSubject ? <><b>Subject Taught:</b>: {teacSubject}<br/></>:<></>}
{startd[0]}  -  {endd[0]} <br/>
{teacDes ?<> - {teacDes}</>:<></>}
<br/>
</li> </>)
}
export default TeachingExperience