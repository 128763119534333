import "../loading.css"
function Loading() {
    return (
        <div >
            <div className="stage">
                <div className="wrapper">
                    <div className="slash"></div>
                    <div className="sides">
                        <div className="side"></div>
                        <div className="side"></div>
                        <div className="side"></div>
                        <div className="side"></div>
                    </div>
                    <div className="text">
                        <div className="text--backing">
                            <span
                                style={{
                                fontSize: '2em',
                                fontWeight: 'bold',
                                color: '#fff',
                                display: 'swap'
                            }}>m</span>ake<span
                                style={{
            fontSize: '2em',
            fontWeight: 'bold',
            color: '#f9fcac',
            display: 'swap'
        }}>Ya</span>
                            <span
                                style={{
                                fontSize: '2em',
                                fontWeight: 'bold',
                                color: '#fff',
                                display: 'swap'
                            }}>CV</span>
                        </div>
                        <div className="text--left">
                            <div className="inner">
                                <span
                                    style={{
                                    fontSize: '2em',
                                    fontWeight: 'bold',
                                    color: '#fff',
                                    display: 'swap'
                                }}>m</span>ake<span
                                    style={{
            fontSize: '2em',
            fontWeight: 'bold',
            color: '#f9fcac',
            display: 'swap'
        }}>Ya</span>
                                <span
                                    style={{
                                    fontSize: '2em',
                                    fontWeight: 'bold',
                                    color: '#fff',
                                    display: 'swap'
                                }}>CV</span>
                            </div>
                        </div>
                        <div className="text--right">
                            <div className="inner">
                                <span
                                    style={{
                                    fontSize: '2em',
                                    fontWeight: 'bold',
                                    color: '#fff',
                                    display: 'swap'
                                }}>m</span>ake<span
                                    style={{
            fontSize: '2em',
            fontWeight: 'bold',
            color: '#f9fcac',
            display: 'swap'
        }}>Ya</span>
                                <span
                                    style={{
                                    fontSize: '2em',
                                    fontWeight: 'bold',
                                    color: '#fff',
                                    display: 'swap'
                                }}>CV</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h1 id="connecting">
                <span>O</span>
                <span>O</span>
                <span>O</span>
                <span>O</span>
            </h1>
            <h1 id="connected">
                <span>C</span>
                <span>0</span>
                <span>N</span>
                <span>N</span>
                <span>E</span>
                <span>C</span>
                <span>T</span>
                <span>E</span>
                <span>D</span>
            </h1>
        </div>
    )
}

export default Loading