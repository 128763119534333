import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function Conference(props) {
    userid = props.id
    var [Conferencedata] = useListKeys(db.ref('UserAccount/' + userid + '/Conference'));
    
    return ( <> <div className="achivdetyail">{
        Conferencedata && Conferencedata.map(data => <Conferenceval edu={data}/>)
    } </div></>)
}
var countedu = []
function Conferenceval(props) { 
    var [Conferencedata] = useListVals(db.ref('UserAccount/' + userid + '/Conference/'+props.edu));
    Conferencedata.sort(function (a, b) {
        return parseFloat(b.workstartDate) - parseFloat(a.workstartDate);
    });

return(<><div className="col-md-12 section_headert1">
<h2>{props.edu}</h2>
</div>{
    Conferencedata && Conferencedata.map(data => <ConferencevalDetail edu={data}/>)
} </>)

}
function ConferencevalDetail(props)
{
  var {
    workDes,worktitle,
    workcompany,conflocation,
    workendDate,
    id,
    workstartDate,workrole,workPaper
} = props.edu;

return (<>
    <div className="col-sm-6 col-xs-12">
      <div className="container-fluid traint1">
         <div className="row">
            <div className="col-xs-12">
           <h3>{worktitle}</h3>
        <p><i>{workcompany}</i> </p>
        {conflocation ?<p>{conflocation}</p>:<></> }
        {workrole ?<p>Role : {workrole}</p>:<></> }
        <p><span className="glyphicon glyphicon-calendar"></span> {workstartDate} - {workendDate}</p>
        {workDes || workPaper
            ? <> <button
                type="button"
                class="btn btn-default btn-descp"
                data-toggle="modal"
                data-target={"#myModal" + id}>Description</button> 

                <div id = {"myModal" + id} class = "modal fade modalt1" role = "dialog" > 
                <div class="modal-dialog">

                <div class="modal-content">
                    
                    <div class="modal-body">
                    {workPaper ? <p>Paper Title :<br/> <b>{parse(workPaper.replaceAll('\n','<br/>'))}</b></p> : <></>}
                     {workDes ? <p>Description :<br/> <b>{parse(workDes.replaceAll('\n','<br/>'))}</b></p> : <></>}
                     
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default  btn-descp" data-dismiss="modal">Close</button>
                    </div>
                </div>

            </div> </div>  
            </ >
            : <></>}
    </div> </div>
     </div> </div> </>)
}
export default Conference