import React, {useState, useCallback} from 'react';
import "./Certification.css"
import "../common.css"
import ReactTooltip from 'react-tooltip';
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import Compressor from 'compressorjs'
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();

const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target.result)
    };
    reader.readAsDataURL(file);
})
function Certification() {
    var [previewdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Certification'));
    previewdata.sort(function(a, b) {
        return parseFloat(b.issud) -parseFloat(a.issud) ;
    });
    const [edstatus,
        setedstatus] = useState('0')
    const [editInput,seteditinput] = useState(false)

    const [dataUri,setDataUri] = useState('')

    const subCerti = e => {
        
        e.preventDefault();
        var orgName = document
            .getElementById('orgName')
            .value;
        var certNumber = document
            .getElementById('certNumber')
            .value;
        var certificationName = document
            .getElementById('certificationName')
            .value;
        var issud = document
            .getElementById('issuedYear')
            .value;

        if ((certificationName.length == 0) || (orgName.length == 0) ||  (issud.length == 0)) {

            window.alert("certification Name / Organization Name/ Issued Year  Field cannot be blank")
            return;
        }
        var puskKey;
        if (editInput) {
            puskKey = document.getElementById('id').value
        } else {
            puskKey = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Certification').push().key;
        }

        var certiInsert = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Certification');

        certiInsert
            .child(puskKey)
            .update({"id": puskKey});

        certiInsert
            .child(puskKey)
            .update({"orgName": orgName,  "certificationName": certificationName, "issuedYear": issud});
        
        if (certNumber) {
            certiInsert
                .child(puskKey)
                .update({"certNumber": certNumber});
        }else{
            certiInsert.child(puskKey+'/certNumber').remove()
        }
            var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
            log.push().update({
                "DateTime" : new Date().toLocaleString() + '',
                "Message": 'Certification Data Modified'
            })
        document.querySelector('.done').style.display = 'block';
        setTimeout(() => {
            reset();
            document.querySelector('.done').style.display = 'none';
        }, 2500);
    }
    const onChange = (file) => {
        //console.log(file) setfilename(file.name)
        if (!file) {
            setDataUri('');
            return;
        }
        if (file.type == 'image/jpeg' || file.type == 'image/png') {
            if (file.size / 1024 > 1024) {
                alert("File size should be less than 1 MB")
            } else {
                var qt = 0.8;
                if (file.size / 1024 > 800) {
                    qt = 0.6
                }
                new Compressor(file, {
                    quality: qt, // 0.6 can also be used, but its not recommended to go below.
                    success: (compressedResult) => {
                        fileToDataUri(compressedResult).then(dataUri => {
                            setDataUri(dataUri)
                        })
                    }
                });
            }
        } else {
            alert("image is supported")

        }
    }
    const editdatacontent = e => {
        seteditinput(true);
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Certification/' + e);
        experiencePreviewId.once('value', function (snapshot) {
            snapshot
                .forEach(function (elem) {
                        document.getElementById(elem.key).value = elem.val();
                    
                })

        })
    }
    const reset = e => {
        document
            .getElementById("certform")
            .reset();
        setDataUri('')
        seteditinput(false);
        document
            .getElementById('edStatus')
            .value="none"
    }
    const [expand,setexpand] = useState(false)
    return (
        <div >
            <div className="success-checkmark done">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div class="col-sm-6 ">
                            <div className="first">

                                <center>
                                    {editInput
                                        ? <label className="labelHead">Edit Certification</label>
                                        : <label className="labelHead">Insert Certification</label>}
                                </center>
                                <form id="certform" onSubmit={subCerti}>
                                    <div id="login_div" className="main-div">
                                        <input type="hidden" className="borLessInput" id="id"/>

                                        <span className="label">Certification Name*</span>
                                        <input
                                            type="text"
                                            placeholder=" Certification Name "
                                            className="borLessInput"
                                            id="certificationName"/>

                                        <span className="label">Organization Name*</span>
                                        <input
                                            type="text"
                                            placeholder=" Organization Name "
                                            className="borLessInput"
                                            id="orgName"/>
                                        <br></br>
                                        {/* {editInput
                                            ? <div id="CollegeLogoUp">

                                                    <div id="collegeLogodiv" className="updateLogo"></div>
                                                    <div id="instLogoUp">

                                                        {dataUri
                                                            ? <img src={dataUri} width="30%"/>
                                                            : <img
                                                                src="/image/logo_here.png"
                                                                width="30%"
                                                                style={{
                                                                marginTop: '30px'
                                                            }}/>}
                                                    </div>
                                                    <div className="editinputLogo">
                                                        <label id="pict">Update Institute Logo
                                                            <input
                                                                type="file"
                                                                id="CollegeLogoedit"
                                                                onChange={(event) => onChange(event.target.files[0] || null)}
                                                                className="borLessInput"/>
                                                        </label>
                                                    </div>
                                                    <br/>
                                                </div>
                                            : <div id="CollegeLogoIn">
                                                <div id="instLogo"><img src={dataUri} width="30%"/></div>

                                                <div className="editinputLogo">
                                                    <label id="pict">Institute Logo
                                                        <input
                                                            type="file"
                                                            id="CollegeLogo"
                                                            onChange={(event) => onChange(event.target.files[0] || null)}/>
                                                    </label>
                                                </div>

                                            </div>} */}

                                        {/* <br/><br/> */}
                                        <span className="label">Certification Number</span>
                                        <input
                                            type="text"
                                            placeholder=" Certification Number"
                                            className="borLessInput"
                                            id="certNumber"/>
                                        
                                        <div className="dateYear">
                                            <div className="form-group">

                                                <span className="label">Issued year*</span>

                                                <input
                                                    type="date"
                                                    id="issuedYear"
                                                    placeholder=" Issued year "
                                                    className="borLessInput"/>
                                            </div>
                                          
                                        </div>
                                        {editInput
                                            ? <div id="">
                                                    <div className="col-xs-6">
                                                        <button className="btn btn-default btn-edit btn-submit" onclick="updateedu()">Update</button>
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <button
                                                            className="btn btn-edit btn-default"
                                                            type="reset"
                                                            onClick={e => reset()}>Reset</button>
                                                    </div>
                                                </div>
                                            : <div id="">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="submit" id="">Submit</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button
                                                        className="btn btn-edit btn-default"
                                                        type="reset"
                                                        onClick={e => reset()}>Reset</button>
                                                </div>
                                            </div>}

                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div className="second">
                                <center>
                                    <label class="headi labelHead">Preview
                                    </label>
                                </center>
                                <br/>
                                  {previewdata && previewdata.map(data => <Previewdata prop={data} editdata ={e => editdatacontent(e)}/>)}
             
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
function Previewdata(props) {
   
    const handleInputChange = useCallback(event => {
        props.editdata(event)
    }, [props.showsection])
    if(props.prop)
    var {
        id,
        certificationName,
        orgName,
        certNumber,
        issuedYear,
    } = props.prop;
    const deleteCont = e => {
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Certification/' + e)
            .remove();
            var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
            log.push().update({
                "DateTime" : new Date().toLocaleString() + '',
                "Message": 'Certification Data deleted'
            })
    }
    return (
        <div class="container-fluid previewContent">
            <div class="col-md-10 ebc">
                <p style={{
                    fontSize: '20px'
                }}>
                    <b >{certificationName}</b>
                </p>
               
                <p>{orgName}</p>
                <p>{certNumber ? <>Certification No. {certNumber}</> :<></>}</p>
                <p><span><img src="/image/event_available-white-36dp.svg"/></span> {issuedYear}</p>
               
            </div>
            <div class="col-xs-2">
                <span
                    class="glyphicon glyphicon-option-horizontal"
                    data-toggle="collapse"
                    data-target={'#info' + id}></span>
                <div id={'info' + id} class="collapse edcopllapse">
                    <button
                        data-tip="Delete"
                        onClick={(e) => deleteCont(id)}
                        class="btn bthBorderLess btn-default">
                        <span class="glyphicon glyphicon-trash"></span>
                    </button>
                    <br/>
                    <button
                        data-tip="Edit"
                        onClick={(e) => handleInputChange(id)}
                        class="btn bthBorderLess btn-default">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                    <ReactTooltip />
                </div>
               
            </div>
        </div>
    )
}
export default Certification;
