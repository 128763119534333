import React, {useState, useCallback} from 'react';
import "../common.css"
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import Compressor from 'compressorjs'
import ReactTooltip from 'react-tooltip';
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target.result)
    };
    reader.readAsDataURL(file);
})

function ProfessionalMembership() {
    var [previewdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ProfessionalMembership'));
    previewdata.sort(function (a, b) {
        return parseFloat(b.projstartDate) - parseFloat(a.projstartDate);
    });
    const [edstatus,
        setedstatus] = useState(false)
    const [editInput,
        seteditinput] = useState(false)
    const [dataUri,
        setDataUri] = useState('')

    const subprofmem = e => {
        e.preventDefault();
        var startDate = document.getElementById('profmemstartDate').value;
        if ((document.getElementById('proftitle').value.length === 0) || (startDate.length === 0) || 
           (document.getElementById('proforg').value.length === 0)) {
            window.alert("Membership Title / Organisation Name / Start date  cannot be empty");
            return;
        }
        if(!edstatus)
        {
            if( document.getElementById('profmemendDate').value.length === 0)
              {
                  window.alert("Membership End Date cannot be empty");
                  return
              }
        }
        var pushkey;
        if (editInput) {
            pushkey = document.getElementById('id').value
        } else {
            pushkey = db
                .ref()
                .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ProfessionalMembership')
                .push()
                .key;
        }

        var exInsert = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ProfessionalMembership');
        exInsert
            .child(pushkey)
            .update({"id": pushkey});

        exInsert
            .child(pushkey)
            .update({
                'proftitle': document
                    .getElementById('proftitle')
                    .value,
                'profmemstartDate': startDate,
                "proforg" : document
                .getElementById('proforg')
                .value
            })
            if (document.getElementById('profresNo').value.length != 0) {
                exInsert
                    .child(pushkey)
                    .update({
                        'profresNo': document
                            .getElementById('profresNo')
                            .value
                    })
            }else {
                exInsert
                .child(pushkey+'/profresNo').remove()  
            }    
        if (document.getElementById('profStatus').value != 'none') {
            exInsert
                .child(pushkey)
                .update({
                    'profStatus': document
                        .getElementById('profStatus')
                        .value
                })
        }else {
            exInsert
                .child(pushkey+'/profStatus').remove()  
        }
        {
            edstatus
                ? exInsert
                    .child(pushkey)
                    .update({"profmemendDate": 'present'})
                : exInsert
                    .child(pushkey)
                    .update({
                        "profmemendDate": document
                            .getElementById('profmemendDate')
                            .value
                    });
        }
        if (dataUri != '') 
           exInsert.child(pushkey).update({"Image": dataUri});

           var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
           log.push().update({
               "DateTime" : new Date().toLocaleString() + '',
               "Message": 'ProfessionalMembership Data Modified'
           })
        document
            .querySelector('.doneprofmem')
            .style
            .display = 'block';
        setTimeout(() => {
            reset();
            document
                .querySelector('.doneprofmem')
                .style
                .display = 'none';
        }, 2500);
    }
    const onChange = (file) => {
        //console.log(file) setfilename(file.name)
        if (!file) {
            setDataUri('');
            return;
        }
        if (file.type == 'image/jpeg' || file.type == 'image/png') {
            if (file.size / 1024 > 1024) {
                alert("File size should be less than 1 MB")
            } else {
                var qt = 0.8;
                if (file.size / 1024 > 800) {
                    qt = 0.6
                }
                new Compressor(file, {
                    quality: qt, // 0.6 can also be used, but its not recommended to go below.
                    success: (compressedResult) => {
                        fileToDataUri(compressedResult).then(dataUri => {
                            setDataUri(dataUri)
                        })
                    }
                });
            }
        } else {
            alert("image is supported")

        }
    }
    const editdatacontent = e => {
        // console.log(e)
        reset();
        setedstatus('0');
   document.getElementById('profmemchecked').checked = 1
        seteditinput(true);
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ProfessionalMembership/' + e);
        experiencePreviewId.once('value', function (snapshot) {
            snapshot
                .forEach(function (elem) {

                    if (elem.key == "profmemendDate") {
                        
                        if (elem.val() === 'present') {
                            setedstatus(true);
                            document.getElementById('profmemchecked').checked = 1
                        } else {
                            setedstatus(false);
                            document.getElementById('profmemchecked').checked = 0
                            setTimeout(() => {
                                document
                                    .getElementById(elem.key)
                                    .value = elem.val();
                            }, 300);

                        }
                    } else if(elem.key == 'Image') {
                       
                            setDataUri(elem.val())
                    }
else {
 document.getElementById(elem.key).value = elem.val();
}
                })

        })
    }
    const reset = e => {
    document.getElementById('profmemchecked').checked = '0'
        document
            .getElementById("profmemform")
            .reset();
            setDataUri('')
            seteditinput(false);
        setedstatus(false)
    }
    return (
        <div >
            <div className="success-checkmark doneprofmem">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div class="col-sm-6 ">
                            <div className="first">
                                <center>
                                    {editInput
                                        ? <label className="labelHead">Edit Professional Membership</label>
                                        : <label className="labelHead">Insert Professional Membership</label>}
                                </center>
                                <form id="profmemform" onSubmit={subprofmem}>
                                    <div id="login_div" className="main-div">
                                        <input type="hidden" className="borLessInput" id="profmemid"/>

                                        <span class="label">Membership Title</span>
                                        <input type="text" placeholder="Title" id="proftitle" class="borLessInput"/>
                                        <span class="label">Organisation</span>
                                        <input
                                            type="text"
                                            placeholder=" Organisation Name"
                                            id="proforg"
                                            class="borLessInput"/> {editInput
                                            ? <div id="CollegeLogoUp">

                                                    <div id="collegeLogodiv" className="updateLogo"></div>
                                                    <div id="instLogoUp">

                                                        {dataUri
                                                            ? <img src={dataUri} width="30%"/>
                                                            : <img
                                                                src="/image/logo_here.png"
                                                                width="30%"
                                                                style={{
                                                                marginTop: '30px'
                                                            }}/>}
                                                    </div>
                                                    <div className="editinputLogo">
                                                        <label id="pict">Update Organisation Logo
                                                            <input
                                                                type="file"
                                                                id="CollegeLogoedit"
                                                                onChange={(event) => onChange(event.target.files[0] || null)}
                                                                className="borLessInput"/>
                                                        </label>
                                                    </div>
                                                    <br/>
                                                </div>
                                            : <div id="CollegeLogoIn">
                                                <div id="instLogo"><img src={dataUri} width="30%"/></div>

                                                <div className="editinputLogo">
                                                    <label id="pict">Organisation Logo
                                                        <input
                                                            type="file"
                                                            id="CollegeLogo"
                                                            onChange={(event) => onChange(event.target.files[0] || null)}/>
                                                    </label>
                                                </div>

                                            </div>}

                                        <span class="label">Registration Number</span>
                                        <input
                                            type="text"
                                            placeholder=" Registration Number"
                                            id="profresNo"
                                            class="borLessInput"/>
                                        <span class="label">Status</span>
                                        <select id="profStatus" class="borLessInput">
                                            <option value="none">-</option>
                                            <option>Active</option>
                                            <option>On Hold</option>
                                            <option>Cancel</option>
                                        </select>

                                        <div class="form-group">
                                            <label >
                                                On Going &nbsp;
                                                <input
                                                    type="checkbox"
                                                    id="profmemchecked"
                                                    onChange={e => setedstatus(e.target.checked)}/>
                                            </label><br/>

                                        </div>

                                        <div className="dateYear">
                                            <div className="form-group">

                                                <span class="label">Start date</span>
                                                <input type="date" id="profmemstartDate" class="borLessInput"/>

                                            </div>
                                            <div className="form-group" id="pass">

                                                <span class="label">End date</span>
                                                {edstatus
                                                    ? <h3>Present</h3>
                                                    : <input type="date" id="profmemendDate" class="borLessInput"/>}

                                            </div>
                                        </div>

                                        {editInput
                                            ? <div id="">
                                                    <div className="col-xs-6">
                                                        <button className="btn btn-default btn-edit btn-submit" type="submit">Update</button>
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <button
                                                            className="btn btn-edit btn-default"
                                                            type="reset"
                                                            onClick={e => reset()}>Reset</button>
                                                    </div>
                                                </div>
                                            : <div id="">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="submit">Submit</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button
                                                        className="btn btn-edit btn-default"
                                                        type="reset"
                                                        onClick={e => reset()}>Reset</button>
                                                </div>
                                            </div>}

                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div className="second">
                                <center>
                                    <label class="headi labelHead">Preview
                                    </label>
                                </center>
                               
                                 {previewdata && previewdata.map(data => <Previewdata prop={data} editdata ={e => editdatacontent(e)}/>)}
                            
                                
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
function Previewdata(props) {
    const handleInputChange = useCallback(event => {
        props.editdata(event)
    }, [props.showsection])
    //console.log(props.prop)
    if (props.prop) 
        var {
            id,
            Image,
            proforg,
            profresNo,
            proftitle,
            profStatus,
            profmemstartDate,profmemendDate
        }
    = props.prop;
    const deleteCont = e => {
        
        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
        log.push().update({
            "DateTime" : new Date().toLocaleString() + '',
            "Message": 'ProfessionalMembership Data Deleted'
        })
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ProfessionalMembership/' + e)
            .remove();
    }
    return (
        <div class="container-fluid ">

            <div class="col-xs-3 exImg">
              {Image ? <img src={Image} width="90%"/> :<></>} 
            </div>
            <div class="col-xs-7 ">
            <p>{proforg}</p>
             <p>{proftitle}</p>            
              {profresNo ? <p>Registration No: {profresNo}</p> :<></>}
            {profStatus ? profStatus == 'Active' ? <p>Status:  <span style={{color:'green',fontWeight:'bolder'}}>{profStatus}</span></p> :
            profStatus == 'On Hold' ? <p>Status:  <span style={{color:'#FF9A00',fontWeight:'bolder'}}>{profStatus}</span></p> :
             <p>Status:  <span style={{color:'red',fontWeight:'bolder'}}>{profStatus}</span></p> :
            <></>}
            {profmemstartDate} - {profmemendDate}
            </div>
            <div class="col-xs-2">
                <span
                    class="glyphicon glyphicon-option-horizontal"
                    data-toggle="collapse"
                    data-target={'#info' + id}></span>
                <div id={'info' + id} class="collapse edcopllapse">
                    <button
                        data-tip="Delete"
                        onClick={(e) => deleteCont(id)}
                        class="btn bthBorderLess btn-default">
                        <span class="glyphicon glyphicon-trash"></span>
                    </button>
                    <br/>
                    <button
                        data-tip="Edit"
                        onClick={(e) => handleInputChange(id)}
                        class="btn bthBorderLess btn-default">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                    <ReactTooltip />
                </div>
            </div>
            <br></br>
        </div>
    )
}
export default ProfessionalMembership