import React, {useState, useCallback} from 'react';
import "./education.css"
import "../common.css"
import ReactTooltip from 'react-tooltip';
import firebase from 'firebase'
import 'firebase/database'
import config from '../config'
import Compressor from 'compressorjs'
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
console.log(db)
const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target.result)
    };
    reader.readAsDataURL(file);
})
function Education() {
    var [previewdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Education'));
    //console.log(previewdata)
    previewdata.sort(function(a, b) {
        return parseFloat(b.startDate) -parseFloat(a.startDate) ;
    });
    const [edstatus,
        setedstatus] = useState('0')
    const [editInput,
        seteditinput] = useState(false)
   //  console//const test = e => {
        //console.log(e)
    //}
    const [dataUri,
        setDataUri] = useState('')

    const subEdu = e => {
        
        e.preventDefault();
        var schoolCollegename = document
            .getElementById('schoolCollegename')
            .value;
        var deptClassname = document
            .getElementById('deptClassname')
            .value;
        var branchDegreename = document
            .getElementById('branchDegreename')
            .value;
        var gradeName = document
            .getElementById('gradeName')
            .value;
        var startDate = document
            .getElementById('startDate')
            .value;
        var edStatus = document
            .getElementById('edStatus')
            .value;

        var boardUniversity = document
            .getElementById('boardUniversity')
            .value;
        var titleName = document
            .getElementById('titleName')
            .value;
        var titleDes = document
            .getElementById('titleDes')
            .value;
        var supervisor = document
            .getElementById('supervisor')
            .value;

        if ((deptClassname.length == 0) || (schoolCollegename.length == 0) || (branchDegreename.length == 0) || (startDate.length == 0)) {

            window.alert("Degree / Institute Name / Department /  University  Field cannot be blank")
            return;
        }
        var puskKey;
        if (editInput) {
            puskKey = document
                .getElementById('id')
                .value
        } else {
            puskKey = db
                .ref()
                .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Education')
                .push()
                .key;
        }

        var edInsert = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Education');
        if (edStatus === 'none') {
            alert('Please Select Status');
            return;
        }
        edInsert
            .child(puskKey)
            .update({"id": puskKey});

        edInsert
            .child(puskKey)
            .update({"schoolCollegename": schoolCollegename, "deptClassname": deptClassname, "branchDegreename": branchDegreename, "startDate": startDate});
        
        if (gradeName) {
            edInsert
                .child(puskKey)
                .update({"gradeName": gradeName});
        }else{
            edInsert.child(puskKey+'/gradeName').remove()
        }


        if (boardUniversity.length != 0) {
            edInsert
                .child(puskKey)
                .update({"boardUniversity": boardUniversity});
        }
        else{
            edInsert.child(puskKey+'/boardUniversity').remove()
        }


        if (edStatus === '1') {
            edInsert
                .child(puskKey)
                .update({"endDate": 'present'})
        } else {
            edInsert
                .child(puskKey)
                .update({
                    "endDate": document
                        .getElementById('endDate')
                        .value
                });
        }

        if (titleName.length != 0) {
            edInsert
                .child(puskKey)
                .update({
                    "projecttitle": document
                        .getElementById('projecttitle')
                        .value,
                    "titleName": titleName
                });

            if (supervisor.length != 0) {
                edInsert
                    .child(puskKey)
                    .update({"supervisor": supervisor});
            }
            if (titleDes.length != 0) {
                edInsert
                    .child(puskKey)
                    .update({"titleDes": titleDes});
            }
        }
        else{
            edInsert.child(puskKey+'/titleName').remove()
            edInsert.child(puskKey+'/titleDes').remove()
            edInsert.child(puskKey+'/supervisor').remove()
            edInsert.child(puskKey+'/projecttitle').remove()
        }
        if (dataUri != '') 
            edInsert.child(puskKey).update({"Image": dataUri});

            var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
            log.push().update({
                "DateTime" : new Date().toLocaleString() + '',
                "Message": 'Education Data Modified'
            })
        document.querySelector('.done').style.display = 'block';
        setTimeout(() => {
            reset();
            document.querySelector('.done').style.display = 'none';
        }, 2500);
    }
    const onChange = (file) => {
        //console.log(file) setfilename(file.name)
        if (!file) {
            setDataUri('');
            return;
        }
        if (file.type == 'image/jpeg' || file.type == 'image/png') {
            if (file.size / 1024 > 1024) {
                alert("File size should be less than 1 MB")
            } else {
                var qt = 0.8;
                if (file.size / 1024 > 800) {
                    qt = 0.6
                }
                new Compressor(file, {
                    quality: qt, // 0.6 can also be used, but its not recommended to go below.
                    success: (compressedResult) => {
                        fileToDataUri(compressedResult).then(dataUri => {
                            setDataUri(dataUri)
                        })
                    }
                });
            }
        } else {
            alert("image is supported")

        }
    }
    const editdatacontent = e => {
        setedstatus('0');
        document.getElementById('edStatus').value='0'
        seteditinput(true);
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Education/' + e);
        experiencePreviewId.once('value', function (snapshot) {
            snapshot
                .forEach(function (elem) {
                    //  console.log(elem.val())
                    if (elem.key === 'Image') {
                        setDataUri(elem.val())
                    } else  if (elem.key === 'endDate'){
                        if(elem.val() === 'present'){
                            setedstatus('1');
                            document.getElementById('edStatus').value='1'
                          //  document.getElementById(elem.key).value = ' ';
                        }
                        else{
                            setedstatus('0');
                            document.getElementById('edStatus').value='0'
                            setTimeout(() => {
                                document.getElementById(elem.key).value = elem.val();
                            }, 300);
                           
                        }

                    }else
                    {
                        document
                            .getElementById(elem.key)
                            .value = elem.val();
                    }
                })

        })
    }
    const reset = e => {
        document
            .getElementById("eduform")
            .reset();
        setDataUri('')
        seteditinput(false);
        document
            .getElementById('edStatus')
            .value="none"
    }
    const [expand,setexpand] = useState(false)
    return (
        <div >
            <div className="success-checkmark done">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div class="col-sm-6 ">
                            <div className="first">

                                <center>
                                    {editInput
                                        ? <label className="labelHead">Edit Education</label>
                                        : <label className="labelHead">Insert Education</label>}
                                </center>
                                <form id="eduform" onSubmit={subEdu}>
                                    <div id="login_div" className="main-div">
                                        <input type="hidden" className="borLessInput" id="id"/>

                                        <span className="label">Degree Name</span>
                                        <input
                                            type="text"
                                            placeholder=" Degree "
                                            className="borLessInput"
                                            id="branchDegreename"/>
                                        <span className="label">Institute Name</span>
                                        <input
                                            type="text"
                                            placeholder=" Institute Name "
                                            className="borLessInput"
                                            id="schoolCollegename"/>
                                        <br></br>
                                        {editInput
                                            ? <div id="CollegeLogoUp">

                                                    <div id="collegeLogodiv" className="updateLogo"></div>
                                                    <div id="instLogoUp">

                                                        {dataUri
                                                            ? <img src={dataUri} width="30%"/>
                                                            : <img
                                                                src="/image/logo_here.png"
                                                                width="30%"
                                                                style={{
                                                                marginTop: '30px'
                                                            }}/>}
                                                    </div>
                                                    <div className="editinputLogo">
                                                        <label id="pict">Update Institute Logo
                                                            <input
                                                                type="file"
                                                                id="CollegeLogoedit"
                                                                onChange={(event) => onChange(event.target.files[0] || null)}
                                                                className="borLessInput"/>
                                                        </label>
                                                    </div>
                                                    <br/>
                                                </div>
                                            : <div id="CollegeLogoIn">
                                                <div id="instLogo"><img src={dataUri} width="30%"/></div>

                                                <div className="editinputLogo">
                                                    <label id="pict">Institute Logo
                                                        <input
                                                            type="file"
                                                            id="CollegeLogo"
                                                            onChange={(event) => onChange(event.target.files[0] || null)}/>
                                                    </label>
                                                </div>

                                            </div>}

                                        <br/><br/>
                                        <span className="label">Department Name</span>
                                        <input
                                            type="text"
                                            placeholder=" Department"
                                            className="borLessInput"
                                            id="deptClassname"/>
                                        <span className="label">University / Board</span>
                                        <input
                                            type="text"
                                            placeholder="University / Board"
                                            className="borLessInput"
                                            id="boardUniversity"/>
                                        <span className="label">Grade/Percentage/CGPA</span>
                                        <input
                                            type="text"
                                            placeholder=" Grade/Percentage/CGPA"
                                            className="borLessInput"
                                            id="gradeName"/>

                                        <div className="form-group">
                                            <select
                                                id="edStatus"
                                                onChange={e => setedstatus(e.target.value)}
                                                className="borLessInput">
                                                <option value="none">Select Status</option>
                                                <option value="0">Completed</option>
                                                <option value="1">Appearing</option>
                                            </select>
                                        </div>
                                        <div className="dateYear">
                                            <div className="form-group">

                                                <span className="label">Starting year</span>

                                                <input
                                                    type="date"
                                                    id="startDate"
                                                    placeholder=" Starting year "
                                                    className="borLessInput"/>
                                            </div>
                                            <div className="form-group" id="pass">

                                                <span className="label">Passout year</span>
                                                {edstatus === '1'
                                                    ? <h3>Present</h3>
                                                    : <input
                                                        type="date"
                                                        id="endDate"
                                                        placeholder=" Passout year "
                                                        className="borLessInput"/>}

                                            </div>
                                        </div>
                                        {!expand?
                                        <span
                                            class="material-icons modeInfo"
                                            data-toggle="collapse" 
                                            data-target="#additional" onClick={e=>setexpand(!expand)}>
                                           expand_more
                                        </span> :
                                        <span
                                            class="material-icons modeInfo"
                                            data-toggle="collapse" 
                                            data-target="#additional" onClick={e=>setexpand(!expand)}>
                                            expand_less
                                        </span>}
                                        <div id="additional" className="collapse">

                                            <select id="projecttitle" className="borLessInput">
                                                <option value="Project Title">Project Title</option>
                                                <option value="Thesis Title">Thesis Title</option>

                                            </select>
                                            <span className="label">Title name</span>
                                            <textarea id="titleName" placeholder="Title name" className="borLessInput"></textarea>
                                            <span className="label">Title Description</span>
                                            <textarea
                                                id="titleDes"
                                                placeholder="Title Description (optional)" rows="5"
                                                className="borLessInput"></textarea>

                                            <span className="label">Supervisor name & Designation</span>
                                            <textarea
                                                id="supervisor"
                                                placeholder="Supervisor name & Designation {use ;(semicolon) to seperate supervisors}"
                                                className="borLessInput"></textarea>

                                        </div>
                                        {editInput
                                            ? <div id="">
                                                    <div className="col-xs-6">
                                                        <button className="btn btn-default btn-edit btn-submit" onclick="updateedu()">Update</button>
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <button
                                                            className="btn btn-edit btn-default"
                                                            type="reset"
                                                            onClick={e => reset()}>Reset</button>
                                                    </div>
                                                </div>
                                            : <div id="">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="submit" id="">Submit</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button
                                                        className="btn btn-edit btn-default"
                                                        type="reset"
                                                        onClick={e => reset()}>Reset</button>
                                                </div>
                                            </div>}

                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div className="second">
                                <center>
                                    <label class="headi labelHead">Preview
                                    </label>
                                </center>
                                  {previewdata && previewdata.map(data => <Previewdata prop={data} editdata ={e => editdatacontent(e)}/>)}
             
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
function Previewdata(props) {
   
    const handleInputChange = useCallback(event => {
        props.editdata(event)
    }, [props.showsection])
    if(props.prop)
    var {
        id,
        boardUniversity,
        branchDegreename,
        deptClassname,
        endDate,
        Image,
        schoolCollegename,
        startDate,
        gradeName,
        supervisor,
        titleDes,
        projecttitle,
        titleName
    } = props.prop;
    const deleteCont = e => {
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Education/' + e)
            .remove();
            var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
            log.push().update({
                "DateTime" : new Date().toLocaleString() + '',
                "Message": 'Education Data deleted'
            })
    }
    return (
        <div class="container-fluid previewContent">
            <div class="col-xs-3 exImg">
                {Image
                    ? <img src={Image} width="90%"/>
                    : <center><img
                        src="/image/logo_here.png"
                        width="60%"
                        style={{
                        marginTop: '30px'
                    }}/></center>}

            </div>
            <div class="col-md-7 ebc">
                <p style={{
                    fontSize: '20px'
                }}>
                    <b >{branchDegreename}</b>
                </p>
                <p>{deptClassname}</p>
                <p>{schoolCollegename}</p>
                <p>
                    <span><img src="/image/event_available-white-36dp.svg"/></span>
                    {startDate} - {endDate}</p>
                {gradeName
                    ? <p>Grade: {gradeName}</p>
                    : <p></p>}
                {boardUniversity
                    ? <p>Board: {boardUniversity}</p>
                    : <p></p>}
                {titleName
                    ? <div>
                            <button
                                class="btn btn-default bnt1"
                                data-toggle="collapse"
                                data-target={'#' + id}>Additional</button>
                            <div id={id} className="collapse">
                                <p>
                                    <span><img src="/image/menu_book-white-18dp.svg"/></span>{projecttitle}
                                    : {titleName}</p>

                                {titleDes
                                    ? <p>
                                            {titleDes}</p>
                                    : <div></div>}
                                {supervisor
                                    ? <p>
                                            <span><img src="/image/person-white-18dp.svg"/></span>Advisors {supervisor}</p>
                                    : <div></div>}
                            </div>
                        </div>
                    : <div></div>}
            </div>
            <div class="col-xs-2">
                <span
                    class="glyphicon glyphicon-option-horizontal"
                    data-toggle="collapse"
                    data-target={'#info' + id}></span>
                <div id={'info' + id} class="collapse edcopllapse">
                    <button
                        data-tip="Delete"
                        onClick={(e) => deleteCont(id)}
                        class="btn bthBorderLess btn-default">
                        <span class="glyphicon glyphicon-trash"></span>
                    </button>
                    <br/>
                    <button
                        data-tip="Edit"
                        onClick={(e) => handleInputChange(id)}
                        class="btn bthBorderLess btn-default">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                    <ReactTooltip />
                </div>
               
            </div>
        </div>
    )
}
export default Education;
