import {useObjectVal, useListVals, useListKeys} from 'react-firebase-hooks/database';
import parse from 'html-react-parser';
import firebase from 'firebase'
import 'firebase/database'
import config from '../../../../config'
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.database();

var userid;
function Membership(props) {
    userid = props.id
    var [ProfessionalMembershipdata] = useListVals(db.ref('UserAccount/' + userid + '/ProfessionalMembership'));
    ProfessionalMembershipdata.sort(function (a, b) {
        return parseFloat(b.teacstartDate) - parseFloat(a.teacstartDate);
    });

    return ( <> {
        ProfessionalMembershipdata && ProfessionalMembershipdata.map(data => <ProfessionalMembershipval edu={data}/>)
    } </>)
}
var countedu = []
function ProfessionalMembershipval(props)
{
    var {
        Image,
        proftitle,profStatus,profmemendDate,profmemstartDate,proforg,profresNo
    } = props.edu;
    
    return (<>
        <div className="col-sm-6 col-xs-12">
          <div className="container-fluid member1">
             <div className="row">
               <div className="col-xs-3">
               {Image ? <center><img src={Image} /></center> : <></>}
               </div > 
               <div className="col-xs-9">
               <h3>{proforg}</h3>
            <p><i>{proftitle}</i> </p>
            {profresNo ?<p>Registration No. : {profresNo}</p>:<></> }
            <p><span className="glyphicon glyphicon-calendar"></span> {profmemstartDate} - {profmemendDate}</p>
    
             </div> </div>
          </div> </div> </>)
}
export default Membership