import React, {useState, useCallback} from 'react';
import "../common.css"
import firebase from 'firebase'
import parse from 'html-react-parser';
import 'firebase/database'
import config from '../config'
import ReactTooltip from 'react-tooltip';
import {useListVals} from 'react-firebase-hooks/database';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
const editorConfig = {
    toolbar: ['bulletedList'],
    language: 'en',
    startupOutlineBlocks: true
    // Add any other configuration options you need
  };
  const handleEditorReady = (editor) => {
    console.log('Editor is ready to use!', editor);

    // Disable the bulleted list command
    const command = editor.commands.get('bulletedList');
    if (command) {
      command.isEnabled = true;
    }
  };
function Project() {

    var [previewdata] = useListVals(db.ref('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Project'));
    previewdata.sort(function(a, b) {

        return new Date(b.projstartDate) - new Date(a.projstartDate) ;
    });
    const [edstatus,setedstatus] = useState(false)
    const [editInput,seteditinput] = useState(false)

    //sumnit data
    const subproj = e => {

        e.preventDefault();
        var startDate = document.getElementById('projstartDate').value;
        if ((document.getElementById('projectname').value.length === 0) || (startDate.length === 0) ) {
            window.alert("Project name / Start date  cannot be empty");
            return;
        }
        var pushkey;
        if (editInput) {
            pushkey = document.getElementById('id').value
        } else {
            pushkey = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Project').push().key;
        }

        var exInsert = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Project');
        exInsert
            .child(pushkey)
            .update({"id": pushkey});

       exInsert.child(pushkey).update({
                'projectname': document.getElementById('projectname').value,
                'projstartDate': startDate
         })
         if (document.getElementById('Associatedwith').value.length != 0) {
            exInsert.child(pushkey).update({
                'Associatedwith': document.getElementById('Associatedwith').value
            })
        }
        if (document.getElementById('projectUrl').value.length != 0) {
            exInsert.child(pushkey).update({
                'projectUrl': document.getElementById('projectUrl').value
            })
        }
        if (document.getElementById('projectrole').value.length != 0) {
            exInsert.child(pushkey).update({
                'projectrole': document.getElementById('projectrole').value
            })
        }
        if (document.getElementById('projectlocation').value.length != 0) {
            exInsert.child(pushkey).update({
                'projectlocation': document.getElementById('projectlocation').value
            })
        }
        if (document.getElementById('toolUsed').value.length != 0) {
            exInsert.child(pushkey).update({
                'toolUsed': document.getElementById('toolUsed').value
            })
        }
        if (document.getElementById('projectnShortDescp').value.length != 0) {
            exInsert.child(pushkey).update({
                'projectnShortDescp': document.getElementById('projectnShortDescp').value
            })
        }
       // if (projDescpData === "") {
            exInsert.child(pushkey).update({
                'projectDes': projDescpData
            })
       // }
       // else{
           // exInsert.child(pushkey+'/projectDes').remove();
       // }
        if (document.getElementById('projectSupervisor').value.length != 0) {
            exInsert.child(pushkey).update({
                'projectSupervisor': document.getElementById('projectSupervisor').value
            })
        }
        else{
            exInsert.child(pushkey+'/projectSupervisor').remove();
          }
        {
            edstatus
                ? exInsert
                    .child(pushkey)
                    .update({"projendDate": 'present'})
                : exInsert
                    .child(pushkey)
                    .update({
                        "projendDate": document
                            .getElementById('projendDate')
                            .value
                    });
        }
       
        
        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
        log.push().update({
            "DateTime" : new Date().toLocaleString() + '',
            "Message": 'Project Data Modified'
        })

        document
            .querySelector('.doneproj')
            .style
            .display = 'block';
        setTimeout(() => {
            reset();
            document
                .querySelector('.doneproj')
                .style
                .display = 'none';
        }, 2500);
    }


    const editdatacontent = e => {
        // console.log(e)
        reset();
        setedstatus('0');
        document
        .getElementById('projstartDate').checked = "false"
        seteditinput(true);
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Project/' + e);
        experiencePreviewId.once('value', function (snapshot) {
            snapshot
                .forEach(function (elem) {        
                
                    if (elem.key == "projendDate") {
                        if(elem.val() === 'present'){
                            setedstatus(true);
                           document.getElementById('projstartDate').checked = "true"
                          //  document.getElementById(elem.key).value = ' ';
                        }
                        else{
                           setedstatus(false);
                          document.getElementById('projstartDate').checked = "false"
                            setTimeout(() => {
                                document.getElementById(elem.key).value = elem.val();
                            }, 300);
                           
                        }
                    }
                    else if(elem.key == "projectDes")
                         {
                            setprojDescpdata(elem.val())
                     }
                    else
                        document.getElementById(elem.key).value = elem.val();
                    
                    
                   
                })

        })
    }
    const reset = e => {

        document
            .getElementById("prpform")
            .reset(); 
        seteditinput(false);
        setedstatus(false)
        setprojDescpdata('<ul><li></li></ul>')
        setaddSecpPoint(1)
    }
    const [addSecpPoint,
        setaddSecpPoint] = useState(1)
    const addDecpPoint_func = e => {
        var appendSub = '';
        var val = addSecpPoint + 1;
        appendSub += '<div class="col-xs-1">' + val + '</div>'
        appendSub += '<div class="col-xs-11">'
        appendSub += '  <input type="text" id="descpPoint'+addSecpPoint+'" placeholder="Point '+ val+'" class="borLessInput"> '
        appendSub += '</div>'
        setaddSecpPoint(addSecpPoint + 1)
        document.getElementById('moreDescppoint').insertAdjacentHTML("beforeend", appendSub);
        appendSub = ''
    }
    const [desp,setdesp]= useState(true)
    const [projDescpData,setprojDescpdata] = useState('<ul><li></li></ul>');
    return (
        <div >
            <div className="success-checkmark doneproj">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <section id="home">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-sm-6 ">
                            <div className="first">
                                <center>
                                    {editInput
                                        ? <label className="labelHead">Edit Project</label>
                                        : <label className="labelHead">Insert Project</label>}
                                </center>
                                <form id="prpform" onSubmit={subproj}>
                                    <div id="login_div" className="main-div">
                                        <input type="hidden" className="borLessInput" id="id"/>

                                        <span className="label">
                                            <span id="workVal">Project Name</span>
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="Project Short Description"
                                            id="projectname"
                                            className="borLessInput"/>
                                        <span className="label">Project Short Description</span>
                                        <input
                                            type="text"
                                            placeholder="Project Name"
                                            id="projectnShortDescp"
                                            className="borLessInput"/>

                                        <span className="label">Associated with</span>
                                        <input
                                            type="text"
                                            placeholder="Associated with "
                                            id="Associatedwith"
                                            className="borLessInput"/>
                                        <span className="label">
                                            Location</span>
                                        <input
                                            type="text"
                                            placeholder=" Location "
                                            id="projectlocation"
                                            className="borLessInput"/>

                                        <span className="label">Role</span>
                                        <input type="text" placeholder="Role " id="projectrole" className="borLessInput"/>
                                        
                                        <div className="form-group">
                                            <label >
                                                On Going  <input
                                                    type="checkbox"
                                                    id="checkbox"
                                                    onChange={e => setedstatus(e.target.checked)}
                                                   />
                                            </label><br/>

                                        </div>

                                        <div className="dateYear">
                                            <div className="form-group">

                                                <span className="label">Start date</span>
                                                <input type="date" id="projstartDate" className="borLessInput"/>

                                            </div>
                                            <div className="form-group" id="pass">

                                                <span className="label">End date</span>
                                                {edstatus
                                                    ? <h3>Present</h3>
                                                    : <input type="date" id="projendDate" className="borLessInput"/>}

                                            </div>
                                        </div>
                                        <span className="label">
                                            Tools Used</span>
                                        <input
                                            type="text"
                                            placeholder="Tools used"
                                            id="toolUsed"
                                            className="borLessInput"/>
                                       
                                        <span className="label">Project url</span>
                                        <input type="text" id="projectUrl" placeholder="URL" className="borLessInput"/>
                                        <span className="label">SUPERVISOR NAME & DESIGNATION</span>
                                        <textarea
                                            type="text"
                                            id="projectSupervisor"
                                            placeholder="Supervisor Name and Designation" rows="3"
                                            className="borLessInput"></textarea>
                                        
                                        <span className="label">Respsibility</span>
                                        {/* <h5 className="btn btn-default" onClick={e=>setdesp(true)}>In Sentence</h5>        <h5 className="btn btn-default" onClick={e=>setdesp(false)}>In Points</h5> */}
                                        
                                        {/* {desp ? <textarea
                                            id="projectDes"
                                            placeholder="Description (optional)"rows="5"
                                            className="borLessInput"></textarea> :

                                        <div className="container-fluid">
                                            <br/><br/>
                                            <div className="row">
                                               <div className="col-xs-1">
                                                 1. 
                                               </div>
                                               <div className="col-xs-11">
                                                 <input type="text" id="descpPoint0" placeholder="Point 1" className="borLessInput"/> 
                                                </div>  
                                                <span id="moreDescppoint"></span>
                                            </div>   
                                        </div> } */}
                                        <CKEditor
                                         config={editorConfig}
                                        editor={ClassicEditor}
                                        data={projDescpData}
                                        onReady={handleEditorReady}
                                        onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setprojDescpdata(data);}}
                                        onBlur={(event, editor) => {}}
                                        onFocus={(event, editor) => {
                                       // console.log('Focus.', editor);
                                    }}/>
                                       
                                        {/* <span data-tip="Add more skill" class="material-icons modeInfo" onClick={e=>addDecpPoint_func()}>
                                            add
                                        </span> */}

                                        {editInput
                                            ? <div id="">
                                                    <div className="col-xs-6">
                                                        <button className="btn btn-default btn-edit btn-submit" onclick="updateedu()">Update</button>
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <button
                                                            className="btn btn-edit btn-default"
                                                            type="reset"
                                                            onClick={e => reset()}>Reset</button>
                                                    </div>
                                                </div>
                                            : <div id="">
                                                <div className="col-xs-6">
                                                    <button className="btn btn-edit btn-default" type="submit">Submit</button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button
                                                        className="btn btn-edit btn-default"
                                                        type="reset"
                                                        onClick={e => reset()}>Reset</button>
                                                </div>
                                            </div>}

                                    </div>
                                </form>
                            </div>

                        </div>

                        <div className="col-sm-6">
                            <div className="second">
                                <center>
                                    <label className="headi labelHead">Preview
                                    </label>
                                </center>
                                {previewdata && previewdata.map(data => <Previewdata prop={data} editdata ={e => editdatacontent(e)}/>)}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
function Previewdata(props) {
    const handleInputChange = useCallback(event => {
        props.editdata(event)
    }, [props.showsection])
    //console.log(props.prop)
    if(props.prop) 
        var {
            id,
            Associatedwith,
            projendDate,
            projectDes,
            projectSupervisor,
            projectUrl,
            projectname,
            projectrole,
            projstartDate,
            projectlocation,
            projectnShortDescp,toolUsed
        }
    = props.prop;
    if(projectDes.startsWith("<p>"))
         projectDes = projectDes.replace(/<p>/g,'<ul><li>').replace(/<\/p>/g,'</li></ul>')
    const deleteCont = e => {
        var log = db.ref().child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/ActivityLog')
        log.push().update({
            "DateTime" : new Date().toLocaleString() + '',
            "Message": 'Project Data Deleted'
        })
        var experiencePreviewId = db
            .ref()
            .child('UserAccount/' + localStorage.getItem('makeyacvUser') + '/Project/' + e)
            .remove();
    }
    return (
        <div className="container-fluid previewContent">

            <div className="col-md-10 ebc">
                <p style={{
                    fontSize: '20px'
                }}>
                    {projectUrl ? <a href={projectUrl} target="_blank" id="previewA"><b ><i>" {projectname} " </i></b></a>:<b ><i>" {projectname} " </i></b> }
                    
                </p>
                <p>{projectnShortDescp ? projectnShortDescp :<></>}</p>
                <p>{Associatedwith ? Associatedwith :<></>}{projectlocation? ', '+ projectlocation: <></>}</p>
                <p>
                    <span><img src="/image/event_available-white-36dp.svg"/></span>
                    {projstartDate}
                    - {projendDate}</p>

               
                {projectrole
                    ? <p>Role : {projectrole}</p> 
                                        : <p></p>}
                {toolUsed
                    ? <p>Tool Used : {toolUsed}</p> 
                                        : <p></p>}
                {projectSupervisor
                    ? <p>Supervisor  : {projectSupervisor}</p> 
                                        : <p></p>}
                {projectDes
                    ? <div>
                            <button
                                className="btn btn-default bnt1"
                                data-toggle="collapse"
                                data-target={'#proj' + id}>Additional</button>
                            <div id={'proj'+id} className="collapse">
                                <p className="projecy_class">
                                {parse(projectDes.replaceAll('<li>','<li ><span className="material-icons">play_arrow</span>'))}</p>
                                  {projectUrl ? <p >Url :{projectUrl}</p>:<p></p>}
                            </div>
                        </div>
                    : <div></div>}
                    
            </div>
            <div className="col-xs-2">
                <span
                    className="glyphicon glyphicon-option-horizontal"
                    data-toggle="collapse"
                    data-target={'#info' + id}></span>
                <div id={'info' + id} className="collapse edcopllapse">
                    <button
                        data-tip="Delete"
                        onClick={(e) => deleteCont(id)}
                        className="btn bthBorderLess btn-default">
                        <span className="glyphicon glyphicon-trash"></span>
                    </button>
                    <br/>
                    <button
                        data-tip="Edit"
                        onClick={(e) => handleInputChange(id)}
                        className="btn bthBorderLess btn-default">
                        <span className="glyphicon glyphicon-edit"></span>
                    </button>
                </div>
                <ReactTooltip />
            </div>
            <br></br>
        </div>
    )
}

export default Project;
